import React, { useEffect, useState } from "react";
import { FaPrint, FaDownload } from "react-icons/fa";
import "../invoice.css";
import { BASE_URL } from "../Url/constants.js";
import { sendRequest } from "../ApiRequest.js";
import { useParams } from "react-router-dom";
import QRCode from "qrcode.react";
// import PrintableInvoice from "../components/PrintableInvoice.jsx";
import InvoicePrintTemplate from "../components/TemplateSettings/BillingTemplatePreview/InvoicePrintTemplate.jsx";
import ReactDOM from 'react-dom'
import ErrorNotification from "../Notification/ErrorNotification.jsx";
function Invoice() {
  const [invoiceData, setInvoiceData] = useState({});
  const [loading, setLoading] = useState(true);
  const [templateConfig, setTemplateConfig] = useState([]);

  const { id } = useParams();

  const handlePrintableInvoice = async () => {
    try {
      // const templateResponse = await sendRequest('GET', `${BASE_URL}/v1/template/templates/get_default_template`);
      // const defaultTemplateId = templateResponse.data.data.id;

      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Invoice</title>
          </head>
          <body>
            <div id="root"></div>
          </body>
        </html>
      `);

      ReactDOM.render(
        <InvoicePrintTemplate 
          // template={defaultTemplateId}
          invoiceData={invoiceData}
          isSale={isSale}
          isPurchase={isPurchase}
        />,
        printWindow.document.getElementById("root"),
        () => {
          printWindow.document.close();
          printWindow.focus();
          setTimeout(() => {
            printWindow.print();
          }, 500);
        }
      );
    } catch (error) {
      ErrorNotification('Failed to print invoice:', error);
    }
  };

  function generateUPIString(
    payeeAddress,
    payeeName,
    amount,
    currency,
    transactionNote
  ) {
    const upiParams = {
      pa: payeeAddress,
      pn: payeeName,
      am: amount,
      cu: currency,
      tn: transactionNote,
    };

    return `upi://pay?${new URLSearchParams(upiParams).toString()}`;
  }

  useEffect(() => {
    fetchInvoiceData();
    fetchTemplateConfig();
  }, [id]);

  const fetchTemplateConfig = async () => {
    try {
      const response = await sendRequest('GET', `${BASE_URL}/v1/template/templates/get_default_template`);
      const templateId = response.data.data.id;
      const configResponse = await sendRequest('GET', `${BASE_URL}/v1/template/templates/${templateId}`);
      setTemplateConfig(configResponse.data.data.template_options);
    } catch (error) {
      ErrorNotification('Error fetching template config:', error);
    }
  };

  const fetchInvoiceData = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/billings/bills/${id}`,
      );
      setInvoiceData(response.data.data);
    } catch (error) {
      ErrorNotification("Error fetching the invoice data");
    } finally {
      setLoading(false);
    }
  };


  const handleDownload = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/billings/bills/bill_download_pdf?id=${id}`,
        null,
        { responseType: "blob" }
      );
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      ErrorNotification("Error downloading the invoice");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!invoiceData) {
    return <div>No invoice data available</div>;
  }

  const isSale = invoiceData.type === "Sale";
  const isPurchase = invoiceData.type === "Purchase";

  const subTotal =
    invoiceData?.sub_total?.toFixed(2) ||
    (isPurchase
      ? invoiceData?.bill_items
          ?.reduce(
            (acc, item) => acc + (item?.purchase_price * item?.quantity || 0),
            0
          )
          .toFixed(2)
      : invoiceData?.bill_items
          ?.reduce(
            (acc, item) =>
              acc + (item?.item_total_amount || 0) * (item?.quantity || 0),
            0
          )
          .toFixed(2)) ||
    "0.00";

  return (
    <>
      <div className="invoice-box">
        <div className="invoice-wrapper">
          <div className="invoice-title">
            <h1>Invoice</h1>
          </div>
          <div className="company-info-invoice">
            <div className="invoice-header-row">
              <div className="com-whole-info">
                <div className="invoice-company-logo">
                  <img
                    src={invoiceData.logo_url || "default-logo.png"}
                    alt=""
                    className="logo-image"
                  />
                </div>
                <div className="invoice-company-details">
                  <div>Invoice Number: {invoiceData?.bill_number}</div>
                  <strong>
                    <h5>{invoiceData?.company?.name?.replace(/_/g, " ")}</h5>
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div className="invoice-actions">
            <button
              className="invoice-button-download"
              onClick={handleDownload}
            >
              <FaDownload />
            </button>
            <button className="invoice-button-print" onClick={handlePrintableInvoice}>
              <FaPrint />
            </button>
          </div>
          <div className="invoice-details">
            <div className="invoice-bill-to">
              <h4>Bill To</h4>
              {isSale && (
                <div>
                  <div>
                    <strong>Name:</strong>{" "}
                    {invoiceData.bill_to
                      ? invoiceData.bill_to.name ||
                        (invoiceData.bill_to.first_name &&
                        invoiceData.bill_to.last_name
                          ? `${invoiceData.bill_to.first_name} ${invoiceData.bill_to.last_name}`
                          : "")
                      : ""}
                  </div>
                  <div>
                    <strong>Phone:</strong> {invoiceData.bill_to?.phone}
                  </div>
                  <div>
                    <strong>Email:</strong> {invoiceData.bill_to?.email}
                  </div>
                </div>
              )}
              {isPurchase && (
                <div>
                  <div>
                    <strong>Name:</strong> {invoiceData.company.name}
                  </div>
                  <div>
                    <strong>GST Number:</strong>{" "}
                    {invoiceData.company.gst_number}
                  </div>
                  <div>
                    <strong>Business Type:</strong>{" "}
                    {invoiceData.company.business_type}
                  </div>
                  <div>
                    <strong>Business Category:</strong>{" "}
                    {invoiceData.company.business_category.replace(/_/g, " ")}
                  </div>
                </div>
              )}
            </div>
            {isPurchase && invoiceData?.ship_from && (
              <div className="invoice-ship-from">
                <h3>Ship From</h3>
                <div>
                  <div>
                    <strong>First Name:</strong>{" "}
                    {invoiceData?.ship_from?.first_name}
                  </div>
                  <div>
                    <strong>Last Name:</strong>{" "}
                    {invoiceData?.ship_from?.last_name}
                  </div>
                  <div>
                    <strong>Email:</strong> {invoiceData?.ship_from?.email}
                  </div>
                  <div>
                    <strong>Phone:</strong> {invoiceData?.ship_from?.phone}
                  </div>
                </div>
              </div>
            )}
          </div>
          {isSale && (
            <div className="invoice-table-container mt-4">
              <table className="invoice-table">
                <thead>
                  <tr>
                    <th scope="col">Item Name</th>
                    <th scope="col">Item Code</th>
                    <th scope="col">Actual Price (per unit)</th>
                    <th scope="col">Taxable Value (per unit)</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Total Discount</th>
                    <th scope="col">Total Tax</th>
                    <th scope="col">Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData?.bill_items?.map((item) => (
                    <tr key={item?.id}>
                      <td>{item?.name}</td>
                      <td>{item?.item_code}</td>
                      <td>
                        {item?.sales_price?.toFixed(2)}{" "}
                        {item?.tax_inclusive ? "(Incl. Tax)" : "(Excl. Tax)"}
                      </td>

                      <td>
                        {item?.item_total_amount
                          ? item.item_total_amount.toFixed(2)
                          : "0.00"}
                      </td>
                      <td>{item?.quantity || "0.00"}</td>
                      <td>
                        {item?.item_total_discount
                          ? (
                              item.item_total_discount * (item.quantity || 0)
                            ).toFixed(2)
                          : "0.00"}
                      </td>
                      <td>
                        {item?.item_total_tax
                          ? (
                              item.item_total_tax * (item.quantity || 0)
                            ).toFixed(2)
                          : "0.00"}
                      </td>
                      <td>
                        {item?.item_total_amount
                          ? (
                              item.item_total_amount * (item.quantity || 0)
                            ).toFixed(2)
                          : "0.00"}
                      </td>
                    </tr>
                  ))}
                  {/* Calculate Totals */}
                  <tr>
                    <td colSpan="4">
                      <strong>Totals</strong>
                    </td>

                    <td>
                      <strong>
                        {invoiceData?.bill_items?.reduce(
                          (acc, item) => acc + (item?.quantity || 0),
                          0
                        )}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        {invoiceData?.bill_items
                          ?.reduce(
                            (acc, item) =>
                              acc +
                              (item?.item_total_discount || 0) *
                                (item?.quantity || 0),
                            0
                          )
                          .toFixed(2)}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        {invoiceData?.bill_items
                          ?.reduce(
                            (acc, item) =>
                              acc +
                              (item?.item_total_tax || 0) *
                                (item?.quantity || 0),
                            0
                          )
                          .toFixed(2)}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        {invoiceData?.bill_items
                          ?.reduce(
                            (acc, item) =>
                              acc +
                              (item?.item_total_amount || 0) *
                                (item?.quantity || 0),
                            0
                          )
                          .toFixed(2)}
                      </strong>
                    </td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>
              {/* <hr /> */}
            </div>
          )}
          {isPurchase && (
            <div className="invoice-table-container mt-4">
              <table className="invoice-table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Item Name</th>
                    <th scope="col">Item Code</th>
                    <th scope="col">HSN No.</th>
                    <th scope="col">Purchase Price/Unit (₹)</th>
                    <th scope="col">Quantity</th>
                    {/* <th scope="col">Unit</th> */}
                    <th scope="col">Tax</th>
                    <th scope="col">Amount (₹)</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData?.bill_items?.map((item) => (
                    <tr key={item?.id}>
                      <td>{item?.name}</td>
                      <td>{item?.item_code}</td>
                      <td>{item?.hsn_number}</td>
                      <td>{item?.purchase_price?.toFixed(2) || "0.00"}</td>
                      <td>{item?.quantity || "0.00"}</td>
                      <td>
                        {(item?.item_total_tax * item?.quantity)?.toFixed(2) ||
                          "0.00"}
                      </td>
                      <td>
                        {(item?.purchase_price * item?.quantity)?.toFixed(2) ||
                          "0.00"}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="3">
                      <strong>Totals</strong>
                    </td>
                    <td>
                      <strong>
                        {invoiceData?.bill_items
                          ?.reduce(
                            (acc, item) => acc + (item?.purchase_price || 0),
                            0
                          )
                          .toFixed(2)}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        {invoiceData?.bill_items?.reduce(
                          (acc, item) => acc + (item?.quantity || 0),
                          0
                        )}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        {invoiceData?.bill_items
                          ?.reduce(
                            (acc, item) =>
                              acc +
                              (item?.item_total_tax * item?.quantity || 0),
                            0
                          )
                          .toFixed(2)}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        {invoiceData?.bill_items
                          ?.reduce(
                            (acc, item) =>
                              acc +
                              (item?.purchase_price * item?.quantity || 0),
                            0
                          )
                          .toFixed(2)}
                      </strong>
                    </td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          )}
          <div className="page-break"></div>
          <div className="invoice-summary">
            <div className="invoice-summary-left">
              {/* <div className="invoice-payment-info">
                <div className="invoice-payment-details">
                  <h3>Payment Details</h3>
                  <div>Payment terms: Net Due in 30 Days</div>
                  <div>Bank Code: 123-4567-890</div>
                </div>
              </div> */}
              {/* <div className="invoice-note">
                <button className="invoice-button-pay">Pay invoice now</button>
              </div> */}
            </div>
            {/* <div className="qr-code-section">
              {invoiceData.payment_mode === "online" && (
                <div className="upi-qr-code">
                  <h4>Scan to Pay</h4>
                  <QRCode
                    value={generateUPIString(
                      invoiceData?.company?.upi_id, // Replace with your actual UPI ID
                      invoiceData?.company?.name?.replace(/_/g, " ") ||
                        "Company Name",
                      invoiceData?.total_amount,
                      "INR",
                      `Payment for Bill #${invoiceData?.bill_number}`
                    )}
                    size={200}
                  />
                  <p>
                    Total Amount: ₹
                    {invoiceData?.total_amount?.toFixed(2) || "0.00"}
                  </p>
                </div>
              )}
            </div> */}
            <div className="invoice-summary-right">
              <div className="invoice-totals">
                <h3>Amount Details</h3>
                <div className="invoice-total-line">
                  <span className="invoice-label">Sub Total:</span>
                  <span className="invoice-value">
                    {subTotal ? subTotal : "0.00"}
                  </span>
                </div>
                {isSale && (
                  <div className="invoice-total-line">
                    <span className="invoice-label">Total Discount:</span>
                    <span className="invoice-value">
                      {invoiceData.total_discount
                        ? invoiceData?.total_discount.toFixed(2)
                        : invoiceData?.bill_items
                            ?.reduce(
                              (acc, item) =>
                                acc +
                                (item?.item_total_discount || 0) *
                                  (item?.quantity || 0),
                              0
                            )
                            .toFixed(2)}
                    </span>
                  </div>
                )}
                <div className="invoice-total-line">
                  <span className="invoice-label">Total Tax:</span>
                  <span className="invoice-value">
                    {/* need to check the puchase tax its dummy right now */}
                    {invoiceData?.total_tax?.toFixed(2) ||
                      (isPurchase
                        ? invoiceData?.bill_items
                            ?.reduce((acc, item) => acc + (item?.tax || 0), 0)
                            .toFixed(2)
                        : invoiceData?.bill_items
                            ?.reduce(
                              (acc, item) =>
                                acc +
                                (item?.item_total_tax || 0) *
                                  (item?.quantity || 0),
                              0
                            )
                            .toFixed(2)) ||
                      "0.00"}
                  </span>
                </div>

                {isSale &&
                invoiceData?.is_gst_enable &&
                invoiceData?.is_igst_enable ? (
                  <div className="invoice-total-line">
                    <span className="invoice-label">IGST(18%)</span>
                    <span className="invoice-value">
                      {subTotal ? (subTotal * 0.18).toFixed(2) : "0.00"}
                    </span>
                  </div>
                ) : isSale && invoiceData?.is_gst_enable ? (
                  <>
                    <div className="invoice-total-line">
                      <span className="invoice-label">CGST: (9%)</span>
                      <span className="invoice-value">
                        {subTotal ? (subTotal * 0.09).toFixed(2) : "0.00"}
                      </span>
                    </div>
                    <div className="invoice-total-line">
                      <span className="invoice-label">SGST: (9%)</span>
                      <span className="invoice-value">
                        {subTotal ? (subTotal * 0.09).toFixed(2) : "0.00"}
                      </span>
                    </div>
                  </>
                ) : null}
                <div className="invoice-total-line">
                  <span className="invoice-label">Grand Total:</span>
                  <span className="invoice-value">
                    {invoiceData?.total_amount?.toFixed(2) || "0.00"}
                  </span>
                </div>
                {/* <div className="invoice-total-line">
                  <span className="invoice-label">Received Amount:</span>
                  <span className="invoice-value">
                    {invoiceData?.received_amount?.toFixed(2) || "0.00"}
                  </span>
                </div> */}
                {/* <hr className="invoice-divider" />
                <div className="invoice-total-line">
                  <span className="invoice-label">Due Amount:</span>
                  <span className="invoice-value">{dueAmount?.toFixed(2)}</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Invoice;
