import React, { useEffect, useState } from "react";
import Header from "../Header";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";
import './CreateCategory.css';
import { useTranslation } from "react-i18next";
import Search from "../Search/Search";
import EntriesPerPageDropdown from "../Common/EntriesPerPageDropdown";
import PaginationControls from "../Common/PaginationControls";


const CreateCategory = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: "", parent_id: "" });
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const { t} = useTranslation();


  useEffect(() => {
    fetchCategory(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage, searchQuery]);

  const fetchCategory = async (pageNumber = 1, perPage = 10) => {
    try {
      let requestUrl = `${BASE_URL}/v1/inventory_managment/categories?page=${pageNumber}&per_page=${perPage}`;
      if (searchQuery) {
        requestUrl += `&query=${searchQuery}`;
      }
      const response = await sendRequest('GET', requestUrl);
      if (response.status === 200) {
        setCategoryData(response.data.data);
        setTotalPages(Math.ceil(response.data.total_count / perPage));
        setTotalRecords(response.data.total_count);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCategory((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendRequest('POST', `${BASE_URL}/v1/inventory_managment/categories`, newCategory);
      if (response.status === 201) {
        setCategoryData([...categoryData, response.data]);
        setIsModalOpen(false);
        setNewCategory({ name: "", parent_id: "" });
      }
    } catch (error) {
      console.error("Error creating category:", error);
    }
  };

  const handleSearch = (query) => {

    if (query.length >= 2) {
      setSearchQuery(query);
    } else {
      setSearchQuery('');
      setCurrentPage(1);
    }
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getParentCategoryName = (parentId) => {
    const parentCategory = categoryData.find((category) => category.id === parentId);
    return parentCategory ? parentCategory.name : "";
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  return (
    <>
      <Header />
      <div className="category-main-container">
        <div className="category-headers-details">
          <div>
            <h3 className="title">{t("Category List")} ({totalRecords})</h3>
          </div>
          <button className="btn btn-primary" onClick={handleOpenModal}>
            + {t("Add Category")}
          </button>
        </div>

        <div className="item-category-second-header">
          <div className="category-pagination-dropdown">
            <EntriesPerPageDropdown
              recordPerPage={recordsPerPage}
              handleRecordPerPageChange={handleRecordPerPageChange}
            />
          </div>
          <div className="category-search-field" value={searchQuery} onChange={e => handleSearch(e.target.value)}>
            <Search placeholder={t("Search Categories ...")}/>
          </div>
        </div>
        <div className="category-table-list">
          <table>
            <thead>
              <tr>
                <th>{t("S. No.")}</th>
                <th>{t("Category Name")}</th>
                <th>{t("Parent Category")}</th>
              </tr>
            </thead>
            <tbody>
              {categoryData.length > 0 ? (
                categoryData.map((category, index) => (
                  <tr key={category.id}>
                    <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                    <td>{category.name}</td>
                    <td>{getParentCategoryName(category.parent_id)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">{searchQuery ? t("No match found") : t("No records found")}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div>
            <div className="report-pagination-info">
              <div className="report-pagination-container">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                  totalRecords={totalRecords}
                  startIndex={startIndex}
                  endIndex={endIndex}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="cc-modal-overlay">
          <div className="cc-modal-content">
            <div className="cc-modal-header">
              <span className="cc-modal-title">{t("Create New Category")}</span>
              <button className="cc-modal-close" onClick={handleCloseModal}>&times;</button>
            </div>
            <div className="cc-model-sub-container">
              <form onSubmit={handleSubmit}>
                <div className="">
                  <label htmlFor="name">{t("Category Name")}</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={newCategory.name}
                    onChange={handleChange}
                    maxLength={12}
                    required
                  />
                </div>
                <div className="">
                  <label htmlFor="parent_id">{t("Parent Category")}</label>
                  <select
                    name="parent_id"
                    value={newCategory.parent_id}
                    onChange={handleChange}
                    className="form-control"
                  >
                    <option value="">{t("Select a parent category")}</option>
                    {categoryData.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="cc-modal-footer">
                  <button type="submit" className="cc-modal-btn-create">{t("Create")}</button>
                  <button type="button" className="cc-modal-btn-cancel" onClick={handleCloseModal}>{t("Cancel")}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateCategory;
