import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import ErrorNotification from "../../../Notification/ErrorNotification";
import successNotification from "../../../Notification/SuccessNotification";

export const updateBillingStatus = async (id, selectedSupplier, navigate) => {
  
  try {
    const supplierParam = selectedSupplier ? `&supplier_id=${selectedSupplier.id}` : "";
    const response = await sendRequest(
      "put",
      `${BASE_URL}/v1/billings/bills/${id}/update_billing_status?is_purchase=true&status=1${supplierParam}`
    );

    if (response.status === 200) {
      successNotification("Payment Success");
      navigate("/addPurchase", { state: { tab: "transactions" } });
    }
  } catch (error) {
    ErrorNotification("Error updating billing status");
  }
};
