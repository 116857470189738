import secureLocalStorage from "react-secure-storage";
import { sendRequest } from "../ApiRequest";
import { BASE_URL } from "../Url/constants";
import ErrorNotification from "../Notification/ErrorNotification";

const saveUser = (user) => {
  try {
    secureLocalStorage.setItem('user', user);
  } catch (error) {
    ErrorNotification(error.message);
  }
};

const getUser = () => {
  try {
    const user = secureLocalStorage.getItem('user');
    if (user !== null) {
      return user;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error retrieving user:', error);
    return null;
  }
};

const authUser = (setIsloggedIn) => {
  setIsloggedIn(false);
}

const current_user = async () => {
  try {
    const response = await sendRequest(
      'GET',
      `${BASE_URL}/v1/user_details/get_user_details`,
      null
    );
    if (response.status === 200) {
      const userWithFeatures = {
        ...response.data,
        features: response.data.company.all_features
      };
      saveUser(userWithFeatures);
      return userWithFeatures;
    }
  } catch (error) {
    ErrorNotification(error.errors);
  }
};

export { saveUser, getUser, authUser, current_user };
