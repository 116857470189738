import React, { useState, useEffect } from "react";
import "./template_setting.css";
import "react-multi-carousel/lib/styles.css";
import { BsPencilSquare } from "react-icons/bs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ReceiptFirst from "../Receipt/ReceiptFirst/ReceiptFirst";
import ReceiptSecond from "../Receipt/ReceiptSecond/ReceiptSecond";
import ReceiptThird from "../Receipt/ReceiptThird/ReceiptThird";
import ReceiptFour from "../Receipt/ReceiptFour/ReceiptFour";
import { BASE_URL, SUCCESS_MESSAGE, UPDATE_ERROR_MSG } from "../../Url/constants";
import { getToken } from "../SecureStorage/Token";
import ErrorNotification from "../../Notification/ErrorNotification";
import axios from 'axios';
import SuccessNotification from "../../Notification/SuccessNotification";
import { NotificationContainer } from "react-notifications";
import { sendRequest } from "../../ApiRequest";
import Copyrights from "../Copyrights";
import { useTranslation } from "react-i18next";


function TemplateSettingView() {
  const [selectedItem, setSelectedItem] = useState(0);
  // const [hoveredItem, setHoveredItem] = useState(null);
  // const [isHovered, setIsHovered] = useState(Array(4).fill(false));
  const [editVisible, setEditVisible] = useState([]);
  const [templateConfigData, setTemplateConfigData] = useState([]);
  const [DefaultTemplate, setDefaultTemplate] = useState();
  const token1 = getToken();
  const { t} = useTranslation();

  const handleItemClick = async (index) => {
    // setSelectedItem(index === selectedItem ? null : index);
    if (index !== selectedItem) {
      setSelectedItem(index);
      await handleTemplateChange(index + 1);
      updateDefaultTemaplte(index + 1);
    }
  };

  useEffect(() => {
    getDefaultTemaplate();
  }, [])

  const getDefaultTemaplate = async () => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/template/templates/get_default_template`)
    if (response.status === 200) {
      setSelectedItem(response?.data?.data?.id - 1 );
      handleTemplateChange(response?.data?.data?.id);
    }
  }

  const updateDefaultTemaplte = async (template) => {
    const params = {
      template_id: template
    }
    await sendRequest('PUT', `${BASE_URL}/v1/template/templates/set_default_template`, params)
      .then(response => {
        if (response.status === 200) {
          setDefaultTemplate(template);
        }
      })
  };

  const handleTemplateChange = async (templateId = DefaultTemplate) => {
    const url = `${BASE_URL}/v1/template/templates/${templateId}`;
    const response = await sendRequest('GET', url);
    if (response.status === 200) {
      const data = response.data;
      setTemplateConfigData(data.data.template_options);
      updateDefaultTemaplte(templateId);
    }
  };

  // const handleItemHover = (index, isHovering) => {
  //   setHoveredItem(isHovering ? index : null);
  //   setIsHovered(prevState => {
  //     const newState = [...prevState];
  //     newState[index] = isHovering;
  //     return newState;
  //   });
  // };

  const toggleEditVisibility = (index) => {
    setEditVisible(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleCheckboxChange = (index) => {
    setTemplateConfigData(prevState => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        template_config: {
          ...newState[index].template_config,
          is_enable: !newState[index].template_config?.is_enable
        }
      };
      return newState;
    });
  };

  const handleTextChange = (index, text) => {
    setTemplateConfigData(prevState => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        template_config: {
          ...newState[index].template_config,
          free_text: text
        }
      };
      return newState;
    });
  };

  const handleSave = async () => {
    const data = {
      template_config_data: templateConfigData.map(option => ({
        template_option_id: option.id,
        free_text: option?.template_config?.free_text,
        is_enable: option?.template_config?.is_enable
      }))
    };
  
    try {
      const response = await sendRequest('put', `${BASE_URL}/v1/template/templates/${selectedItem + 1}`, data); 
      SuccessNotification(SUCCESS_MESSAGE('updated', 'template'));
    } catch (error) {
      ErrorNotification(UPDATE_ERROR_MSG('template'));
    }
  };

  const enabledTemplateData = templateConfigData
    .filter(option => option?.template_config?.is_enable)
    .map(option => ({
      option_name: option?.option_name,
      free_text: option?.template_config.free_text,
      is_enable: option?.template_config?.is_enable
    }));

  return (
    <>
      <div className="template-main-content">
        <div className="template-setting-box-container">
          <div className="inner-box-1">
            {[1, 2, 3, 4].map((item, index) => (
              <div
                key={index}
                onClick={() => handleItemClick(index)}
                // onMouseEnter={() => handleItemHover(index, true)}
                // onMouseLeave={() => handleItemHover(index, false)}
                className={`temp-img-align mt-3 ${selectedItem === index ? "selected" : ""}`}
              >
                <img
                  src={`/receipt${item}.png`}
                  alt={`demoImage${item}`}
                  className="box-temp-setting-image"
                />
                {selectedItem === index && <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />}
              </div>
            ))}
          </div>

          {selectedItem !== null && (
            <div className="template-main-sub-content">
              <div className="inner-box-2">
                <div className="template-setting-title">
                  {t("Template Setting")}
                </div>
                <div className="setting-template-figure mt-3">
                  <div className="temp-setting-options-container">
                    {templateConfigData.map((option, index) => (
                      <div key={option.id} className="temp-option-item">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={option.template_config?.is_enable}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <label className="label-space">{option.option_name.replace(/_/g, ' ')}</label>
                        {!["company_logo", "invoice_number", "barcode", "company_signature", "discount", "gst_number", "total_quantity", "display_amount_with_decimal", "display_received_amount", "display_balance_amount", "date", "time"].includes(option.option_name) && option.template_config?.is_enable && (
                          <>
                            <BsPencilSquare
                              className="edit-icon"
                              onClick={() => toggleEditVisibility(index)}
                            />
                            {editVisible[index] && (
                              <input
                                type="text"
                                className="form-control temp-setting-edti-fied"
                                placeholder={`Add ${option.option_name.replace(/_/g, ' ')}`}
                                value={option.template_config.free_text || ''}
                                onChange={(e) => handleTextChange(index, e.target.value)}
                              />
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-12 mt-10 text-right">
                  <button className="btn btn-primary save-btn-i" onClick={handleSave}>{t("Save")}</button>
                </div>
              </div>
            </div>
          )}
        </div>

        {selectedItem !== null && (
          <div className="right-content">
            <div className="right-content-inner">
              {selectedItem === 0 && <ReceiptFirst enabledTemplateData={enabledTemplateData} />}
              {selectedItem === 1 && <ReceiptSecond enabledTemplateData={enabledTemplateData} />}
              {selectedItem === 2 && <ReceiptThird enabledTemplateData={enabledTemplateData} />}
              {selectedItem === 3 && <ReceiptFour enabledTemplateData={enabledTemplateData} />}
            </div>
          </div>
        )}
        <NotificationContainer />
      </div>
      <Copyrights />
    </>
  );
}

export default TemplateSettingView;
