import React, { useState, useEffect } from "react";
import { sendRequest } from "../ApiRequest";
import { BASE_URL } from "./../Url/constants";
import { getToken } from "../components/SecureStorage/Token";
import Header from "./Header";
import "./CompanyUserCss/UpdateCompany.css";
import { BsPencilSquare, BsFillTrashFill } from "react-icons/bs";
import { Modal, Button } from "react-bootstrap";
import ErrorNotification from "../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import successNotification from "../Notification/SuccessNotification";
import ConfirmPopup from "./ConfirmPop";
import { current_user } from "../Authentication/AuthenticationContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import QRCodePopup from "./QRCodePopup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Copyrights from "./Copyrights";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import RestaurantPlatforms from "./Restaurant/OnlineOrder/RestaurantPlateforms";

const CompanyInfo = () => {
  const token = getToken();
  const [data, setCompanyData] = useState(null);
  const [updatedCompanyData, setUpdatedCompanyData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [ownerData, setOwnerData] = useState({});
  const [branches, setBranches] = useState([]);
  const [currentBranch, setCurrentBranch] = useState({});
  const [isBranchEditing, setIsBranchEditing] = useState(false);
  const [imagePreviewLogo, setImagePreviewLogo] = useState("");
  const [imagePreviewSignature, setImagePreviewSignature] = useState("");
  const [isCreatingBranch, setIsCreatingBranch] = useState(false);
  const [newBranch, setNewBranch] = useState("");
  const [branchUpdated, setBranchUpdated] = useState(false);
  const [showDeletePop, setShowDeletePop] = useState(false);
  const [pinCodeErrorShown, setPinCodeErrorShown] = useState(false);
  const [user, setUser] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  // const { t, i18n } = useTranslation();
  const [showQRModal, setShowQRModal] = useState(false);
  const [subdomain, setSubdomain] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const { t } = useTranslation();
  const [showQRPopup, setShowQRPopup] = useState(false);
  const [upiId, setUpiId] = useState("");
  const [showRestaurantPlatforms, setShowRestaurantPlatforms] = useState(false);
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchSelectedLanguage = async () => {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/setting/settings/get_selected_language`,
          null
        );
        if (response.status === 200) {
          localStorage.setItem("selectedLanguage", response.data.value);
          setSelectedLanguage(response.data.value);
        } else {
          ErrorNotification("Failed to fetch selected language");
        }
      } catch (error) {
        ErrorNotification(error.errors);
      }
    };
    fetchSelectedLanguage();
  }, []);

  useEffect(() => {
    if (data && data.menu_qr) {
      setQrCodeUrl(data.menu_qr);
    }
    if (data && data.subdomain) {
      setSubdomain(data.subdomain.replace(/^www\./, ""));
    }
  }, [data]);

  const handleGenerateQRCode = async (newSubdomain) => {
    try {
      const response = await sendRequest(
        "PATCH",
        `${BASE_URL}/v1/onboarding/companies/${data.id}`,
        { company: { subdomain: `www.${newSubdomain}` } }
      );

      if (response.status === 200) {
        setSubdomain(newSubdomain);
        successNotification("Subdomain updated successfully!");

        // Generate QR code
        const qrResponse = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/companies/generate_qrcode`
        );

        if (qrResponse.status === 200) {
          setQrCodeUrl(qrResponse.data.menu_qr);
          successNotification("QR Code generated successfully!");
          fetchData();
        }
      } else {
        ErrorNotification("Failed to update subdomain");
      }
    } catch (error) {
      ErrorNotification("Error updating subdomain and generating QR code");
    }
  };

  const handleUpdateUPI = async (newUpiId) => {
    try {
      const response = await sendRequest(
        "PATCH",
        `${BASE_URL}/v1/onboarding/companies/${data.id}`,
        { company: { upi_id: newUpiId } }
      );

      if (response.status === 200) {
        setUpiId(newUpiId);
        successNotification("UPI ID updated successfully!");
        fetchData();
      }
    } catch (error) {
      ErrorNotification("Failed to update UPI ID");
    }
  };

  const fetchUser = async () => {
    const currentUser = await current_user();
    setUser(currentUser);
  };

  const [branchCode, setBranchCode] = useState(
    isBranchEditing ? currentBranch.branch_code : newBranch.branch_code || ""
  );
  const [pinCode, setPinCode] = useState(
    isBranchEditing ? currentBranch.pin_code : newBranch.pin_code || ""
  );
  const [branchCodeError, setBranchCodeError] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");

  const MAX_BRANCH_CODE_LENGTH = 10;
  const PIN_CODE_LENGTH = 6;

  const handleBranchCodeChange = (e) => {
    const value = e.target.value;
    const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, "");

    if (branchCode.includes(alphanumericValue)) {
      setBranchCodeError("Branch code must be unique");
    } else {
      setBranchCodeError("");
    }

    if (alphanumericValue.length <= MAX_BRANCH_CODE_LENGTH) {
      setBranchCode(alphanumericValue);
      handleInputChange({
        target: { name: "branch_code", value: alphanumericValue },
      });
    }
  };

  const handlePinCodeChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");

    if (pinCode.includes(numericValue)) {
      setPinCodeError("Pin code must be unique");
    } else {
      setPinCodeError("");
    }

    if (numericValue.length <= PIN_CODE_LENGTH) {
      setPinCode(numericValue);
      handleInputChange({ target: { name: "pin_code", value: numericValue } });
    }

    if (numericValue.length !== PIN_CODE_LENGTH) {
      setPinCodeError(`Pin code must be ${PIN_CODE_LENGTH} digits long`);
    } else {
      setPinCodeError("");
    }
  };

  useEffect(() => {
    fetchData();
  }, [token, branchUpdated]);

  const fetchData = async () => {
    try {
      const [userResponse, companyResponse] = await Promise.all([
        current_user(),
        sendRequest("GET", `${BASE_URL}/v1/onboarding/companies/current_user_company`, null)
      ]);

      setUser(userResponse);
      const responseData = companyResponse.data;
      setCompanyData(responseData.data);
      setUpdatedCompanyData(responseData.data);
      setOwnerData(responseData.data.user_info);

      // Filter branches based on user role
      const allBranches = responseData.data.all_branches || [];
      const userRole = userResponse?.user_detail?.role?.name;
      const userBranch = userResponse?.user_detail?.associated_branch;

      if (userRole === "owner") {
        setBranches(allBranches);
      } else if (["manager", "staff", "cashier", "chef"].includes(userRole) && userBranch) {
        setBranches([userBranch]);
      } else {
        setBranches([]);
      }

      setImagePreviewLogo(responseData.data.logo_url);
      setImagePreviewSignature(responseData.data.authority_signature_url);
      setQrCodeUrl(responseData.data.menu_qr);
      setSubdomain(responseData.data.subdomain?.replace(/^www\./, "") || "");
      setUpiId(responseData.data.upi_id || "");
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };


  function handleTimeInputChange(event) {
    const { name, value } = event.target;

    let formattedValue = value.replace(/[^0-9:]/g, "");

    if (formattedValue.length > 2 && formattedValue.charAt(2) !== ":") {
      formattedValue =
        formattedValue.slice(0, 2) + ":" + formattedValue.slice(2, 4);
    }

    if (formattedValue.length > 5) {
      formattedValue = formattedValue.slice(0, 5);
    }

    setUpdatedCompanyData({
      ...updatedCompanyData,
      [name]: formattedValue,
    });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "city" || name === "state") {
      const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, "");

      if (name === "city" && alphabeticValue !== value) {
        setCityError("City name must contain only letters.");
        return;
      } else {
        setCityError("");
      }

      if (name === "state" && alphabeticValue !== value) {
        setStateError("State name must contain only letters.");
        return;
      } else {
        setStateError("");
      }
    }

    if (name === "pin_code" && !/^\d*$/.test(value)) {
      if (!pinCodeErrorShown) {
        ErrorNotification("Pin Code must be an integer.");
        setPinCodeErrorShown(true);
      }
      return;
    } else {
      setPinCodeErrorShown(false);
    }
    if (isBranchEditing) {
      setCurrentBranch({ ...currentBranch, [name]: value });
    } else if (isCreatingBranch) {
      setNewBranch({ ...newBranch, [name]: value });
    } else {
      setUpdatedCompanyData({ ...updatedCompanyData, [name]: value });
      setOwnerData({ ...ownerData, [name]: value });
    }
  };

  const handleImageLogoChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setUpdatedCompanyData({ ...updatedCompanyData, logo: selectedFile });

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewLogo(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleImageSignatureChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setUpdatedCompanyData({
        ...updatedCompanyData,
        authority_signature: selectedFile,
      });

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewSignature(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("company[name]", updatedCompanyData.name);
    formData.append("company[gst_number]", updatedCompanyData.gst_number);
    if (updatedCompanyData.logo instanceof File) {
      formData.append("company[logo]", updatedCompanyData.logo);
    }
    if (updatedCompanyData.authority_signature instanceof File) {
      formData.append(
        "company[authority_signature]",
        updatedCompanyData.authority_signature
      );
    }
    formData.append("company[business_type]", updatedCompanyData.business_type);
    formData.append("company[start_time]", updatedCompanyData.start_time);
    formData.append("company[end_time]", updatedCompanyData.end_time);
    formData.append(
      "company[business_category]",
      updatedCompanyData.business_category
    );
    formData.append("company[users_attributes][0][id]", ownerData.id);
    formData.append("company[users_attributes][0][email]", ownerData.email);
    formData.append("company[users_attributes][0][phone]", ownerData.phone);
    formData.append("name", selectedLanguage);
    try {
      const response = await sendRequest(
        "PATCH",
        `${BASE_URL}/v1/onboarding/companies/${updatedCompanyData.id}`,
        formData,
        token
      );

      if (response.status === 200) {
        fetchData();
        setIsEditing(false);
        successNotification("Company Updated");
        window.location.reload();
      } else {
        console.error("Failed to update company data");
      }
    } catch (error) {
      error.data?.errors.map((errorMessage) => ErrorNotification(errorMessage));
    }
  };

  const openBranchEditModal = (branch) => {
    setCurrentBranch(branch);
    setIsBranchEditing(true);
  };

  const openCreateBranchModal = () => {
    setIsCreatingBranch(true);
    setNewBranch({});
  };

  const handleBranchSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    const formData = new FormData();
    formData.append("branch[id]", currentBranch.id);
    formData.append("branch[branch_name]", currentBranch.branch_name);
    formData.append("branch[branch_code]", currentBranch.branch_code);
    formData.append("branch[address]", currentBranch.address);
    formData.append("branch[city]", currentBranch.city);
    formData.append("branch[state]", currentBranch.state);
    formData.append("branch[pin_code]", currentBranch.pin_code);

    if (!currentBranch.city) newErrors.city = true;
    if (!currentBranch.pin_code) newErrors.pin_code = true;

    try {
      const response = await sendRequest(
        "PATCH",
        `${BASE_URL}/v1/onboarding/companies/${updatedCompanyData.id}/branches/${currentBranch.id}`,
        formData,
        token
      );
      if (response.status === 200) {
        const updatedBranch = response.data;
        setBranches(
          branches.map((branch) =>
            branch.id === updatedBranch.id ? updatedBranch : branch
          )
        );
        setIsBranchEditing(false);
        handleClose();
        successNotification("Branch Updated!");
        setStateError("");
        setCityError("");
      } else {
        console.error("Failed to update branch data");
      }
    } catch (error) {
      error.data.errors.map((errorMessage) => ErrorNotification(errorMessage));
    }
  };

  const handleCreateBranchSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("branch[branch_name]", newBranch.branch_name || "");
    formData.append("branch[branch_code]", newBranch.branch_code || "");
    formData.append("branch[address]", newBranch.address || "");
    formData.append("branch[city]", newBranch.city || "");
    formData.append("branch[state]", newBranch.state || "");
    formData.append("branch[pin_code]", newBranch.pin_code || "");

    console.log(newBranch);
    try {
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/onboarding/companies/${updatedCompanyData.id}/branches`,
        formData
      );

      if (response.status === 200) {
        const createdBranch = response.data;
        setBranches([...branches, createdBranch]);
        setIsCreatingBranch(false);
        setBranchUpdated((prev) => !prev);
        handleClose();
        successNotification("Branch Created!");
        setBranchCode();
        setPinCode();
        setStateError("");
        setCityError("");
      }
    } catch (error) {
      error.data.errors.map((errorMessage) => ErrorNotification(errorMessage));
    }
  };

  const handleDeleteButtonClick = (branchId) => {
    setCurrentBranch(branchId);
    setShowDeletePop(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await sendRequest(
        "DELETE",
        `${BASE_URL}/v1/onboarding/companies/${updatedCompanyData.id}/branches/${currentBranch}`,
        null
      );

      if (response.status === 200) {
        setBranches(branches.filter((branch) => branch.id !== currentBranch));
        setShowDeletePop(false);
        successNotification("Branch Deleted!");
      } else {
        console.error("Failed to delete branch");
      }
    } catch (error) {
      error.data.errors.map((errorMessage) => ErrorNotification(errorMessage));
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePop(false);
  };

  const handleClose = () => {
    setIsEditing(false);
    setIsBranchEditing(false);
    setIsCreatingBranch(false);
    setStateError("");
    setCityError("");
  };

  const selectUserLanguage = (lang) => {
    setSelectedLanguage(lang);
  };

  if (!data) {
    return <div>{t("Loading...")}</div>;
  }

  return (
    <>
      <Header />
      <NotificationContainer />
      <div
        className="new-content"
        style={{ maxHeight: "800px", overflow: "auto" }}
      >
        <div>
          {!isEditing ? (
            <div>
              <div className="company-show-main-content">
                <div className="com-detail">
                  <span id="company_heading">{t("Company Details")}</span>
                  <div className="com-actions">
                    {user &&
                      !["manager", "staff", "cashier", "chef"].includes(
                        user?.user_detail?.role?.name
                      ) && (
                        <div className="com-edit">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="edit-tooltip">
                                {t("Update company details")}
                              </Tooltip>
                            }
                          >
                            <a
                              href="#"
                              onClick={() => setIsEditing(true)}
                              style={{ marginRight: "10px" }}
                            >
                              <BsPencilSquare />
                            </a>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="qr-tooltip">
                                {t("Update Your Company QR code")}
                              </Tooltip>
                            }
                          >
                            <a href="#" onClick={() => setShowQRPopup(true)}>
                              <FontAwesomeIcon icon={faQrcode} />
                            </a>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="restaurant-tooltip">
                                {t("Update Restaurant Platform Integration")}
                              </Tooltip>
                            }
                          >
                            <a
                              href="#"
                              onClick={() => setShowRestaurantPlatforms(true)}
                              style={{ marginLeft: "10px" }}
                            >
                              <FontAwesomeIcon icon={faUtensils} />
                            </a>
                          </OverlayTrigger>
                        </div>
                      )}
                  </div>
                </div>
                <div className="company-show-sub-container">
                  <div className="show-company-details-section">
                    <div className="detail-item">
                      <strong>{t("Name")}:</strong> <span>{data.name}</span>
                    </div>
                    <div className="detail-item">
                      <strong>{t("GST")}:</strong>{" "}
                      <span>{data.gst_number}</span>
                    </div>
                    <div className="detail-item">
                      <strong>{t("Business Type")}:</strong>{" "}
                      <span>{data.business_type}</span>
                    </div>
                    <div className="detail-item">
                      <strong>{t("Business Category")}:</strong>{" "}
                      <span>{data.business_category}</span>
                    </div>
                    <div className="detail-item">
                      <strong>{t("Email")}:</strong>{" "}
                      <span>{ownerData.email}</span>
                    </div>
                    <div className="detail-item">
                      <strong>{t("Phone")}:</strong>{" "}
                      <span>{ownerData.phone}</span>
                    </div>
                    <div className="detail-item">
                      <strong>{t("Start Time")}:</strong>{" "}
                      <span>{updatedCompanyData.start_time}</span>
                    </div>
                    <div className="detail-item">
                      <strong>{t("End Time")}:</strong>{" "}
                      <span>{updatedCompanyData.end_time}</span>
                    </div>
                    <div className="detail-item">
                      <strong>{t("Logo")}:</strong>
                      <div>
                        <img
                          src={data.logo_url}
                          alt="Company Logo"
                          className="logo-size"
                        />
                      </div>
                    </div>
                    <div className="detail-item">
                      <strong>{t("Signature")}:</strong>
                      <div>
                        <img
                          src={data.authority_signature_url}
                          alt="Company Signature"
                          className="logo-size"
                        />
                      </div>
                    </div>
                    <div className="detail-item">
                      <strong>{t("QR Code")}:</strong>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {qrCodeUrl ? (
                          <img
                            src={qrCodeUrl}
                            alt="Company QR Code"
                            className="qr-code-size"
                          />
                        ) : (
                          <span>{t("No QR Code")}</span>
                        )}
                        <button
                          className="icon-button"
                          onClick={() => setShowQRPopup(true)}
                          style={{
                            marginLeft: "10px",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          {/* <FontAwesomeIcon icon={faQrcode} size="lg" /> */}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="branch-container-group">
                    <div className="company-branch-header-container">
                      <span className="company-branch-title">
                        {t("Branches")}
                      </span>
                      {user && user?.user_detail?.role?.name === "owner" && (
                        <Button
                          onClick={openCreateBranchModal}
                          style={{
                            marginTop: "-10px",
                            marginRight: "15px",
                          }}
                        >
                          {t("Add Branch")}
                        </Button>
                      )}
                    </div>
                    {branches.length > 0 && (
                      <div className="company-branch-container-grp">
                        <section className="d-flex flex-wrap">
                          {branches.map((branch) => (
                            <div
                              key={branch.id}
                              className="card p-2 m-2 text-end"
                              style={{ width: "400px", height: "500px" }}
                            >
                              <div className="card-header">
                                {user && user?.user_detail?.role?.name === "owner" && (
                                  <>
                                    <a href="#" onClick={() => openBranchEditModal(branch)}>
                                      <BsPencilSquare />
                                    </a>

                                    <a
                                      className="deletebtn delete-icon"
                                      onClick={() => handleDeleteButtonClick(branch.id)}>
                                      <BsFillTrashFill />
                                    </a>
                                  </>
                                )}
                              </div>
                              <div className="card-body text-start">
                                <dl>
                                  <dt className="bi bi-camera">
                                    {t("Branch Name")}:
                                  </dt>
                                  <dd className="bi bi-camera">
                                    {branch.branch_name}
                                  </dd>
                                  <dt className="bi bi-camera">
                                    {t("Branch Code")}:
                                  </dt>
                                  <dd className="bi bi-camera">
                                    {branch.branch_code}
                                  </dd>
                                  <dt className="bi bi-camera">
                                    {t("Branch Address")}:
                                  </dt>
                                  <dd className="bi bi-camera">
                                    {branch.address}
                                  </dd>
                                  <dt>{t("Branch City")}:</dt>
                                  <dd>{branch.city}</dd>
                                  <dt>{t("Branch State")}:</dt>
                                  <dd>{branch.state}</dd>
                                  <dt>{t("Branch Pin Code")}:</dt>
                                  <dd>{branch.pin_code}</dd>
                                </dl>
                              </div>
                            </div>
                          ))}
                        </section>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="content">
                <div className="col-md-12 margin-btm">
                  <h3 className="title">{t("Edit Company Details")}</h3>
                </div>
                <div className="create-company">
                  <div className="row">
                    <div className="col-md-6">
                      <h6>{t("Name")}</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company Name"
                        value={updatedCompanyData.name}
                        onChange={handleInputChange}
                        name="name"
                      />
                      {updatedCompanyData.name.length > 64 && (
                        <div
                          style={{ color: "red", marginTop: 7, fontSize: 12 }}
                        >
                          {t("Company name max length 64 character")}
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <h6>{t("GST Number")}</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="GST Number"
                        value={updatedCompanyData.gst_number}
                        onChange={handleInputChange}
                        name="gst_number"
                      />
                    </div>
                    <div className="col-md-6">
                      <h6>{t("Business Type")}</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company Business Type"
                        value={updatedCompanyData.business_type}
                        onChange={handleInputChange}
                        name="business_type"
                        readOnly
                      />
                    </div>
                    <div className="col-md-6">
                      <h6>{t("Business Category")}</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company Business Category"
                        value={updatedCompanyData.business_category}
                        name="business_category"
                        onChange={handleInputChange}
                        readOnly
                      />
                    </div>
                    <div className="col-md-6">
                      <h6>{t("Email")}</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email ID"
                        value={ownerData.email}
                        onChange={handleInputChange}
                        name="email"
                      />
                    </div>
                    <div className="col-md-6">
                      <h6>{t("Phone")}</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone No"
                        value={ownerData.phone}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^[6-9]\d{0,9}$/;

                          if (regex.test(value) || value === "") {
                            handleInputChange(e);
                          }
                        }}
                        name="phone"
                        maxLength={10}
                      />
                    </div>
                    <div className="col-md-6">
                      <h6>{t("Upload Logo")}</h6>
                      <input
                        type="file"
                        className="form-control upload-img"
                        onChange={handleImageLogoChange}
                        name="logo"
                      />
                      {imagePreviewLogo && (
                        <img
                          src={imagePreviewLogo}
                          alt="Company Logo"
                          className="logo-size"
                        />
                      )}
                    </div>

                    <div className="col-md-6">
                      <h6>{t("Upload Signature")}</h6>
                      <input
                        type="file"
                        className="form-control upload-img"
                        onChange={handleImageSignatureChange}
                        name="authority_signature"
                      />
                      {imagePreviewSignature && (
                        <img
                          src={imagePreviewSignature}
                          alt="Company Logo"
                          className="logo-size"
                        />
                      )}
                    </div>

                    <div className="col-md-2 language-selection-field">
                      <label>{t("Change Language")}</label>
                      <div className="">
                        <select
                          className="form-select"
                          value={selectedLanguage}
                          onChange={(e) => selectUserLanguage(e.target.value)}
                        >
                          <option value="" hidden disabled>
                            {t("Select Language")}
                          </option>
                          <option value="en">English</option>
                          <option value="hi">Hindi</option>
                        </select>

                      </div>
                    </div>


                    {updatedCompanyData.business_type === "restaurant" && (
                      <div className="col-md-12">
                        <h6>{t("Business Hours")}</h6>
                        <div className="company-profile-time-group">
                          <div className="form-group">
                            <label className="mr-2">{t("Start Time")}</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="HH:MM"
                              value={updatedCompanyData.start_time}
                              onChange={handleTimeInputChange}
                              name="start_time"
                              maxLength={5}
                              style={{ width: "80px" }}
                            />
                          </div>
                          <div className="form-group">
                            <label className="mr-2">{t("End Time")}</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="HH:MM"
                              value={updatedCompanyData.end_time}
                              onChange={handleTimeInputChange}
                              name="end_time"
                              maxLength={5}
                              style={{ width: "80px" }}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-md-12 mt-20 text-right">
                      <button
                        onClick={() => setIsEditing(false)}
                        className="btn btn-primary btn-cancel"
                        style={{ marginLeft: "10px" }}
                      >
                        {t("Cancel")}
                      </button>
                      <span className="button-space"></span>
                      <button
                        type="submit"
                        className="btn btn-primary btn-submit"
                      >
                        {t("Save")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
        <NotificationContainer />
      </div>
      <QRCodePopup
        show={showQRPopup}
        onHide={() => setShowQRPopup(false)}
        subdomain={subdomain}
        upiId={upiId}
        onGenerateQR={handleGenerateQRCode}
        onUpdateUPI={handleUpdateUPI}
      />
      <Modal show={showQRModal} onHide={() => setShowQRModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Generate QR Code")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>{t("Subdomain")}</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">www.</span>
              </div>
              <input
                type="text"
                className="form-control"
                value={subdomain.replace(/^www\./, "")} // Remove 'www.' if it exists
                onChange={(e) =>
                  setSubdomain(e.target.value.replace(/^www\./, ""))
                } // Prevent user from adding 'www.'
                placeholder="Enter subdomain"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowQRModal(false)}>
            {t("Close")}
          </Button>
          <Button
            variant="primary"
            onClick={handleGenerateQRCode}
            className="qr-code-generation"
          >
            {t("Generate QR Code")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isBranchEditing || isCreatingBranch} onHide={handleClose}>
        <div className="create-branch">
          <Modal.Header>
            <Modal.Title id="com-sub-model">
              {isBranchEditing ? "Edit Branch" : "Create Branch"}
            </Modal.Title>
            <button
              type="button"
              className="close_branch_model"
              onClick={handleClose}
              aria-label="Close"
            >
              &times;
            </button>
          </Modal.Header>
        </div>
        <Modal.Body>
          <form
            className=""
            onSubmit={
              isBranchEditing ? handleBranchSubmit : handleCreateBranchSubmit
            }
          >
            <div className="form-group">
              <label htmlFor="branch_name">{t("Branch Name")}</label>
              <input
                type="text"
                name="branch_name"
                className="form-control"
                value={
                  isBranchEditing
                    ? currentBranch.branch_name
                    : newBranch.branch_name || ""
                }
                onChange={handleInputChange}
              />
              {newBranch?.branch_name?.length > 64 && (
                <div style={{ color: "red", marginTop: 7, fontSize: 12 }}>
                  {t("Branch name max length 64 character")}
                </div>
              )}
            </div>
            <div className="form-group">
              <label>{t("Branch Code")}</label>
              <input
                type="text"
                name="branch_code"
                className="form-control"
                value={
                  isBranchEditing
                    ? currentBranch.branch_code
                    : newBranch.branch_code || ""
                }
                onChange={handleBranchCodeChange}
                maxLength={MAX_BRANCH_CODE_LENGTH}
              />
              {branchCodeError && (
                <small className="form-text text-danger">
                  {branchCodeError}
                </small>
              )}
            </div>

            <div className="form-group">
              <label>{t("Address")}</label>
              <input
                type="text"
                name="address"
                className="form-control"
                value={
                  isBranchEditing
                    ? currentBranch.address
                    : newBranch.address || ""
                }
                onChange={handleInputChange}
                maxLength="64"
              />
            </div>
            <div className="form-group">
              <label>{t("City")} *</label>
              <input
                type="text"
                name="city"
                className="form-control"
                value={
                  isBranchEditing ? currentBranch.city : newBranch.city || ""
                }
                onChange={handleInputChange}
                maxLength="32"
              />
              {cityError && (
                <small className="form-text text-danger">{cityError}</small>
              )}
            </div>
            <div className="form-group">
              <label>{t("State")}</label>
              <input
                type="text"
                name="state"
                className="form-control"
                value={
                  isBranchEditing ? currentBranch.state : newBranch.state || ""
                }
                onChange={handleInputChange}
                maxLength="32"
              />
              {stateError && (
                <small className="form-text text-danger">{stateError}</small>
              )}
            </div>
            <div className="form-group">
              <label>{t("Pin Code")}</label>
              <input
                type="text"
                name="pin_code"
                className="form-control"
                value={
                  isBranchEditing
                    ? currentBranch.pin_code
                    : newBranch.pin_code || ""
                }
                onChange={handlePinCodeChange}
                maxLength={PIN_CODE_LENGTH}
              />
              {pinCodeError && (
                <small className="form-text text-danger">{pinCodeError}</small>
              )}
            </div>

            <div className="form-group">
              {isBranchEditing ? (
                <Button variant="primary" type="submit">
                  {t("Update")}
                </Button>
              ) : (
                <Button variant="primary" type="submit">
                  {t("Save")}
                </Button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {showDeletePop && (
        <ConfirmPopup
          title={t("Confirm Delete")}
          message={t("Are you sure you want to delete this branch?")}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
      {showRestaurantPlatforms && (
        <RestaurantPlatforms
          companyId={updatedCompanyData.id}
          onClose={() => setShowRestaurantPlatforms(false)}
        />
      )}
      <Copyrights />
    </>
  );
};

export default CompanyInfo;
