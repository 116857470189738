import React, { useEffect, useState } from "react";
import { BsFillTrashFill } from "react-icons/bs";
import "./BillingTableOne.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function BillingTableOne({
  selectedItems,
  updateTotalPrice,
  updateTotalTaxPrice,
  updateTotalDiscount,
  updateItems,
  billSettings,
}) {
  const [items, setItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [actualPrice, setActualPrice] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    const updatedItems = selectedItems.reduce(
      (acc, item) => {
        const existingItem = acc.find((i) => i.item_code === item.item_code);
        if (!existingItem) {
          const totalStockQuantity = item?.stocks?.reduce(
            (sum, stock) => sum + stock.quantity,
            0
          );
          acc.push({
            ...item,
            quantityUpdated: false,
            actualQuantity: totalStockQuantity,
            quantity: 1,
            diffrence_discount: 0,
            stock_ids: [],
          });
        }
        return acc;
      },
      [...items]
    );
    setItems(updatedItems);
  }, [selectedItems]);

  useEffect(() => {
    const actual_price = items.reduce((acc, item) => {
      return acc + item.quantity * item.sales_price;
    }, 0);
    const total = items.reduce((acc, item) => {
      return acc + item.quantity * item.sale_amount - item.diffrence_discount;
    }, 0);
    const total_tax = items.reduce((acc, item) => {
      return acc + item.quantity * item.tax_amount;
    }, 0);
    const total_discount = items.reduce((acc, item) => {
      return acc + item.quantity * item.discount_amount + item.diffrence_discount;
    }, 0);
    setActualPrice(actual_price);
    setTotalPrice(total);
    setTotalTax(total_tax);
    setTotalDiscount(total_discount);
    updateTotalPrice(total);
    updateTotalTaxPrice(total_tax);
    updateTotalDiscount(total_discount);
  }, [items]);

  const isCheckboxEnabled = (optionKey) => {
    const option = billSettings.find((setting) => setting.name === optionKey);
    return option && option.billing_setting_config
      ? option.billing_setting_config.status
      : false;
  };

  const discountEnabled = isCheckboxEnabled("discount");
  const taxEnabled = isCheckboxEnabled("item_wise_tax");

  const handleQuantityChange = (index, newQuantity) => {
    const updatedItems = [...items];
    const itemActualQuantity = updatedItems[index].actualQuantity;
    const clampedQuantity = Math.max(
      1,
      Math.min(newQuantity, itemActualQuantity)
    );
    const { salesPrice, saleAmount, diffrenceDiscount, stockIds } = calculateSalesData(updatedItems[index], clampedQuantity);
    updatedItems[index].quantity = clampedQuantity;
    updatedItems[index].sales_price = salesPrice;
    updatedItems[index].sale_amount = saleAmount;
    updatedItems[index].diffrence_discount = diffrenceDiscount;
    if (!Array.isArray(updatedItems[index].stock_ids)) {
      updatedItems[index].stock_ids = [];
    }
    updatedItems[index].stock_ids = [...new Set([...updatedItems[index].stock_ids, ...stockIds])];  
    updatedItems[index].quantityUpdated = true;
    setItems(updatedItems);
    updateItems(updatedItems);
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
    updateItems(updatedItems);
  };

  const formatPrice = (price) => {
    return price.toFixed(2);
  };

  function calculateSalesData(item, requestedQty) {
    let remainingQty = requestedQty;
    let totalPrice = 0;
    let diffrenceDiscount = 0;
    let usedStocks = [];
    const sortedStocks = [...item.stocks].sort((a, b) => a.sale_price - b.sale_price);

    for (let stock of sortedStocks) {
      if (remainingQty <= 0) break;
      const applicableQty = Math.min(remainingQty, stock.quantity);
      totalPrice += applicableQty * stock.sale_price;
      usedStocks.push({id: stock.id, price: stock.sale_price, qty: applicableQty });

      remainingQty -= applicableQty;
    }
    const stockIds = usedStocks.map(stock => stock.id);
    const salesPrice = usedStocks[usedStocks.length - 1].price;
    diffrenceDiscount = salesPrice * requestedQty - totalPrice;
    const saleAmount = item.is_tax_inclusive ? (salesPrice - (item.tax_amount + item.discount_amount)) : (salesPrice - item.discount_amount);
    return {
      salesPrice,
      saleAmount,
      diffrenceDiscount,
      stockIds
    };
  }
  

  const renderItemData = () => {
    return items.map((item, index) => (
      <tr key={index}>
        <td className="centered">{index + 1}</td>
        <td className="centered">{item.item_code}</td>
        <td className="centered">
          {item.item_name}
          {/* {item.stockStatus && (
            <span
              className={`stock-label ${
                item.stockStatus === "New Stock" ? "new-stock" : "old-stock"
              }`}
            >
              ({item.stockStatus})
            </span>
          )} */}
        </td>
        <td className="centered quantity-container-1">
          <div className="quantity-control">
            <button
              className="quantity-button-1"
              onClick={() => handleQuantityChange(index, item.quantity - 1)}
              disabled={item.quantity <= 1}
            >
              <FontAwesomeIcon icon={faSquareMinus} />
            </button>
            <span className="quantity-number">{item.quantity}</span>
            <button
              className="quantity-button-1"
              onClick={() => handleQuantityChange(index, item.quantity + 1)}
              disabled={item.quantity >= item.actualQuantity}
            >
              <FontAwesomeIcon icon={faSquarePlus} />
            </button>
          </div>
        </td>
        <td className="centered">
          {formatPrice(item.quantity * item.sales_price)}
          <span className="tax-label">
            <strong style={{ color: item.is_tax_inclusive ? "grey" : "grey" }}>
              ({item.is_tax_inclusive ? "Incl.Tax" : "Excl.Tax"})
            </strong>
          </span>
        </td>
        {discountEnabled && (
          <td className="centered">
            {formatPrice((item.quantity * item.discount_amount) + item.diffrence_discount)}
          </td>
        )}
        {taxEnabled && (
          <td className="centered">
            {formatPrice(item.quantity * item.tax_amount)}
          </td>
        )}
        <td className="centered">
          {formatPrice((item.quantity * item.sale_amount) - item.diffrence_discount)}
        </td>
        <td className="centered">
          <a className="deletebtn" onClick={() => handleDeleteItem(index)}>
            <BsFillTrashFill />
          </a>
        </td>
      </tr>
    ));
  };

  return (
    <div className="table-container billing-table-container">
      <table className="table">
        <thead className="sticky-header">
          <tr>
            <th scope="col" className="centered column-width-1">
              #
            </th>
            <th scope="col" className="centered column-width-2">
              {t("Item Code")}
            </th>
            <th scope="col" className="centered column-width-3">
              {t("Item Name")}
            </th>
            <th scope="col" className="centered column-width-4">
              {t("Quantity")}
            </th>
            <th scope="col" className="centered column-width-4">
              {t("Actual Price")}
            </th>
            {discountEnabled && (
              <th scope="col" className="centered column-width-5">
                {t("Discount")}
              </th>
            )}
            {taxEnabled && (
              <th scope="col" className="centered column-width-5">
                {t("Tax")}
              </th>
            )}
            <th scope="col" className="centered column-width-5">
              {t("Taxable Amount")}
            </th>
            <th scope="col" className="centered column-width-6">
              {t("Actions")}
            </th>
          </tr>
        </thead>
        <tbody>{renderItemData()}</tbody>
        {items.length > 0 && (
          <tfoot className="sticky-footer">
            <tr>
              <td colSpan="4" className="centered">
                <strong>{t("Total Price")}</strong>
              </td>
              <td colSpan="1" className="centered">
                <strong>{formatPrice(actualPrice)}</strong>
              </td>
              {discountEnabled && (
                <td colSpan="1" className="centered">
                  <strong>{formatPrice(totalDiscount)}</strong>
                </td>
              )}
              {taxEnabled && (
                <td colSpan="1" className="centered">
                  <strong>{formatPrice(totalTax)}</strong>
                </td>
              )}
              <td colSpan="1" className="centered">
                <strong>{formatPrice(totalPrice)}</strong>
              </td>
              <td colSpan="1"></td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
}

export default BillingTableOne;
