import React, { useEffect, useRef, useState } from "react";
import { sendRequest } from "../ApiRequest";
import { INPUT_PLACEHOLDER } from "../Url/constants";
import { BASE_URL } from "../Url/constants";
import { getToken } from "./SecureStorage/Token";
import ErrorNotification from "../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import successNotification from "../Notification/SuccessNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const GetCustomer = ({ onCustomerSelect }) => {
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const token = getToken();

  const handleSearch = async (query) => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/onboarding/customers/customer_search?query=${query}`,
        null,
        token
      );
      setSearchResults(response.data);
    } catch (error) {
      ErrorNotification("Customer Not Found");
    }
  };

  const handleCustomerSelect = (customer) => {
    if (customer.data) {
      onCustomerSelect(customer.data);
      setSelectedCustomer(customer.data.phone);
    } else {
      onCustomerSelect(customer);
      setSelectedCustomer(customer.phone);
    }
    setSearchResults([]);
  };

  return (
    <div className="Apps">
      <SearchableDropdownCustomer
        options={searchResults}
        onSearch={handleSearch}
        label="phone"
        selectedVal={selectedCustomer}
        handleChange={setSelectedCustomer}
        onCustomerSelection={handleCustomerSelect}
        setSearchResults={setSearchResults}
      />
    </div>
  );
};

const SearchableDropdownCustomer = ({
  options,
  label,
  selectedVal,
  handleChange,
  onSearch,
  onCustomerSelection,
  setSearchResults,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getDisplayValue = () => {
    return query || selectedVal;
  };

  const [errorShown, setErrorShown] = useState(false);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    const indianPhoneRegex = /^[6-9]\d{0,9}$/;

    if (event.target.name === "phone_number") {
      if (!indianPhoneRegex.test(inputValue)) {
        if (!errorShown) {
          ErrorNotification("Please enter a valid 10-digit phone number");
          setErrorShown(true);
        }
        return;
      } else {
        setErrorShown(false);
      }
    }

    setQuery(inputValue);
    handleChange(inputValue);

    if (inputValue.length >= 2) {
      onSearch(inputValue);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const handleEnter = async (event) => {
    if (event.key === "Enter" && query.trim() !== "") {
      let params = {};

      if (query.includes("@") && query.includes(".")) {
        params = {
          customer: {
            email: query,
          },
        };
      } else if (/^[6-9]\d{9}$/.test(query)) {
        params = {
          customer: {
            phone: query,
          },
        };
      } else {
        ErrorNotification("Please enter a valid 10-digit phone number");
        return;
      }

      try {
        const response = await sendRequest(
          "post",
          `${BASE_URL}/v1/onboarding/customers`,
          params,
          getToken(),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          successNotification("Customer Created Successfully!");
        }

        const newCustomer = response.data;
        await onSearch(newCustomer.phone || newCustomer.email);
        setSearchResults((prevResults) => [newCustomer, ...prevResults]);
        onCustomerSelection(newCustomer);
        setQuery(newCustomer.phone || newCustomer.email);
        setIsOpen(false);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="dropdown">
      <div className="col-md-14 input-search-bill">
        <input
          ref={inputRef}
          type="text"
          value={getDisplayValue()}
          name="searchTerm"
          placeholder={INPUT_PLACEHOLDER()}
          className="form-control"
          onChange={handleInputChange}
          onKeyPress={handleEnter}
          onFocus={() => {
            if (query.length >= 2) {
              setIsOpen(true);
            }
          }}
          autoComplete="off"
        />
        <div className="search-icon text-black-50 bg-white ps-1 me-2">
          <FontAwesomeIcon icon={faSearch} />
        </div>
      </div>
      {isOpen && options.length > 0 && (
        <div className="options open">
          {options.map((option, index) => (
            <div
              onClick={() => {
                setQuery(option.phone || option.email);
                onCustomerSelection(option);
                handleChange(option.phone || option.email);
                setIsOpen(false);
              }}
              className={`option ${option[label] === selectedVal ? "selected" : ""}`}
              key={index}
            >
              <div>
                {option.email}
                {option.email && option.phone ? ", " : ""}
                {option.phone}
              </div>
            </div>
          ))}
        </div>
      )}
      <NotificationContainer />
    </div>
  );
};

export default GetCustomer;
