import React, { useEffect, useState } from 'react'
import '../components/Dashboard/DashComponent.css';
import { DashPurchase } from '../components/Dashboard/DashComponents';
import { Expenses } from '../components/Dashboard/DashComponents';
import { Sale } from '../components/Dashboard/DashComponents';
import { YouRecieve } from '../components/Dashboard/DashComponents';
import { Youpay } from '../components/Dashboard/DashComponents';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { sendRequest } from '../ApiRequest';
import { BASE_URL } from '../Url/constants';
import ErrorNotification from '../Notification/ErrorNotification';

export const Dashboard = ({ handleLogout }) => {
  const [setIsDashboardClicked] = useState(false);
  const [fetchBills, setFetchBills] = useState(0.0);

  useEffect(() => {
    const fetch_bills = async () => {
      try {
        const response = await sendRequest(
          'GET',
          `${BASE_URL}/v1/reports/fetch_bills`,
          null
        );
        if (response.status === 200) {
          setFetchBills(response.data);
        } else {
          ErrorNotification("No data found");
        }
      } catch (error) {
        ErrorNotification(error.errors);
      }
    };
    fetch_bills();

  }, []);

  useEffect(() => {
    const fetchSelectedLanguage = async () => {
      try {
        const response = await sendRequest(
          'GET',
          `${BASE_URL}/v1/setting/settings/get_selected_language`,
          null
        );
        if (response.status === 200) {
          localStorage.setItem('selectedLanguage', response.data.value);
        } else {
          ErrorNotification("Failed to fetch selected language");
        }
      } catch (error) {
        ErrorNotification(error.errors);
      }
    };
    fetchSelectedLanguage();
  }, []);

  return (
    <>
      <Header handleLogout={handleLogout} setIsDashboardClicked={setIsDashboardClicked} />
      <div className='row'>
        <Sale />
        <Expenses />
      </div>
      <div className='row'>
        <YouRecieve fetchBills={fetchBills.you_revieve} />
        <Youpay fetchBills={fetchBills.you_pay} />
        <DashPurchase />
      </div>
      <Footer />
    </>
  )
};

export default Dashboard;
