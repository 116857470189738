import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../PlatformMenuList/OnlinePlatformMenu.css";

const OnlinePlatformMenu = ({ onClose }) => {
  const navigate = useNavigate();
  const modalRef = useRef(null);

  const handleNavigation = (path) => {
    navigate(path);
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
      <div ref={modalRef} className="online-order-dropdown-menu">
        <ul>
          <li onClick={() => handleNavigation("/online-order")}>Orders</li>
          <li onClick={() => handleNavigation("/online-store")}>Store</li>
          <li onClick={() => handleNavigation("/plateform-item-details")}>Items Detail</li>
          <li onClick={() => handleNavigation("/toggle-platform-item")}>Platform for Item</li>
          <li onClick={() => handleNavigation("/platform-for-store")}>Platform for Store</li>
          <li onClick={() => handleNavigation("/reports")}>Reports</li>
          <li onClick={() => handleNavigation("/create-customer")}>Customers</li>
        </ul>
      </div>
  );
};

export default OnlinePlatformMenu;
