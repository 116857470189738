import React, { useState, useEffect } from "react";
import './RestourentBillings.css';
import ReactDOM from 'react-dom';
import ItemSearchForRestourent from "./ItemSearchForRestourent";
import KotItemTable from "./KotItemTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHeart } from "@fortawesome/free-solid-svg-icons";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL, statusLabels } from "../../Url/constants";
import GroupCategory from "./GroupCategory";
import GroupSubCategories from "./GroupSubCategories";
import GroupSubCategoryItem from "./GroupSubCategoryItem";
import SearchItemForKot from "./SearchItemForKot";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorNotification from "../../Notification/ErrorNotification";
import successNotification from "../../Notification/SuccessNotification";
import SelectGuest from "../../components/Restaurant/SelectGuest/SelectGuest";
import SelectCaptain from "../../components/Restaurant/SelectCaptain/SelectCaptain";
import ItemDiscountInvoice from "../../components/Restaurant/ItemDiscountInvoice/ItemDiscountInvoice";
import ParcelCharges from "../../components/Restaurant/ParcelCharges/ParcelCharges";
import NoCharge from "../../components/Restaurant/NoCharges/NoCharge";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import PrintableInvoice from "../../components/PrintableInvoice";

const currDate = new Date().toLocaleDateString();
const currTime = new Date().toLocaleTimeString();

const RestourentBillings = () => {
  const [groupCategories, setGroupCategory] = useState([]);
  const [GroupSubCategory, setGroupSubCategory] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchedOptions, setSearchedOptions] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  // const { tableId, selectedPax, selectedCaptain } = location.state || {};
  const { orderType, tableId, tableStatus, tableData, selectedPax, selectedCaptainId, captionName } = location.state || {};
  // const { tableId, selectedPax} = location.state || {};
  const [currentOrderId, setCurrentOrderId] = useState("");
  const [isOpenGuestPopup, setOpenGuestPopup] = useState(false);
  const [subTotal, setSubTotal] = useState("");
  const [isOpenSelectCaptainPopup, setOpenSelectCaptainPopup] = useState(false);
  const [selectedCaptain, setSelectedCaptain] = useState("");
  const [orderDetails, setOrderDetails] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState([]);
  const [billNumber, setBillNumber] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [ParcelCharge, setParcelCharge] = useState("")
  const [isDiscountProvided, setIsDiscountProvided] = useState(false);
  const [discountType, setDiscountType] = useState(false);
  // const [serviceChargeProvided, setServiceChargeProvided] = useState("");
  // const [isServiceChargedEnabled, setisServiceChargedEnabled] = useState(true);
  const [sgst, setSgst] = useState("");
  const [cgst, setCgst] = useState("");
  const [isParceled, setIsParceled] = useState(false);
  // const [selectedCustomer, setSelectedCustomer] = useState("");
  const [isGstEnabled, setIsGstEnabled] = useState(true);
  const [discountReason, setDiscountReason] = useState([]);
  // const [inputValue, setInputValue] = useState("");
  const [isNoChargePopup, setNoChargePopup] = useState(false)
  const [SelctedNoChargeId, setselctedNoChargeId] = useState("");
  const [discountPercent, setDiscountPercent] = useState("");
  const [initialDiscountValue, setInitialDiscountValue] = useState("");
  const [isParcelPopup, setParcelPopup] = useState(false)
  const [isDiscountPopup, setDiscountPopup] = useState(false)
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [billingSettings, setBillingSettings] = useState([]);
  const [enableGST, setEnableGST] = useState(false);
  const [TotalDiscount, setTotalDiscount] = useState("");
  const [receiptTemplateId, setReceiptTemplateId] = useState(null);
  
  const [couponDiscount, setCouponDiscount] = useState([]);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponDiscountValue, setCouponDiscountValue] = useState("");

  const openGuestPopup = () => {
    setOpenGuestPopup(true);
  }

  const [activeCategory, setActiveCategory] = useState("");
  const [activeSubCategory, setActiveSubCategory] = useState("");
  const [activeItems, setActiveItems] = useState([]);

  const closeGuestPopup = (guest) => {
    if (guest) {
      setSelectedGuest(guest);
    }
    setOpenGuestPopup(false);
  };

  const openSelectCaptainPopup = () => {
    setOpenSelectCaptainPopup(true);
  }

  const closeSelectCaptainPopup = () => {
    setOpenSelectCaptainPopup(false);
  }

  const openParcelPopup = () => {
    setParcelPopup(true);
  }

  const closeParcelPopup = () => {
    setParcelPopup(false);
  }

  const discountPopupOpen = () => {
    setDiscountPopup(true);
  }

  useEffect(() => {
    if (tableStatus && tableStatus === 'running') {
      getLastOrder(tableId);
    }
    fetchOrderBillingSettings();
    fetchGSTSetting();
    fetchDefaultReceiptTemplate(); // Add this line
  }, [tableId]);

  const fetchDefaultReceiptTemplate = async () => {
    try {
      const response = await sendRequest('GET', `${BASE_URL}/v1/template/templates/get_default_receipt`);
      if (response.status === 200) {
        setReceiptTemplateId(response.data.data.id);
      }
    } catch (error) {
      console.error("Error fetching default receipt template:", error);
    }
  };

  useEffect(() => {
    if (['take_away', 'dine_in', 'home_delivery'].includes(orderType)) {
      handlefetchfevouriteItems(true);
      fetchInitialBillNumber();
    }
  }, [orderType])


  const fetchOrderBillingSettings = async () => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/setting/settings/billing_settings`)
    if (response.status === 200) {
      setBillingSettings(response.data.data);
    }
  }

  const fetchGSTSetting = async () => {
    const url = `${BASE_URL}/v1/setting/settings/get_setting_status?name=GST`;
    const response = await sendRequest('GET', url);
    if (response.status === 200) {
      const gstSetting = response.data;
      if (gstSetting) {
        setEnableGST(gstSetting.status);
      }
    }
  }

  const isCheckboxEnabled = (optionKey) => {
    const option = billingSettings.find(setting => setting.name === optionKey);
    return option && option.billing_setting_config ? option.billing_setting_config.status : false;
  };


  const fetchInitialBillNumber = async () => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/billings/last_invoice_bill_number`);
    if (response.status === 200) {
      const lastBillNumber = response.data;
      setBillNumber(lastBillNumber);
    }
  };

  const formatBillNumber = (number) => {
    return number.toString().padStart(4, "0");
  };

  const getLastOrder = async (table) => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/restaurant/orders/orders/get_table_currrent_order?floor_table_id=${table}&floor_id=${tableData?.floor_id}`)
    if (response.status === 200) {
      setCurrentOrderId(response.data.order_id);
      getOrderDetails(response.data?.order_id);
    }
  }

  const getOrderDetails = async (order_id) => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/restaurant/orders/orders/${order_id}`)
    if (response.status === 200) {
      setOrderDetails(response.data.data);
    }
  }

  const handleSelectOption = (option) => {
    setActiveItems(prevItemsId => [...prevItemsId, option.id]);
    setSelectedItems((prevItems) => [...prevItems, option]);
  };

  const handleInactiveDeletedItems = (option) => {
    setActiveItems(prevItemsId => prevItemsId.filter(id => id !== option.id));
  }

  const handleUpdateItems = (updatedItems) => {
    setSelectedItems(updatedItems);
  };

  const handleGroupCategories = async (category_name) => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/inventory_managment/categories?category_name=${category_name}`)
    if (response.status === 200 && response.data.data.length > 0) {
      setGroupCategory(response.data.data);
      setOptions([]);
      setGroupSubCategory([]);
    }
    else {
      const res = await sendRequest('GET', `${BASE_URL}/v1/inventory_managment/items?query=${category_name}&&per_page=50&is_billing=true`);
      if (res.status === 200) {
        const data = res.data;
        const fetchedOptions = data.data;
        setOptions(fetchedOptions);
        setGroupSubCategory([]);
      }
    }
  };

  const handleGroupSubCategories = async (id) => {
    setActiveCategory(id);
    const response = await sendRequest('GET', `${BASE_URL}/v1/inventory_managment/categories?category_id=${id}`)
    if (response.status === 200 && response.data.data.length > 0) {
      setGroupSubCategory(response.data.data);
      setOptions([]);
    } else {
      const res = await sendRequest('GET', `${BASE_URL}/v1/inventory_managment/items?category_id=${id}&per_page=50&is_billing=true`)
      if (res.status === 200) {
        const data = res.data;
        const fetchedOptions = data.data;
        setOptions(fetchedOptions);
        setGroupSubCategory([]);
      }
    }
  };

  const handlefetchItems = async (category_id) => {
    setActiveSubCategory(category_id);
    const response = await sendRequest('GET', `${BASE_URL}/v1/inventory_managment/items?category_id=${category_id}&per_page=50&is_billing=true`);
    const data = response.data;
    const fetchedOptions = data.data;
    setOptions(fetchedOptions);
  }

  const handlefetchfevouriteItems = async (is_favourite) => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/inventory_managment/items?is_favourite=${is_favourite}&per_page=50&is_billing=true`);
    const data = response.data;
    setOptions([]);
    setOptions(data.data);
    setActive(true);
  }

  const handleKotItemOptions = (fetchedOptions) => {
    setOptions([]);
    setSearchedOptions(fetchedOptions);
  }

  const handleTableChange = () => {
    navigate("/restaurant-order", { state: { floor_id: tableData?.floor_id } });
  };

  const handleParcelCharges = (value) => {
    setIsParceled(value);
  }

  const handleParcelChargeValue = (value) => {
    setParcelCharge(value);
  }

  const handleDiscountValue = (value) => {
    setInitialDiscountValue(value);
    setIsDiscountProvided(true);
    const type = discountType ? discountType : "fixed";
    calculateDiscount(value, type);
  }

  const handleDiscountType = (value) => {
    setDiscountType(value);
    if (initialDiscountValue) {
      calculateDiscount(initialDiscountValue, value);
    }
  }

  const calculateDiscount = (value, type) => {
    const perdiscount = (subTotal * (value / 100)).toFixed(2);
    const fixDiscount = value;

    if (isDiscountProvided) {
      if (type === 'percent') {
        setDiscountValue(perdiscount);
        setDiscountPercent(value);
      } else {
        setDiscountValue(fixDiscount);
        setDiscountPercent();
      }
    }
  }

  const closeItemDiscountPopup = () => {
    setDiscountPopup(false);
  }

  const handleDiscountReason = (value) => {
    setDiscountReason(prevReasons => {
      const flattenedArray = [...prevReasons, value].flat();
      const uniqueReasons = [...new Set(flattenedArray)];
      return uniqueReasons;
    });
  };

  const totalDiscountedValue = (
    (isDiscountProvided ? Number(discountValue) : 0) +
    (isCouponApplied ? Number(couponDiscountValue) : 0)
  ).toFixed(2);

  // const calculateServiceCharges = (subTotal, amount) => {
  //   const servicecharge = (subTotal * (amount / 100)).toFixed(2);
  //   setServiceChargeProvided(servicecharge);
  // }

  const handleGstTaxChange = (event) => {
    setIsGstEnabled(!event.target.checked);
  }

  const handlePrintInvoice = async (data) => {
    try {
      // Fetch the template configuration
      const configResponse = await sendRequest('GET', `${BASE_URL}/v1/template/templates/get_default_receipt`);

      const templateId = configResponse.data.data.id;
      console.log(templateId)
      const templateConfig = configResponse.data.data.template_options;

      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Bill</title>
          </head>
          <body>
            <div id="printable-bill"></div>
          </body>
        </html>
      `);
      printWindow.document.close();
      ReactDOM.render(
        <PrintableInvoice
          invoiceData={data}
          templateId={templateId}
          templateConfig={templateConfig}
        />,
        printWindow.document.getElementById("printable-bill")
      );

      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 500);
    } catch (error) {
      ErrorNotification('Failed to print bill:', error);
    }
  };

  // const handleServiceCharge = (event) => {
  //   setisServiceChargedEnabled(!event.target.checked);
  // }

  const calculateGstAmount = (subTotal, amount) => {
    const gst = (subTotal * (amount / 100)).toFixed(2);
    setCgst(gst);
    setSgst(gst);
  }
  const subTotalAmount = (subTotal - (((isDiscountProvided || isCouponApplied) && isCheckboxEnabled("discount")) ? Number(totalDiscountedValue) : 0)).toFixed(2);
  const invoiceTotalAmount = (Number(subTotalAmount) + (isParceled ? Number(ParcelCharge) : 0) + ((isGstEnabled && enableGST) ? (Number(sgst) + Number(cgst)) : 0)).toFixed(2);
  // const invoiceTotalAmount = (Number(subTotalAmount) + (isParceled ? Number(ParcelCharge) : 0) + ((isCheckboxEnabled("service_charge_enable") && isServiceChargedEnabled) ? Number(serviceChargeProvided) : 0) + ((isGstEnabled && enableGST) ? (Number(sgst) + Number(cgst)) : 0)).toFixed(2);

  const handlekotcreate = async (is_print = false) => {
    const uniqueItems = [];
    const itemIds = new Set();

    selectedItems.forEach(item => {
      if (!item.quantityUpdated) {
        item.quantity = 1;
      }
      if (!itemIds.has(item.id)) {
        itemIds.add(item.id);
        uniqueItems.push({
          item_id: item.id,
          quantity: item.quantity,
          item_total_amount: (item.sale_amount).toFixed(2),
          item_total_discount: item?.discount_amount ? item?.discount_amount : 0,
          item_modifier_ids: item.item_modifier ? item?.item_modifier?.map(modifier => modifier.id) : "",
          stock_diffrence_discount: item.diffrence_discount ? item.diffrence_discount.toFixed(2) : 0,
          stock_ids: item.stock_ids
        });
      }
    });

    try {
      const kot_params = {
        order: orderType === 'dine_in' || orderType === 'home_delivery' ? {
          floor_table_ids: [tableId],
          no_of_guests: selectedPax,
          user_id: selectedCaptain?.id || selectedCaptainId || orderDetails?.user_id,
          order_groups: uniqueItems,
          sub_total: subTotalAmount,
          customer_id: selectedGuest?.id || orderDetails?.customer_id,
          order_type: orderType,
          total_discount: TotalDiscount ? TotalDiscount : 0
        } : {
          user_id: selectedCaptain?.id || orderDetails?.user_id,
          order_groups: uniqueItems,
          sub_total: subTotalAmount,
          customer_id: selectedGuest?.id || orderDetails?.customer_id,
          total_discount: ((isDiscountProvided || isCouponApplied) && isCheckboxEnabled("discount")) ? Number(TotalDiscount + totalDiscountedValue) : TotalDiscount ? TotalDiscount : 0,
          discount_reason_ids: isCheckboxEnabled("discount") ? discountReason : "",
          total_amount: invoiceTotalAmount,
          total_gst: (isGstEnabled && enableGST) ? (Number(sgst) + Number(cgst)) : 0,
          parcel_charges: isParceled ? Number(ParcelCharge) : 0,
          coupon_id: isCouponApplied ? couponDiscount.id : "",
          // service_charges: isServiceChargedEnabled ? Number(serviceChargeProvided) : 0,
          status: statusLabels['Success'],
          order_type: orderType ? orderType : 'dine_in',
          nc_account_id: SelctedNoChargeId ? SelctedNoChargeId : ""
        },
        is_sent: (is_print === true) ? is_print : "",
        sent_via_whats_app: isCheckboxEnabled("send_to_all") ? isCheckboxEnabled("send_to_all") : isCheckboxEnabled("whats_app"),
        sent_via_sms: isCheckboxEnabled("send_to_all") ? isCheckboxEnabled("send_to_all") : isCheckboxEnabled("message"),
        sent_via_email: isCheckboxEnabled("send_to_all") ? isCheckboxEnabled("send_to_all") : isCheckboxEnabled("send_by_email")
      };
      let requestUrl = `${BASE_URL}/v1/restaurant/orders/orders`;
      let method = "POST";
      if (currentOrderId) {
        requestUrl = `${BASE_URL}/v1/restaurant/orders/orders/${currentOrderId}`;
        method = "PUT";
      }
      const response = await sendRequest(method, requestUrl, kot_params);
      if (response.status === 200) {
        successNotification("Order created Successfully")
        if (orderType === 'take_away') {
          handlePrintInvoice(response.data.data);
        }
        if (orderType === 'home_delivery') {
          navigate("/delivery-order")
        } else {
          handleTableChange();
        }
      }
    } catch (error) {
      ErrorNotification(error.data.errors);
    }
  }

  const handleSubTotalChange = (total) => {
    setSubTotal(total);
    calculateGstAmount(total, 2.50);
    // calculateServiceCharges(total, 2.00);
  }
  const handleDiscountAmountChanged = (total_discount) => {
    setTotalDiscount(total_discount);
  }

  const handleSelectCaptain = (selectedCaptain) => {
    setSelectedCaptain(selectedCaptain);
  };

  const calculateOrderGroupsTotals = () => {
    return orderDetails.order_groups?.map(group => {
      const total = group.order_items.reduce((sum, item) => sum + (item.item_total_amount * item.quantity) - item.stock_diffrence_discount, 0);
      return { id: group.id, total };
    }) || [];
  };

  const openNoChargePopup = () => {
    setNoChargePopup(true);
  }

  const closeNoChargePopup = () => {
    setNoChargePopup(false);
  }

  const handleSelctedNoCharge = (charge) => {
    setselctedNoChargeId(charge.id);
  }

  const handleCouponDiscount = (value) => {
    setCouponDiscount(value);
    setIsCouponApplied(true);
    calculateCouponDiscount(value);
  }

  const calculateCouponDiscount = (value) => {
    const perdiscount = (subTotal * (Number(value.value) / 100)).toFixed(2);
    const fixDiscount = Number(value.value);
    if (value.coupon_type === "percent") {
      setCouponDiscountValue(perdiscount);
    } else if (value.coupon_type === 'fixed'){
      setCouponDiscountValue(fixDiscount);
    }
  }

  const orderGroupsTotals = calculateOrderGroupsTotals();

  return (
    <>
      <div>
        <div className="restaurant-kot-group-container">
          <div className="restaurant-item-container">
            <div className="restourent-left-content">
              <div className="bill-number">
                {orderType === 'take_away' ? (
                  <>
                    <div className="kot-header">
                      <span className="table-name">
                        {t("Invoice No")}: ({formatBillNumber(billNumber + 1)})
                      </span>
                      <span className="pox-kot-name">
                        {t("Token")}: #
                      </span>
                      <span className="kot-guest-selection">
                        <span className="kot-select-guest-title">Guest: </span>
                        <span className="selected-guest-name-show">
                          {/* {displayText} */}
                          {selectedGuest?.email || selectedGuest?.phone || orderDetails?.customer?.email || orderDetails?.customer?.phone || ''}
                        </span>
                      </span>
                      <span className="date-time-kot">
                        {t("Date")}: {`${currDate} ${currTime}`}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="kot-header">
                      <span className="table-name">
                        {t("Table No")}: {tableData?.title || tableId}
                      </span>
                      <span className="kot-name">
                        {t("KOT")}: {(orderDetails?.order_groups?.length || 0) + 1}
                      </span>
                      <span className="pox-kot-name">
                        {t("PAX")}: {selectedPax}
                      </span>
                      <span className="date-time-kot">
                        {t("Date")}: {`${currDate} ${currTime}`}
                      </span>
                    </div>
                    <div className="kot-header">
                      {orderType === 'dine_in' && (

                        <span className="kot-caption">
                          <span className="kot-caption-group-title">{t("Captain")}:</span>
                          <span className="selected-captain-name-show">
                            {selectedCaptain?.first_name && selectedCaptain?.last_name
                              ? `${selectedCaptain.first_name} ${selectedCaptain.last_name}`
                              : orderDetails?.user?.first_name && orderDetails?.user?.last_name
                                ? `${orderDetails.user?.first_name} ${orderDetails.user?.last_name}`
                                : captionName ? captionName : ''
                            }
                          </span>
                        </span>
                      )}
                      {orderType === 'dine_in' && (
                        <span className="kot-guest-selection">
                          <span className="kot-select-guest-title">{t("Guest")}: </span>
                          <span className="selected-guest-name-show">
                            {selectedGuest?.email || selectedGuest?.phone || orderDetails?.customer?.email || orderDetails?.customer?.phone || ''}
                          </span>
                        </span>
                      )}
                    </div>
                  </>
                )}

                <ItemSearchForRestourent
                  options={[]}
                  label="item_name"
                  id="id"
                  handleChange={(option) => handleSelectOption(option)}
                />
                <KotItemTable
                  selectedItems={selectedItems}
                  updateItems={(updatedItems) => handleUpdateItems(updatedItems)}
                  subTotal={(total) => handleSubTotalChange(total)}
                  deletedItems={(deletedItems) => handleInactiveDeletedItems(deletedItems)}
                  updateTotalDiscount={(total_discount) => handleDiscountAmountChanged(total_discount)}
                />
                <div className="kot-create-buttons">
                  {orderType === 'take_away' ? (
                    <>
                      <button className="btn btn-primary kot-buttons" onClick={() => handlekotcreate()}>{t("Save")}</button>
                      <button className="btn btn-primary kot-buttons" onClick={() => handlekotcreate(true)}>{t("Save & Print")}</button>
                      <button className="btn btn-primary kot-buttons" onClick={openGuestPopup}>Guest</button>
                      <button className="btn btn-primary kot-buttons" onClick={() => handleTableChange()}>{t("Back")}</button>
                    </>
                  ) : (
                    <>
                      {currentOrderId ? (
                        <button className="btn btn-primary kot-buttons" onClick={() => handlekotcreate()}>{t("Save")}</button>
                      ) : (
                        <button className="btn btn-primary kot-buttons" onClick={() => handlekotcreate()}>{t("KOT Print")}</button>
                      )}
                      <button className="btn btn-primary kot-buttons" onClick={openSelectCaptainPopup}>{t("Select Captain")}</button>
                      <button className="btn btn-primary kot-buttons" onClick={openGuestPopup}>Guest</button>
                      <button className="btn btn-primary kot-buttons" onClick={() => handleTableChange()}>{t("Table List")}</button>
                    </>
                  )}
                </div>
                {/* <div className="take-away-order-details"> */}
                {['dine_in', 'home_delivery'].includes(orderType) ? (
                  <div>
                    {currentOrderId && (
                      <div className="dine-in-order-details">
                        <>
                          <div className="kot-card-detail-header">
                            <span className="kot-label">{t("Kot No.")}</span>
                            <span className="kot-label-second">{t("Total")}</span>
                          </div>
                          <div className="kot-create-value-response">
                            {orderGroupsTotals.map((group, index) => (
                              <div key={index} className="response-value-kot">
                                <span className="total-kot-create-title">{t("KOT")} {index + 1}</span>
                                <span className="total-kot-create-total-value">{group.total.toFixed(2)}</span>
                              </div>
                            ))}
                          </div>
                          <div className="response-kot-total-value">
                            <span className="total-kot-create-title">Total</span>
                            <span className="total-kot-create-title-value">{orderGroupsTotals.reduce((sum, group) => sum + group.total, 0).toFixed(2)}</span>
                          </div>
                        </>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="take-away-order-details">
                    <>
                      <div className="take-away-order-sub-details">
                        {/* {['take_away'].includes(orderType) && isCheckboxEnabled("service_charge_enable") && isServiceChargedEnabled && (
                          <div className="kot-card-detail-header">
                            <span className="kot-label">{t("Serivce Charge on SUB-TOTAL @(2%)")}</span>
                            <span className="kot-label-second">{serviceChargeProvided}</span>
                          </div>
                        )} */}
                        {(isDiscountProvided || TotalDiscount) && (
                          <div className="kot-card-detail-header">
                            {discountType === 'percent' ? (
                              <span className="kot-label">{t("Discount on SUB-TOTAL")} @({discountPercent}%)</span>) : (
                              <span className="kot-label">{t("Discount")}</span>
                            )}
                            <span className="kot-label-second">{Number(discountValue + TotalDiscount) || '0.00'}</span>
                          </div>
                        )}
                        {isCouponApplied && (
                          <div className="kot-card-detail-header">
                            <span className="kot-label">{t("Coupon Discount")}</span>
                            <span className="kot-label-second">{couponDiscountValue || "0.00"}</span>
                          </div>
                        )}
                        <div className="kot-card-detail-header">
                          <span className="kot-label">{t("Sub Total")}</span>
                          <span className="kot-label-second">{subTotalAmount || '0.00'}</span>
                        </div>
                        {isParceled && (
                          <div className="kot-card-detail-header">
                            <span className="kot-label">{t("Parcel Charge")}</span>
                            <span className="kot-label-second">{ParcelCharge || '0.00'}</span>
                          </div>
                        )}
                        {(isGstEnabled && enableGST) && (
                          <>
                            <div className="kot-card-detail-header">
                              <span className="kot-label">{t("SGST on SUB-TOTAL @(2.50 %)")}</span>
                              <span className="kot-label-second">{sgst || '0.00'}</span>
                            </div>
                            <div className="kot-card-detail-header">
                              <span className="kot-label">{t("CGST on SUB-TOTAL @(2.50 %)")}</span>
                              <span className="kot-label-second">{cgst || '0.00'}</span>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="response-kot-total-value">
                        <span className="kot-label">{t("Grand Total")}</span>
                        <span className="kot-label-second">{invoiceTotalAmount || '0.00'}</span>
                      </div>
                    </>
                  </div>
                )}
                {/* </div> */}
              </div>
            </div>
            <div className="restourent-right-content">
              <div className="kot-inner-box-1">
                <div className="inner-box-1-list">
                  <div className="group-category-list">
                    <div className={`card-content-kot ${active ? 'active' : ''}`} onClick={() => handlefetchfevouriteItems(true)}>
                      <span className="cat-font-size" >
                        <FontAwesomeIcon icon={faHeart} className="kot-icon-space" />
                      </span>
                      <div>
                        <span className="category-favourate-item-title">{t("Favourite Items")}</span>
                      </div>
                    </div>
                    {/* {groupCategories.map((category) => (
                    <>
                      <div key={category.id} onClick={() => handleGroupSubCategories(category.id)}>
                        <GroupCategory category={category.name} />
                      </div>
                    </>
                  ))} */}
                    {groupCategories.map((category) => (
                      <div key={category.id} onClick={() => handleGroupSubCategories(category.id)}>
                        <GroupCategory category={category} isActive={activeCategory} />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="inner-box-1-list-second">
                  <div className="kot-item-category">
                    <span className="categories-heading" onClick={() => handleGroupCategories("Food")}>
                      <FontAwesomeIcon icon={faBars} /> {t("Food")}
                    </span>
                    <span className="categories-heading" onClick={() => handleGroupCategories("Drinks")}>
                      <FontAwesomeIcon icon={faBars} /> {t("Drinks")}
                    </span>
                    <span className="categories-heading" onClick={() => handleGroupCategories("Sweets")}>
                      <FontAwesomeIcon icon={faBars} /> {t("Sweets")}
                    </span>
                  </div>
                  <div className="search-kot-items">
                    {
                      <SearchItemForKot handlekotitemoptions={handleKotItemOptions} />
                    }
                  </div>
                  <div className="kot-searched-item-list">
                    <div className="seleted-group-sub-categories">
                      {GroupSubCategory.length > 0 && (
                        <>
                          {GroupSubCategory.map((subCategory) => (
                            <div key={subCategory.id} className="sub-category" onClick={() => handlefetchItems(subCategory.id)}>
                              <GroupSubCategories subcategory={subCategory} isActive={activeSubCategory} />
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                    <div className="seleted-group-sub-categories-items">
                      {options.length > 0 && (
                        <>
                          {options.map((item) => (
                            <div key={item.id} className="sub-category" onClick={() => handleSelectOption(item)}>
                              <GroupSubCategoryItem item={item} isActive={activeItems} />
                            </div>
                          ))}
                        </>
                      )}
                      {searchedOptions.length > 0 && (
                        <>
                          {searchedOptions.map((item) => (
                            <div key={item.id} className="sub-category" onClick={() => handleSelectOption(item)}>
                              <GroupSubCategoryItem item={item} isActive={activeItems} />
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {orderType === 'take_away' && (
            <>
              <div className='kot-footer'>
                <div className='kot-footer-left'>
                  <button className="kot-btn-parcel" onClick={openParcelPopup}>{t("Parcel")}</button>
                  <button className="kot-invoice-nc" onClick={openNoChargePopup}>{t("NC")}</button>
                </div>
                <div className='kot-footer-center'>
                  {/* {isCheckboxEnabled("service_charge_enable") && (
                    <div className='kot-footer-center-checkbox-1'>
                      <input className="kot-footer-input-checkbox" type='checkbox' checked={!isServiceChargedEnabled} onChange={handleServiceCharge} />
                      <span>{t("No Ser. Charge?")}</span>
                    </div>
                  )} */}
                  {enableGST && (
                    <div className='kot-footer-center-checkbox-2'>
                      <input className="kot-kot-footer-left-footer-input-checkbox" type='checkbox' checked={!isGstEnabled} onChange={handleGstTaxChange} />
                      <span>{t("Tax Exemption?")}</span>
                    </div>
                  )}
                </div>
                {isCheckboxEnabled("discount") && (
                  <div className='kot-footer-right'>
                    <button className="kot-dis-button" onClick={discountPopupOpen}>Discount</button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {isOpenGuestPopup && (
          <div className="guest-kot-table-popup">
            <SelectGuest onClose={closeGuestPopup} />
          </div>
        )}

        {isOpenSelectCaptainPopup && (
          <div className="select-captain-kot-table-popup">
            <SelectCaptain onClose={closeSelectCaptainPopup} onSelectCaptain={handleSelectCaptain} />
          </div>
        )}

        {isDiscountPopup && (
          <div className="invoice-parcel-popup-show">
            <ItemDiscountInvoice initialDiscountValue={initialDiscountValue} discountValue={handleDiscountValue} initialDiscountType={discountType} discountType={handleDiscountType} initialSelectedDiscountReason={discountReason} discountReason={handleDiscountReason} couponResponse={handleCouponDiscount} onClose={closeItemDiscountPopup} subTotalValue={subTotal} />
          </div>
        )}

        {isParcelPopup && (
          <div className="invoice-parcel-popup-show">
            <ParcelCharges
              ParcelChargeValue={ParcelCharge}
              setParcelValue={handleParcelChargeValue}
              isPracelChargeable={handleParcelCharges}
              onClose={closeParcelPopup}
            />
          </div>
        )}

        {isNoChargePopup && (
          <div className="no-charge-invoice-popup-show">
            <NoCharge selectedNoCharge={handleSelctedNoCharge} onClose={closeNoChargePopup} />
          </div>
        )}
      </div>
      <Footer />
    </>
  )
}

export default RestourentBillings;
