import React, { useEffect, useState } from "react";
import { getToken } from './SecureStorage/Token';
import { BASE_URL } from "../Url/constants";
import ErrorNotification from "../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import './CreateCustomer.css';
import { validateEmail, validatePhone } from "../Url/validationUtils";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import { sendRequest } from "../ApiRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Copyrights from "./Copyrights";
import { useTranslation } from "react-i18next";

const UpdateStaffForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const staff = location.state;
  const { t } = useTranslation();

  const [updateStaffData, setupdateStaffData] = useState({
    "id": staff?.id,
    "name": staff?.name,
    "first_name": staff?.first_name,
    "last_name": staff?.last_name,
    "email": staff?.email,
    "phone": staff?.phone,
    "temporary_address": staff?.temporary_address,
    "permanent_address": staff?.permanent_address,
    "gender": staff?.gender,
    "id_proof": staff?.id_proof_url,
    "shift_id": staff?.shift_id,
    "monthly_salary": staff?.monthly_salary,
    "role": staff?.role_details?.id
  });

  const [roleData, setRoleData] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      const indianPhoneRegex = /^$|^[6-9]\d{0,9}$/;

      if (indianPhoneRegex.test(value)) {
        setupdateStaffData({ ...updateStaffData, [name]: value });
      }
    } else {
    setupdateStaffData({ ...updateStaffData, [e.target.name]: e.target.value });
    }
  }

  const handImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setupdateStaffData({ ...updateStaffData, id_proof: selectedFile });
    }
  }

  useEffect(() => {
    getRoleData();
  }, [])

  const token1 = getToken();

  const getRoleData = async () => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/manage_role/roles`)
    if (response.status === 200) {
      setRoleData(response.data.data);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = [];

    if (updateStaffData.email) {
      const emailValidationResult = validateEmail(updateStaffData.email);
      if (emailValidationResult) {
        errors.push(emailValidationResult);
      }
    }

    if (updateStaffData.phone) {
      const phoneValidationResult = validatePhone(updateStaffData.phone);
      if (phoneValidationResult) {
        errors.push(phoneValidationResult);
      }
    }

    if (errors.length > 0) {
      errors.forEach(errorMessage => {
        ErrorNotification(errorMessage);
      });
      return;
    }

    const form = e.target;
    const formData = new FormData(form);

    formData.append("user[first_name]", updateStaffData.first_name);
    formData.append("user[last_name]", updateStaffData.last_name);
    formData.append("user[email]", updateStaffData.email);
    formData.append("user[phone]", updateStaffData.phone);
    formData.append("user[temporary_address]", updateStaffData.temporary_address);
    formData.append("user[permanent_address]", updateStaffData.permanent_address);
    formData.append("user[gender]", updateStaffData.gender);
    formData.append("user[monthly_salary]", parseFloat(updateStaffData.monthly_salary));
    formData.append("user_role[role_id]", parseInt(updateStaffData.role))

    if (updateStaffData.id_proof instanceof File) {
      formData.append("user[id_proof]", updateStaffData.id_proof);
    }

    await sendRequest('PUT', `${BASE_URL}/v1/staff_management/staffs/${updateStaffData.id}`, formData, token1)
      .then(response => {
        if (response.status === 200) {
          navigate('/staff')
        }
      })
      .catch(error => {
        ErrorNotification(error.data.errors[0]);
      });
  }

  const handleCancel = () => {
    navigate('/staff');
  }

  return (
    <>
      <Header />
      <div className="content">
        <div className="col-md-12 margin-btm"><h3 className="title">{t("Edit Staff")}</h3></div>
        <div className="create-company">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <input type="text" className="form-control" placeholder={t("First Name")} name="first_name" value={updateStaffData.first_name} onChange={handleInputChange} />
              </div>
              <div className="col-md-6">
                <input type="text" className="form-control" placeholder={t("Last Name")} name="last_name" value={updateStaffData.last_name} onChange={handleInputChange} />
              </div>
              <div className="col-md-6">
                <input type="text" className="form-control" placeholder={t("Email*")} name="email" value={updateStaffData.email} onChange={handleInputChange} />
              </div>
              <div className="col-md-6">
                <input type="text" className="form-control" placeholder={t("Phone*")} name="phone" value={updateStaffData.phone} onChange={handleInputChange} />
              </div>
              <div
                className="col-md-6 position-relative"
                style={{ color: "#bbbaba" }}
              >
                <select
                  style={{ borderWidth: "1px", borderColor: "#bbbaba" }}
                  name="gender"
                  className="form-control select-input"
                  value={updateStaffData.gender}
                  onChange={handleInputChange}
                >
                  <option value="">{t("Select Gender")}*</option>
                  <option value="male" selected={updateStaffData.gender === 'male'}>Male</option>
                  <option value="female" selected={updateStaffData.gender === 'female'}>Female</option>
                  <option value="others" selected={updateStaffData.gender === 'others'}>Others</option>
                </select>
                <span className="staff-dropdown-icon">
                  <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </div>

              <div
                className="col-md-6 position-relative"
                style={{ color: "#bbbaba" }}
              >
                <select
                  style={{ borderWidth: "1px", borderColor: "#bbbaba" }}
                  name="role"
                  className="form-control select-input"
                  value={updateStaffData.role}
                  onChange={handleInputChange}
                >
                  <option value="">{t("Select Role")}*</option>
                  {roleData.map(role => (
                    <option
                      key={role.id}
                      value={role.id}
                      selected={updateStaffData.role === role.id}
                    >
                      {role.name}
                    </option>
                  ))}
                </select>
                <span className="staff-dropdown-icon">
                  <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Temporary Address")}
                  name="temporary_address"
                  value={updateStaffData.temporary_address}
                  onChange={handleInputChange}
                  maxLength="64"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Permanent Address")}
                  name="permanent_address"
                  value={updateStaffData.permanent_address}
                  onChange={handleInputChange}
                  maxLength="64"
                />
              </div>

              <div className="col-md-6">
                <input type="file" className="form-control" name="id_proof" onChange={handImageChange} />
                <input type="hidden" name="existing_id_proof" value={updateStaffData.id_proof} />
                <label className="note">{t("Upload Id-Proof")}</label>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Enter Monthly Salary")}
                  name="monthly_salary"
                  value={updateStaffData.monthly_salary}
                  onChange={handleInputChange}
                  maxLength="10"
                  pattern="\d{1,10}"
                  inputMode="numeric"
                />
              </div>

              <div className="col-md-12">
                <button className="btn btn-primary save-btn-1" type="submit">{t("Save")}</button>
                <button className="btn btn-primary" type="button" onClick={handleCancel}>{t("Cancel")}</button>
              </div>
            </div>
          </form>
          <NotificationContainer />
        </div>
      </div>
      <Copyrights />
    </>
  )
}

export default UpdateStaffForm;
