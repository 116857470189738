import React, { useState, useEffect } from "react";
import "./ItemSettings.css";
import { BASE_URL } from "../../Url/constants";
import SuccessNotification from "../../Notification/SuccessNotification";
import { NotificationContainer } from "react-notifications";
import debounce from "lodash.debounce";
import ToggleButton from "../../ToggleButton";
import { useTranslation } from "react-i18next";
import { sendRequest } from "../../ApiRequest";


export const ItemSettings = () => {
  const [options, setOptions] = useState([]);
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    fetchInitialConfig();
  }, []);

  const fetchInitialConfig = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/setting/settings/item_setting_options?type=global`
      );
      const allOptions = response.data.data;

      const filteredOptions = allOptions.filter(option => option.name !== "item_unit");
      
      const uniqueOptions = filteredOptions.map((option) => ({
        ...option,
        status: option.item_setting_config?.status || false,
      }));

      const initialConfig = uniqueOptions.reduce((acc, option) => {
        acc[option.name] = option.status;
        return acc;
      }, {});

      setOptions(uniqueOptions);
      setConfig(initialConfig);
      setLoading(false);
    } catch (error) {
      console.error(
        "Error fetching initial config:",
        error.response || error.message
      );
    }
  };

  const debouncedApiCall = debounce(async (id, checked) => {
    try {
      const response = await sendRequest(
        "PUT",
        `${BASE_URL}/v1/setting/settings/item_config_create`,
        {
          item_config: { option_name: id, status: checked },
        }
      );
      SuccessNotification(`${id.replace(/_/g, " ")} setting updated`);
    } catch (error) {
      console.error("Error in API call:", error.response || error.message);
    }
  }, 500);

  const handleChange = (e) => {
    const { id, checked } = e.target;
    setConfig((prevConfig) => ({ ...prevConfig, [id]: checked }));
    debouncedApiCall(id, checked);
  };

  useEffect(() => {
    // Clean up the debounced function on unmount
    return () => {
      debouncedApiCall.cancel();
    };
  }, []);

  if (loading) {
    return <div>{t("Loading...")}</div>;
  }

  return (
    <>
      <h3 className="heading_content">{t("Item Settings")}</h3>
      <div className="item-settings-container">
        <NotificationContainer />
        <div className="left-panel">
          <div>
            {options.map((option) => (
              <div className="option-item" key={option.id}>
                <ToggleButton
                  id={option.name}
                  checked={config[option.name]}
                  onChange={handleChange}
                  disabled={!option.status}
                />
                <label htmlFor={option.name}>
                  {option.name === "party_wise_item_rate"
                    ? "Add Purchase Price"
                    : option.name.replace(/_/g, " ").split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
                  }

                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
