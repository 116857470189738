import React from "react";
import './receiptfirst.css'

function ReceiptFirst({ enabledTemplateData }) {
  const getOption = (optionName) => {
    return enabledTemplateData.find((opt) => opt.option_name === optionName) || {};
  };

  const getAlignment = (optionName) => {
    const option = getOption(optionName);
    return option.alignment || 'left'; // Default to 'left' if alignment is not defined
  };

  return (
    <>
      <div className="receipt-first-main">
        <div className="receipt-sub">
          <div className="receipt-header">
            <div className="first-receipt-1-logo-header">
              {getOption('company_logo').is_enable && (
                <img className="first-receipt-1-logo" src="/companylogo.jpeg" alt="logo" />
              )}
            </div>

            <div className="row">
              {getOption('company_name').is_enable && (
                <span
                  className="company-title"
                  style={{ textAlign: getAlignment('company_name') }}
                >
                  {getOption('company_name').free_text || "JUSTMINDWORK Private Limited"}
                </span>
              )}
            </div>
            <div className="row">
              {getOption('address').is_enable && (
                <div
                  className="receipt-company-address"
                  style={{ textAlign: getAlignment('address') }}
                >
                  {getOption('address').free_text || "111J, Satguru Parinay, Indore"}
                </div>
              )}
              {getOption('email').is_enable && (
                <div
                  className="receipt-com-mail"
                  style={{ textAlign: getAlignment('email') }}
                >
                  Email: {getOption('email').free_text || "justmindwork@gmail.com"}
                </div>
              )}
              {getOption('phone_number').is_enable && (
                <div
                  className="receipt-com-mail"
                  style={{ textAlign: getAlignment('phone_number') }}
                >
                  Phone: {getOption('phone_number').free_text || `+98765432349`}
                </div>
              )}
              {enabledTemplateData.find(option => option.option_name === 'insta_id')?.is_enable && (
                <div className="receipt-com-mail">
                  Insta Id: {enabledTemplateData.find(option => option.option_name === 'insta_id').free_text || `@nike`}
                </div>
              )}
              {enabledTemplateData.find(option => option.option_name === 'gst_number')?.is_enable && (
                <div className="receipt-com-mail">Gst Number: SGST12345678987</div>
              )}
            </div>
          </div>
          <div className="receipt-sub-header">
            .......................................CASH MEMO.................................
          </div>
          <div className="receipt-date-time">
            <div className="receipt-date">
              {getOption('date').is_enable && (
                `Date: 02/05/2024`
              )}
            </div>
            <div className="receipt-time">
              {getOption('time').is_enable && (
                `Time: 01:00`
              )}
            </div>
          </div>
          <div className="receipt-date-time">
            <div className="receipt-date">
              Branch: Indore
            </div>
            <div className="receipt-time">
              {getOption('invoice_number').is_enable && (
                `Receipt: #L21387`
              )}
            </div>
          </div>
          <div className="receipt-first-table-div">
            <table className="receipt-first-table">
              <thead className="table-header-dotted">
                <tr>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Qty</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>colgate paste</td>
                  <td>1</td>
                  <td>40</td>
                  <td>40</td>
                </tr>
                <tr>
                  <td>colgate paste</td>
                  <td>1</td>
                  <td>40</td>
                  <td>40</td>
                </tr>
                <tr>
                  <td>colgate paste</td>
                  <td>1</td>
                  <td>40</td>
                  <td>40</td>
                </tr>
                <tr>
                  <td>colgate paste</td>
                  <td>1</td>
                  <td>40</td>
                  <td>40</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr className="hr-dotted" />
          <div>
            <div className="row">
              <div className="col"></div>
              <div className="col-auto">
                <div className="second-receipt-expenses">
                  Sub-Total: 10000
                </div>
                <div className="second-receipt-expenses">
                  Discount: 1000
                </div>
              </div>
            </div>
          </div>
          <hr className="hr-dotted" />
          <div>
            <div className="row">
              <div className="col"></div>
              <div className="col-auto">
                <div className="second-receipt-expenses">
                  Service charge: 0rs
                </div>
                <div className="second-receipt-expenses">
                  Tax(%): 10
                </div>
              </div>
            </div>
          </div>
          <hr className="hr-dotted" />
          <div className="row">
            <div className="col"></div>
            <div className="col-auto">
              <div className="second-receipt-right">
                <div className="second-receipt-expenses">
                  Total Bill: 10000rs
                </div>
                {getOption('display_balance_amount').is_enable && (
                  <div className="second-receipt-expenses">
                    Due: 0rs
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr className="hr-dotted" />
          <div className="row">
            <div className="col"></div>
            <div className="col-auto">
              {getOption('display_received_amount').is_enable && (
                <div className="second-receipt-right mb-2">
                  Cash Paid: 9000
                </div>
              )}
            </div>
          </div>
          {getOption('qr_code').is_enable && (
            <div className="qr-code-container">
              <img src="/qr-code-placeholder.png" alt="QR Code" className="qr-code-image" />
            </div>
          )}
          <div className="receipt-container">
            <div>
              {getOption('return_policy').is_enable && (
                <span
                  className="first-receipt-policy"
                  style={{ textAlign: getAlignment('return_policy') }}
                >
                  Return Policy: {getOption('return_policy').free_text || "Non returnable items"}
                </span>
              )}
              <div>
                <span className="first-receipt-policy-content">
                  This is an electronic generated receipt so doesn't require any signature
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReceiptFirst;