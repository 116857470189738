import React, { useState, useEffect } from "react";
import { FaCog } from "react-icons/fa";
import Modal from "react-modal";
import { IoMdClose } from 'react-icons/io';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PrintableInvoice from "../../components/PrintableInvoice";
import {
  BASE_URL,
  SUCCESS_MESSAGE,
  UPDATE_ERROR_MSG,
} from "../../Url/constants";
import ErrorNotification from "../../Notification/ErrorNotification";
import SuccessNotification from "../../Notification/SuccessNotification";
import { NotificationContainer } from "react-notifications";
import { sendRequest } from "../../ApiRequest";
import Copyrights from "../../components/Copyrights";
import { useTranslation } from "react-i18next";
import "./InvoiceSetting.css";
import Header from "../../components/Header";

function InvoiceSettings() {
  const [selectedItem, setSelectedItem] = useState(0);
  // const [editVisible, setEditVisible] = useState([]);
  const [templateConfigData, setTemplateConfigData] = useState([]);
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentEditingOption, setCurrentEditingOption] = useState(null);
  const [company, setCompany] = useState([]);  

  useEffect(() => {
    getDefaultReceipt();
    getCompany();
  }, []);

  const getCompany = async () => {
    const response = await sendRequest(
      'GET',
      `${BASE_URL}/v1/user_details/get_user_details`
    );
    if (response.status === 200) {
      setCompany(response.data.company);
    }
  };

  const getDefaultReceipt = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/template/templates/get_default_receipt`
      );
      if (response.status === 200) {
        const defaultTemplateId = response?.data?.data?.id;
        setSelectedItem(defaultTemplateId - 5); // Adjust for receipt templates starting at 5
        handleTemplateChange(defaultTemplateId);
      }
    } catch (error) {
      ErrorNotification("Failed to get default template");
      console.error("Error getting default template:", error);
    }
  };

  const updateDefaultReceipt = async (templateId) => {
    try {
      const params = { receipt_id: templateId };
      const response = await sendRequest(
        "PUT",
        `${BASE_URL}/v1/template/templates/set_default_template`,
        params
      );
      if (response.status === 200) {
      }
    } catch (error) {
      ErrorNotification("Failed to update default template");
      console.error("Error updating default template:", error);
    }
  };

  const handleTemplateChange = async (templateId) => {
    try {
      const url = `${BASE_URL}/v1/template/templates/${templateId}`;
      const response = await sendRequest("GET", url);
      if (response.status === 200) {
        const templateOptions = response.data.data.template_options;

        const configPromises = templateOptions.map(async (option) => {
          const configUrl = `${BASE_URL}/v1/template/templates/get_template_configs?template_id=${templateId}&template_option_id=${option.id}`;
          const configResponse = await sendRequest("GET", configUrl);
          if (
            configResponse.status === 200 &&
            configResponse.data.data.length > 0
          ) {
            return {
              ...option,
              template_config: configResponse.data.data[0],
              alignment: configResponse.data.data[0].alignment || 'center',
            };
          }
          return option;
        });

        const updatedTemplateOptions = await Promise.all(configPromises);
        setTemplateConfigData(updatedTemplateOptions);
      }
    } catch (error) {
      ErrorNotification("Failed to load template configuration");
      console.error("Error loading template configuration:", error);
    }
  };

  const handleItemClick = async (index) => {
    if (index !== selectedItem) {
      setSelectedItem(index);
      await handleTemplateChange(index + 5); // Adjust for receipt templates starting at 5
      await updateDefaultReceipt(index + 5);
    }
  };

  // const toggleEditVisibility = (index) => {
  //   setEditVisible((prevState) => {
  //     const newState = [...prevState];
  //     newState[index] = !newState[index];
  //     return newState;
  //   });
  // };

  const handleCheckboxChange = (index) => {
    setTemplateConfigData((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        template_config: {
          ...newState[index].template_config,
          is_enable: !newState[index].template_config?.is_enable,
        },
      };
      return newState;
    });
  };

  const handleTextChange = (text) => {
    setTemplateConfigData((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex((opt) => opt.id === currentEditingOption.id);
      if (index >= 0 && newState[index].template_config) {
        newState[index] = {
          ...newState[index],
          template_config: {
            ...newState[index].template_config,
            free_text: text,
          },
        };
      }
      return newState;
    });
    setCurrentEditingOption((prevOption) => ({
      ...prevOption,
      template_config: {
        ...prevOption.template_config,
        free_text: text,
      },
    }));
  };


  const handleSave = async () => {
    const data = {
      template_config_data: templateConfigData.map((option) => ({
        template_option_id: option.id,
        free_text: option?.template_config?.free_text,
        is_enable: option?.template_config?.is_enable,
        alignment: option?.template_config?.alignment,
      })),
    };

    try {
      const response = await sendRequest(
        "PUT",
        `${BASE_URL}/v1/template/templates/${selectedItem + 5}`,
        data
      );
      if (response.status === 200) {
        SuccessNotification(SUCCESS_MESSAGE("updated", "template"));
      } else {
        ErrorNotification(UPDATE_ERROR_MSG("template"));
      }
    } catch (error) {
      ErrorNotification(UPDATE_ERROR_MSG("template"));
    }
  };

  const dummyInvoiceData = {
    logo_url: company.logo_url,
    company: {
      name: "Sample Company",
      address: "123 Sample St",
      phone: "123-456-7890",

    },
    bill_items: [
      { name: "Item 1", quantity: 2, item_total_amount: 20 },
      { name: "Item 2", quantity: 1, item_total_amount: 15 },
    ],
    sub_total: 35,
    total_tax: 3.5,
    total_amount: 38.5,
    bill_number: "INV-001",
  };

  const openModal = (option) => {
    setCurrentEditingOption(option);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentEditingOption(null);
  };

  const handleAlignmentChange = (alignment) => {
    setTemplateConfigData((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex((opt) => opt.id === currentEditingOption.id);
      newState[index] = {
        ...newState[index],
        template_config: {
          ...newState[index].template_config,
          alignment: alignment,
        },
      };
      return newState;
    });
  };
  

  const handleSavePopup = () => {
    // Here you can add any additional logic needed when saving the popup
    closeModal();
  };

  return (
    <>
      <Header />
      <div className="template-main-content">
        <div className="template-setting-box-container">
          <div className="inner-box-1">
            {[5, 6, 7, 8].map((item, index) => (
              <div
                key={index}
                onClick={() => handleItemClick(index)}
                className={`temp-img-align mt-3 ${
                  selectedItem === index ? "selected" : ""
                }`}
              >
                <img
                  src={`/template${item}.png`}
                  alt={`demoImage${item}`}
                  className="box-temp-setting-image"
                />
                {selectedItem === index && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="check-icon"
                  />
                )}
              </div>
            ))}
          </div>

          {selectedItem !== null && (
            <div className="template-main-sub-content">
              <div className="inner-box-2">
                <div className="template-setting-title">
                  {t("Template Setting")}
                </div>
                <div className="setting-template-figure mt-3">
                  <div className="temp-setting-options-container">
                    {templateConfigData.map((option, index) => (
                      <div key={option.id} className="temp-option-item">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={option.template_config?.is_enable}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <label className="label-space">
                          {option.option_name.replace(/_/g, " ")}
                        </label>
                        <FaCog
                          className="settings-icon"
                          onClick={() => openModal(option)}
                        />
                        {![
                          "qr_code",
                          "company_logo",
                          "invoice_number",
                          "barcode",
                          "company_signature",
                          "discount",
                          "gst_number",
                          "total_quantity",
                          "display_amount_with_decimal",
                          "display_received_amount",
                          "display_balance_amount",
                          "date",
                          "time",
                          "insta_id",
                        ].includes(option.option_name) &&
                          option.template_config?.is_enable && (
                            <>
                              {/* <BsPencilSquare
                                className="edit-icon"
                                onClick={() => toggleEditVisibility(index)}
                              /> */}
                              {/* {editVisible[index] && (
                                <input
                                  type="text"
                                  className="form-control temp-setting-edti-fied"
                                  placeholder={`Add ${option.option_name.replace(
                                    /_/g,
                                    " "
                                  )}`}
                                  value={option.template_config.free_text || ""}
                                  onChange={(e) =>
                                    handleTextChange(index, e.target.value)
                                  }
                                />
                              )} */}
                            </>
                          )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-12 mt-10 text-right">
                  <button
                    className="btn btn-primary save-btn-i"
                    onClick={handleSave}
                  >
                    {t("Save")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Option Settings"
      className="custom-modal"
      overlayClassName="custom-overlay"
    >
      <div className="modal-header">
        <h2>{currentEditingOption?.option_name.replace(/_/g, " ")} Settings</h2>
        <IoMdClose className="close-icon" onClick={closeModal} />
      </div>
      <div className="modal-content">
        <div className="form-group">
          <label>Alignment:</label>
          <select
            value={currentEditingOption?.template_config?.alignment || 'center'}
            onChange={(e) => handleAlignmentChange(e.target.value)}
          >
            <option value="left">Left</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
          </select>
        </div>
        <div className="form-group">
          <label>Custom Text:</label>
          <input
            type="text"
            value={currentEditingOption?.template_config?.free_text || ''}
            onChange={(e) => handleTextChange(e.target.value)} // Use the revised handleTextChange
          />
        </div>
      </div>
      <div className="modal-footer">
        <button onClick={handleSavePopup}>Save</button>
        <button onClick={closeModal}>Cancel</button>
      </div>
    </Modal>
        {selectedItem !== null && (
          <div className="right-content">
            <div className="right-content-inner">
              <div
                className="invoice-preview-container"
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  margin: "0 auto",
                  padding: "20px",
                  backgroundColor: "white",
                  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                }}
              >
                <PrintableInvoice
                  invoiceData={dummyInvoiceData}
                  templateId={selectedItem + 5}
                  templateConfig={templateConfigData}
                />
              </div>
            </div>
          </div>
        )}
        <NotificationContainer />
      </div>
      <Copyrights />
    </>
  );
}

export default InvoiceSettings;
