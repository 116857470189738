import React from "react";
import Header from "../components/Header";
import Accordion from "../components/Accordion";
import Copyrights from "../components/Copyrights";

function CreateCompany() {
    return (
        <>
            <Header />
             <div className="content">
                <Accordion/>
            </div>
            <Copyrights />
        </>
    );
}

export default CreateCompany;