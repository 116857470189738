import React, { useState } from "react";
import { getToken } from './SecureStorage/Token';
import { BASE_URL } from "../Url/constants";
import ErrorNotification from "../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import './CreateCustomer.css';
import { validateEmail, validatePhone } from "../Url/validationUtils";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { sendRequest } from "../ApiRequest";

const CustomerCollapse = () => {

  const [customerData, setCustomerData] = useState({
    name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: ""
  });


  const navigate = useNavigate();

  const handleCustomerChange = (e) => {
    setCustomerData({ ...customerData, [e.target.name]: e.target.value });
  };
  const token1 = getToken();
  const token = { headers: { Authorization: `${token1}` }};
  console.log(token);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = [];

    if (customerData.email) {
      const emailValidationResult = validateEmail(customerData.email);
      if (emailValidationResult) {
        errors.push(emailValidationResult);
      }
    }

    if (customerData.phone) {
      const phoneValidationResult = validatePhone(customerData.phone);
      if (phoneValidationResult) {
        errors.push(phoneValidationResult);
      }
    }

    if (errors.length > 0) {
      errors.forEach(errorMessage => {
        ErrorNotification(errorMessage);
      });
      return;
    }

    const customer_params = {
      customer: {
        name: customerData.name,
        first_name: customerData.first_name,
        last_name: customerData.last_name,
        email: customerData.email,
        phone: customerData.phone
      }
    }
    await sendRequest('POST', `${BASE_URL}/v1/onboarding/customers`, customer_params, token1)
    .then(response => {
      if (response.status === 200){
        navigate("/create-customer");
      }
    })
    .catch(error => {
      ErrorNotification(error.data.errors[0]);
    });
  };

  const handleCancel = () => {
    navigate('/create-customer');
  };

  return (
    <>
    < Header />
      <div className="content">
        <div className="col-md-12 margin-btm"><h3 className="title">Add Customer</h3></div>
        {/* <Collapsible trigger={"Create Customer"} open={isOpen} onOpening={() => setIsOpen(true)}> */}
        <div className="create-company">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <input type="text" className="form-control" placeholder="First Name" name="first_name" value={customerData.first_name} onChange={handleCustomerChange} />
              </div>
              <div className="col-md-6">
                <input type="text" className="form-control" placeholder="Last Name" name="last_name" value={customerData.last_name} onChange={handleCustomerChange} />
              </div>

              <div className="col-md-6">
                <input type="text" className="form-control" placeholder="Email Id" name="email" value={customerData.email} onChange={handleCustomerChange} />
              </div>
              <div className="col-md-6">
                <input type="text" className="form-control" placeholder="Phone No" name="phone" value={customerData.phone} onChange={handleCustomerChange} />
              </div>
              <div className="col-md-12">
                <button className="btn btn-primary save-btn-1" type="submit">Save</button>
                <button className="btn btn-primary" type="button" onClick={handleCancel}>Cancel</button>
              </div>
            </div>
          </form>
          <NotificationContainer/>
        </div>
      {/* </Collapsible> */}

      </div>
    </>
  );
}

export default CustomerCollapse;