import React from 'react'

const CustomTable = ({ col_names, data }) => {

  return (
    <table className="table table-striped">
      <thead className='sticky-header'>
        <tr>
          {col_names.map((a, index) => (
            <th key={index} scope="col">{a}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((r, rowIndex) => (
          <tr key={rowIndex}>
            {col_names.map((c, colIndex) => (
              <td key={colIndex}>{r[c]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CustomTable;
