import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBars, FaMoneyBill, FaUsps, FaTools, FaGift } from "react-icons/fa";
import {
  MdMonetizationOn,
  MdDashboard,
  MdAssessment,
  MdTune,
  MdBuild,
  MdAccountBox,
} from "react-icons/md";
import { FaUserLock, FaCog } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import "./side-bar.css";
import { current_user } from "../../Authentication/AuthenticationContext";
import { useTranslation } from "react-i18next";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [companyLogo, setCompanyLogo] = useState();
  const [companyName, setCompanyName] = useState();
  const [userRole, setUserRole] = useState("");
  const [user, setUser] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchUser();
  }, []);

  const hasFeature = (featureName) => {
    return user && user.features?.some(feature => feature?.name === featureName && feature?.enable);
  };

  const fetchUser = async () => {
    const currentUser = await current_user();
    setUser(currentUser);
    if (
      currentUser &&
      currentUser.user_detail &&
      currentUser.user_detail.role
    ) {
      setUserRole(currentUser.user_detail.role.name);
    }
    setCompanyLogo(currentUser.company?.logo_url);
    setCompanyName(currentUser.company?.name);
  };

  const routes = [
    {
      path: "/dashboard",
      name: t("Dashboard"),
      icon: <MdDashboard />,
    },
    {
      path: "/reports",
      name: t("Reports"),
      icon: <MdAssessment />,
    },
    // {
    //   path: "/platform-dashboard",
    //   name: "Online Order",
    //   icon: <FontAwesomeIcon icon={faGlobe} />,
    // },
    {
      path: "/roles-permissions",
      name: t("Roles & Permissions"),
      icon: <FaUserLock />,
      visible: userRole !== "staff" || userRole !== "cashier" 
    },
    {
      path: "/template-settings",
      name: t("Template Setting"),
      icon: <MdAccountBox />,
      exact: true,
      subRoutes: [
        {
          path: "pages/settings/template-setting",
          name: t("B2B Template Setting"),
          icon: <MdBuild />,
        },
        {
          path: "/invoice-settings",
          name: t("B2C Template Setting"),
          icon: <MdAccountBox />,
        },
      ],
      visible: hasFeature("Is_setting_Enable") && (userRole !== "staff" ||  userRole !== "cashier")
    },
    {
      path: "/manage",
      name: t("Manage"),
      icon: <FaTools />,
      exact: true,
      subRoutes: [
        {
          path: "/promotion",
          name: t("Promotion"),
          icon: <FaGift />,
        },
        {
          path: "/expanse-bill",
          name: t("Expense"),
          icon: <FaMoneyBill />,
        },
        {
          path: "/restaurant-coupons",
          name: t("Coupon"),
          icon: <MdAccountBox />,
        },
        {
          path: "/create-unit",
          name: t("Unit"),
          icon: <FaUsps />,
        },
        {
          path: "/create-category",
          name: t("Category"),
          icon: <FontAwesomeIcon icon={faLayerGroup}/>,
        },
      ]
    },
    {
      path: "/settings",
      name: t("Settings"),
      icon: <FaCog />,
      exact: true,
      subRoutes: [
        {
          path: "pages/settings/tax-billing",
          name: t("Tax & Billing"),
          icon: <MdMonetizationOn />,
        },
        {
          path: "pages/settings/managing-tax",
          name: t("Managing Tax Settings"),
          icon: <MdTune />,
        },
        {
          path: "pages/settings/item-setting",
          name: t("Item Setting"),
          icon: <MdBuild />,
        },       
        {
          path: "/restaurant-setting",
          name: t("Restaurant Settings"),
          icon: <MdBuild />,
        },
      ],
      visible: hasFeature("Is_setting_Enable")
    },
  ];

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const getInitials = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  return (
    <div className="main-container">
      <motion.div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        animate={{
          width: isOpen ? "300px" : "45px",
          transition: {
            duration: 0.5,
            type: "spring",
            damping: 10,
          },
        }}
        className={`sidebar ${["invoice", "kitchen-ceff"].some(substring => window.location.href.includes(substring)) ? "sidebar-invoice" : ""}`}
      >
        <div className="top_section">
          <AnimatePresence>
            {isOpen && (
              <motion.h1
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="logo"
              >
                <div className="company-logo-container">
                  {companyLogo ? (
                    <img
                      height="40px"
                      width="40px"
                      borderRadius="50%"
                      src={companyLogo}
                      alt={`${companyName} Logo`}
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <div className="company-initials">
                      {getInitials(companyName)}
                    </div>
                  )}
                </div>
              </motion.h1>
            )}
          </AnimatePresence>
          <div className="bars">
            <FaBars />
          </div>
        </div>
        <section className="routes">
          {routes.map((route, index) => {
            if (route.visible === false) {
              return null;
            }
            if (route.subRoutes) {
              return (
                <SidebarMenu
                  key={index}
                  setIsOpen={setIsOpen}
                  route={route}
                  isOpen={isOpen}
                />
              );
            }
            return (
              <NavLink to={route.path} key={index} className="link">
                <div className="icon">{route.icon}</div>
                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className="link_text"
                    >
                      {route.name}
                    </motion.div>
                  )}
                </AnimatePresence>
              </NavLink>
            );
          })}
        </section>
      </motion.div>
      <main>{children}</main>
    </div>
  );
};

export default SideBar;