import React, { useEffect, useState } from "react";
import "./ItemUnit.css";
import Header from "../../Header";
import Search from "../../Search/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UnitPopup from "./UnitPopup";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import PaginationControls from "../../Common/PaginationControls";
import { useTranslation } from "react-i18next";


function ItemUnit() {
  const [isOpenUnitPopup, setUnitPopup] = useState(false);
  const [unitData, setUnitData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const { t} = useTranslation();


  const handleOpenUnitPopup = () => {
    setUnitPopup(true);
  };

  const closeUnitPopup = () => {
    setUnitPopup(false);
  };

  const fetchUnit = async (pageNumber = 1, perPage = 10) => {
    try {
      let requestUrl = `${BASE_URL}/v1/inventory_managment/units?page=${pageNumber}&per_page=${perPage}`;
      if (searchQuery.length > 2) {
        requestUrl += `&query=${searchQuery}`;
      }
      const response = await sendRequest('GET', requestUrl);
      if (response.status === 200) {
        setUnitData(response.data.data);
        setTotalPages(Math.ceil(response.data.total_count / perPage));
        setTotalRecords(response.data.total_count);
      }
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };

  useEffect(() => {
    fetchUnit(currentPage, recordsPerPage, searchQuery);
  }, [currentPage, recordsPerPage, searchQuery]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (query) => {
    if (query.length >= 2) {
      setSearchQuery(query);
    } else {
      setSearchQuery("");
      setCurrentPage(1);
    }
  };

  const refreshUnits = () => {
    fetchUnit(currentPage, recordsPerPage);
  }

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  return (
    <>
      <Header />
      <div className="item-unit-main-container">
        <div className="item-add-unit-button-container">
          <span className="unit-list-name">{t("Unit List")} ({totalRecords})</span>
          <button className="btn btn-primary" onClick={handleOpenUnitPopup}>
            <FontAwesomeIcon icon={faPlus} className="unit-plus-icon" />
            <span>{t("Add Unit")}</span>
          </button>
        </div>
        <div className="item-unit-second-header">
          <div className="unit-pagination-dropdown">
            <EntriesPerPageDropdown
              recordPerPage={recordsPerPage}
              handleRecordPerPageChange={handleRecordPerPageChange}
            />
          </div>
          <div className="unit-search-field" value={searchQuery} onChange={e => handleSearch(e.target.value)}><Search placeholder={t("Search Units ...")} /></div>
        </div>
        <div className="unit-table-container">
          <table className="table-view">
            <thead>
              <tr>
                <th>{t("S. No.")}</th>
                <th>{t("Unit Name")}</th>
              </tr>
            </thead>
            <tbody>
              {unitData.length > 0 ? (
                unitData.map((unit, index) => (
                  <tr key={unit.id}>
                    <td>{startIndex + index}</td>
                    <td>{unit.unit_name}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">{t("No records found")}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {totalRecords > recordsPerPage && (
          <div>
            <div className="report-pagination-info">
              <div className="report-pagination-container">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                  totalRecords={totalRecords}
                  startIndex={startIndex}
                  endIndex={endIndex}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {isOpenUnitPopup &&
        <div className="item-unit-popup-container">
          <UnitPopup onClose={closeUnitPopup} createdUnit={refreshUnits} />
        </div>
      }
    </>
  );
}

export default ItemUnit;

