import React from "react";
import QRCode from "qrcode.react";

const PrintableInvoice = ({ invoiceData, templateId, templateConfig }) => {
  const isEnabled = (optionName) => {
    const option = templateConfig.find((opt) => opt.option_name === optionName);
    return option ? option.template_config?.is_enable : false;
  };

  const getFreeText = (optionName) => {
    const option = templateConfig.find((opt) => opt.option_name === optionName);
    return option ? option.template_config.free_text : "";
  };

  const getAlign = (optionName) => {
    const option = templateConfig.find((opt) => opt.option_name === optionName);
    return option ? option.template_config?.alignment || 'center' : 'center';
  };

  const getPrintStyle = () => {
    const thermalPrinterStyle = `
      @media print {
        body {
          font-family: 'Arial', sans-serif;
          font-size: 8px;
          line-height: 1.2;
          margin: 0;
          padding: 0;
          width: 58mm;
        }
        .thermal-receipt {
          width: 100%;
          padding: 2mm 1mm;
        }
        .receipt-header {
          text-align: center;
          margin-bottom: 3mm;
          font-size: 12px !important;
        }
        .receipt-header h1 {
          font-size: 25px !important;
          margin: 0 0 1mm 0;
        }
        .receipt-header p {
          margin: 0 0 0.5mm 0;
        }
        .receipt-items table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 3mm;
        }
        .receipt-items th, .receipt-items td {
          text-align: left;
          padding: 0.5mm 0;
          border-bottom: 1px dotted #000;
        }
        .receipt-items th:first-child, .receipt-items td:first-child {
          width: 50%;
        }
        .receipt-items th:nth-child(2), .receipt-items td:nth-child(2) {
          width: 20%;
        }
        .receipt-items th:last-child, .receipt-items td:last-child {
          width: 30%;
          text-align: right;
        }
        .receipt-summary {
          margin-bottom: 3mm;
          font-weight: bold !important;
          font-size: 12px;
        }
        .summary-line {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5mm;
        }
        .total {
          font-weight: bold;
          border-top: 1px solid #000;
          padding-top: 0.5mm;
        }
        .receipt-footer {
          text-align: center;
          margin-top: 3mm;
        }
        .qr-code {
          margin-top: 3mm;
          text-align: center;
        }
      }
    `;
  
    switch (templateId) {
      
      case 6:
        return `
          @media print {
            body {
              font-family: 'Arial', sans-serif;
              font-size: 9px;
              line-height: 1.2;
              margin: 0;
              padding: 0;
              width: 58mm;
            }
            .thermal-receipt {
              width: 100%;
              padding: 2mm 1mm;
            }
            .receipt-header {
              text-align: center;
              margin-bottom: 4mm;
              font-size: 12px !important;
            }
            .receipt-header h1 {
              margin: 0 0 2mm 0;
              border-bottom: 2px solid black;
              padding-bottom: 1mm;
              font-size: 25px !important;
            }
            .receipt-header p {
              margin: 0 0 0.5mm 0;
            }
            .receipt-items table {
              width: 100%;
              border-collapse: separate;
              border-spacing: 0 1mm;
            }
            .receipt-items th, .receipt-items td {
              text-align: left;
              padding: 1mm 0;
            }
            .receipt-items th:first-child, .receipt-items td:first-child {
              width: 50%;
            }
            .receipt-items th:nth-child(2), .receipt-items td:nth-child(2) {
              width: 20%;
              text-align: center;
            }
            .receipt-items th:last-child, .receipt-items td:last-child {
              width: 30%;
              text-align: right;
            }
            .receipt-summary {
              margin: 3mm 0;
              border-top: 1px solid black;
              border-bottom: 1px solid black;
              padding: 2mm 0;
              font-size: 12px;
            }
            .summary-line {
              display: flex;
              justify-content: space-between;
              margin-bottom: 1mm;
            }
            .total {
              font-weight: bold;
              font-size: 12px;
            }
            .receipt-footer {
              text-align: center;
              margin-top: 4mm;
              font-style: italic;
            }
            .qr-code {
              margin-top: 3mm;
              text-align: center;
            }
          }
        `;
      case 7:
        return `
          @media print {
            body {
              font-family: 'Courier', monospace;
              font-size: 8px;
              line-height: 1.3;
              margin: 0;
              padding: 0;
              width: 58mm;
            }
            .thermal-receipt {
              width: 100%;
              padding: 2mm 1mm;
            }
            .receipt-header {
              text-align: center;
              margin-bottom: 3mm;
              font-size: 12px !important;
            }
            .receipt-header h1 {
              font-size: 16px;
              margin: 0 0 2mm 0;
              border: 1px solid black;
              padding: 1mm;
              font-size: 25px !important;
            }
            .receipt-header p {
              margin: 0 0 0.5mm 0;
            }
            .receipt-items table {
              width: 100%;
              border-collapse: collapse;
            }
            .receipt-items th, .receipt-items td {
              text-align: left;
              padding: 0.5mm 0;
              border-bottom: 1px dashed black;
            }
            .receipt-items th:first-child, .receipt-items td:first-child {
              width: 50%;
            }
            .receipt-items th:nth-child(2), .receipt-items td:nth-child(2) {
              width: 20%;
            }
            .receipt-items th:last-child, .receipt-items td:last-child {
              width: 30%;
              text-align: right;
            }
            .receipt-summary {
              margin: 3mm 0;
              border: 1px solid black;
              padding: 2mm;
              font-size: 12px;
            }
            .summary-line {
              display: flex;
              justify-content: space-between;
              margin-bottom: 1mm;
            }
            .total {
              font-weight: bold;
              font-size: 10px;
              border-top: 1px solid black;
              padding-top: 1mm;
            }
            .receipt-footer {
              text-align: center;
              margin-top: 3mm;
              border-top: 1px dashed black;
              padding-top: 2mm;
            }
            .qr-code {
              margin-top: 3mm;
              text-align: center;
            }
          }
        `;
      default:
        return thermalPrinterStyle;
    }
  };
  const getPreviewStyle = () => {
    const commonStyles = `
      .preview-thermal-receipt {
        font-family: 'Arial', sans-serif;
        font-size: 14px;
        line-height: 1.4;
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
        padding: 20px;
        background-color: white;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      }
      .preview-receipt-header {
        text-align: center;
        margin-bottom: 20px;
      }
      .preview-receipt-header h1 {
        font-size: 24px;
        margin: 0 0 10px 0;
      }
      .preview-receipt-header p {
        margin: 0 0 5px 0;
      }
      .preview-receipt-items table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      .preview-receipt-items th, .preview-receipt-items td {
        text-align: left;
        padding: 8px 0;
        border-bottom: 1px solid #ddd;
      }
      .preview-receipt-summary {
        margin-bottom: 20px;
      }
      .preview-summary-line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }
      .preview-total {
        font-weight: bold;
        border-top: 2px solid #000;
        padding-top: 10px;
      }
      .preview-receipt-footer {
        text-align: center;
        margin-top: 20px;
      }
      .preview-qr-code {
        margin-top: 20px;
        text-align: center;
      }
    `;

    switch (templateId) {
      case 5:
      return `
        ${commonStyles}
        .preview-thermal-receipt {
          font-family: 'Arial', sans-serif;
          font-size: 12px;
          line-height: 1.2;
        }
        .preview-receipt-header {
          text-align: center;
          margin-bottom: 10px;
        }
        .preview-receipt-header h1 {
          font-size: 18px;
          margin: 0 0 5px 0;
        }
        // Add more specific styles to match template 5
      `;
    case 6:
      return `
        ${commonStyles}
        .preview-thermal-receipt {
          font-family: 'Arial', sans-serif;
          font-size: 12px;
          line-height: 1.2;
        }
        .preview-receipt-header {
          text-align: center;
          margin-bottom: 15px;
        }
        .preview-receipt-header h1 {
          font-size: 20px;
          margin: 0 0 10px 0;
          border-bottom: 2px solid black;
          padding-bottom: 5px;
        }
        // Add more specific styles to match template 6
      `;
    case 7:
      return `
        ${commonStyles}
        .preview-thermal-receipt {
          font-family: 'Courier', monospace;
          font-size: 11px;
          line-height: 1.3;
        }
        .preview-receipt-header {
          text-align: center;
          margin-bottom: 10px;
        }
        .preview-receipt-header h1 {
          font-size: 16px;
          margin: 0 0 5px 0;
          border: 1px solid black;
          padding: 3px;
        }
        // Add more specific styles to match template 7
      `;
    case 8:
      return `
        ${commonStyles}
        .preview-thermal-receipt {
          font-family: 'Arial', sans-serif;
          font-size: 12px;
          line-height: 1.2;
        }
        .preview-receipt-header {
          text-align: center;
          margin-bottom: 10px;
        }
        .preview-receipt-header h1 {
          font-size: 18px;
          margin: 0 0 5px 0;
          font-weight: bold;
        }
        // Add more specific styles to match template 8
      `;
    default:
      return commonStyles;
  }
};

  const safelyGetValue = (obj, path, defaultValue = "") => {
    return (
      path.split(".").reduce((acc, part) => acc && acc[part], obj) ||
      defaultValue
    );
  };

  // Helper function to safely format numbers
  const safelyFormatNumber = (value, decimalPlaces = 2) => {
    const number = parseFloat(value);
    return isNaN(number) ? "0.00" : number.toFixed(decimalPlaces);
  };

  return (
    <>
    <style>{getPreviewStyle()}</style>
    <style media="print">{getPrintStyle()}</style>
    {invoiceData?.bill_type === 'Restaurant' ? (
      <div className={`preview-thermal-receipt template-${templateId}`}>
        <div className="preview-receipt-header">
          {isEnabled("company_logo") && (
            <img
              src={invoiceData?.logo_url || "default-logo.png"}
              alt="Company Logo"
              style={{ maxWidth: "150px", maxHeight: "50px"}}
            />
          )}
          {isEnabled("company_name") && (
            <h1 style={{textAlign: getAlign("company_name")}}>
              {getFreeText("company_name") ||
                safelyGetValue(invoiceData, "company.name", "").replace(
                  /_/g,
                  " "
                )}
            </h1>
          )}
          {isEnabled("address") && (
            <p style={{textAlign: getAlign("address")}}>
              {getFreeText("address") ||
                safelyGetValue(invoiceData, "branch.branch_address")}
            </p>
          )}
          {isEnabled("phone_number") && (
            <p style={{textAlign: getAlign("phone_number")}}>
              Phone:{" "}
              {getFreeText("phone_number") ||
                safelyGetValue(invoiceData, "company.phone")}
            </p>
          )}
          {isEnabled("insta_id") && (
            <p style={{textAlign: getAlign("insta_id")}}>
              insta_id:{" "}
              {getFreeText("insta_id") ||
                safelyGetValue(invoiceData, "no insta id")}
            </p>
          )}
          {isEnabled("email") && (
            <p style={{textAlign: getAlign("email")}}>
              Email:{" "}
              {getFreeText("email") ||
                safelyGetValue(invoiceData, "company.email")}
            </p>
          )}
        </div>
  
        {isEnabled("invoice_number") && (
          <p style={{textAlign: getAlign("invoice_number")}}>Invoice Number: {invoiceData.id}</p>
        )}
        {isEnabled("date") && <p style={{textAlign: getAlign("date")}}>Date: {new Date().toLocaleDateString()}</p>}
        {isEnabled("time") && <p style={{textAlign: getAlign("time")}}>Time: {new Date().toLocaleTimeString()}</p>}
  
        <div className="preview-receipt-items">
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {safelyGetValue(invoiceData, "order_groups", []).map(
                (orderGroup, groupIndex) =>
                  orderGroup.order_items.map((item, itemIndex) => (
                    <tr key={`${groupIndex}-${itemIndex}`}>
                      <td>{item.item_name}</td>
                      <td>{item.quantity}</td>
                      <td>{safelyFormatNumber(item.item_total_amount)}</td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
  
        <div className="preview-receipt-summary">
          <div className="preview-summary-line">
            <span>Subtotal:</span>
            <span>{safelyFormatNumber(invoiceData.sub_total)}</span>
          </div>
          {isEnabled("discount") && (
            <div className="preview-summary-line">
              <span>Discount:</span>
              <span>{safelyFormatNumber(invoiceData.total_discount)}</span>
            </div>
          )}
          <div className="preview-summary-line">
            <span>Tax:</span>
            <span>{safelyFormatNumber(invoiceData.total_tax)}</span>
          </div>
          <div className="preview-summary-line preview-total">
            <span>Total:</span>
            <span>{safelyFormatNumber(invoiceData.total_amount)}</span>
          </div>
        </div>
  
        <div className="preview-receipt-footer">
          {isEnabled("return_policy") && (
            <p style={{textAlign: getAlign("return_policy")}}>
              {getFreeText("return_policy") || "Thank you for your business!"}
            </p>
          )}
        </div>
  
        {isEnabled("barcode") && (
          <div className="preview-qr-code">
            <QRCode
              value={`Invoice #${invoiceData.id}`}
              size={100}
            />
          </div>
        )}
      </div>
    ) : (
      <div className={`preview-thermal-receipt template-${templateId}`}>
        <div className="preview-receipt-header">
          {isEnabled("company_logo") && (
            <img
              src={invoiceData?.logo_url || "default-logo.png"}
              alt="Company Logo"
              style={{ maxWidth: "150px", maxHeight: "50px" }}
            />
          )}
          {isEnabled("company_name") && (
            <h1 style={{textAlign: getAlign("company_name")}}>
              {getFreeText("company_name") ||
                safelyGetValue(invoiceData, "company.name", "").replace(
                  /_/g,
                  " "
                )}
            </h1>
          )}
          {isEnabled("address") && (
            <p style={{textAlign: getAlign("address")}}>
              {getFreeText("address") ||
                safelyGetValue(invoiceData, "company.address")}
            </p>
          )}
          {isEnabled("phone_number") && (
            <p style={{textAlign: getAlign("phone_number")}}>
              Phone:{" "}
              {getFreeText("phone_number") ||
                safelyGetValue(invoiceData, "company.phone")}
            </p>
          )}
          {isEnabled("insta_id") && (
            <p style={{textAlign: getAlign("insta_id")}}>
              insta_id:{" "}
              {getFreeText("insta_id") ||
                safelyGetValue(invoiceData, "no insta id")}
            </p>
          )}
          {isEnabled("email") && (
            <p style={{textAlign: getAlign("email")}}>
              Email:{" "}
              {getFreeText("email") ||
                safelyGetValue(invoiceData, "company.email")}
            </p>
          )}
        </div>
  
        {isEnabled("invoice_number") && (
          <p style={{textAlign: getAlign("invoice_number")}}>Invoice Number: {safelyGetValue(invoiceData, "bill_number")}</p>
        )}
        {isEnabled("date") && <p style={{textAlign: getAlign("date")}}>Date: {new Date().toLocaleDateString()}</p>}
        {isEnabled("time") && <p style={{textAlign: getAlign("time")}}>Time: {new Date().toLocaleTimeString()}</p>}
  
        <div className="preview-receipt-items">
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {safelyGetValue(invoiceData, "bill_items", []).map(
                (item, index) => (
                  <tr key={index}>
                    <td>{safelyGetValue(item, "name")}</td>
                    <td>{safelyGetValue(item, "quantity")}</td>
                    <td>
                      {safelyFormatNumber(
                        safelyGetValue(item, "item_total_amount")
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
  
        <div className="preview-receipt-summary">
          <div className="preview-summary-line">
            <span>Subtotal:</span>
            <span>
              {safelyFormatNumber(safelyGetValue(invoiceData, "sub_total"))}
            </span>
          </div>
          {isEnabled("discount") && (
            <div className="preview-summary-line">
              <span>Discount:</span>
              <span>
                {safelyGetValue(invoiceData, "total_discount", "0.00")}
              </span>
            </div>
          )}
          <div className="preview-summary-line">
            <span>Tax:</span>
            <span>
              {safelyFormatNumber(safelyGetValue(invoiceData, "total_tax"))}
            </span>
          </div>
          <div className="preview-summary-line preview-total">
            <span>Total:</span>
            <span>
              {safelyFormatNumber(safelyGetValue(invoiceData, "total_amount"))}
            </span>
          </div>
        </div>
  
        <div className="preview-receipt-footer">
          {isEnabled("return_policy") && (
            <p style={{textAlign: getAlign("return_policy")}}>
              {getFreeText("return_policy") || "Thank you for your business!"}
            </p>
          )}
        </div>
  
        {isEnabled("barcode") && (
          <div className="preview-qr-code">
            <QRCode
              value={`Invoice #${safelyGetValue(invoiceData, "bill_number")}`}
              size={100}
            />
          </div>
        )}
      </div>
    )}
  </>
  );
};

export default PrintableInvoice;

