import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { BASE_URL } from "../../Url/constants";
import { sendRequest } from "../../ApiRequest";
import ErrorNotification from "../../Notification/ErrorNotification";
import successNotification from "../../Notification/SuccessNotification";

const PromotionModal = ({ isOpen, onClose, onSave }) => {
  const [promotionName, setPromotionName] = useState("");
  const [validUntil, setValidUntil] = useState("");

  const handleSave = () => {
    onSave({ name: promotionName, valid_until: validUntil });
    setPromotionName("");
    setValidUntil("");
    onClose();
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal-container bg-light border rounded">
        <div className="modal-header justify-content-between align-items-center">
          <h3>Create Promotion</h3>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group mb-3">
            <label>Promotion Name</label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter promotion name"
              value={promotionName}
              onChange={(e) => setPromotionName(e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Valid Until</label>
            <input
              className="form-control"
              type="date"
              value={validUntil}
              onChange={(e) => setValidUntil(e.target.value)}
            />
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-end">
          <button className="btn btn-primary me-2" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

const PromotionItemModal = ({ isOpen, promotionId, onClose, onSave }) => {
  const [promotionItems, setPromotionItems] = useState([
    {
      item_id: "",
      free_item_ids: [],
      promotion_type: "",
      promotion_value: "",
    },
  ]);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const fetchItems = useCallback(async () => {
    try {
      const res = await sendRequest(
        "get",
        `${BASE_URL}/v1/inventory_managment/items?page=${page}&per_page=10`
      );
      setItems((prevItems) => [...prevItems, ...res.data.data]);
      setHasMoreItems(res.data.data.length > 0);
    } catch (error) {
      console.error("Failed to fetch items:", error);
    }
  }, [page]);

  useEffect(() => {
    if (isOpen) {
      fetchItems();
    }
  }, [isOpen, fetchItems]);

  const handleAddItem = () => {
    setPromotionItems([
      ...promotionItems,
      {
        item_id: "",
        free_item_ids: [],
        promotion_type: "",
        promotion_value: "",
      },
    ]);
  };

  const handleRemoveItem = (index) => {
    const items = [...promotionItems];
    items.splice(index, 1);
    setPromotionItems(items);
  };

  const handleInputChange = (index, field, value) => {
    const items = [...promotionItems];
    items[index][field] = value;
    setPromotionItems(items);
  };

  const handleSave = () => {
    const formattedItems = promotionItems.map((item) => ({
      item_id: parseInt(item.item_id),
      free_item_ids: item.free_item_ids.map((id) => id.value),
      promotion_type: item.promotion_type,
      promotion_value: parseFloat(item.promotion_value),
    }));
    onSave(promotionId, formattedItems);
    setPromotionItems([
      {
        item_id: "",
        free_item_ids: [],
        promotion_type: "",
        promotion_value: "",
      },
    ]);
    onClose();
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && hasMoreItems) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const itemOptions = items.map((item) => ({
    value: item.id,
    label: item.item_name,
  }));

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal-container bg-light border rounded">
        <div className="modal-header d-flex justify-content-between align-items-center">
          <h3>Add Promotion Items</h3>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div
          className="modal-body"
          onScroll={handleScroll}
          style={{ maxHeight: "400px", overflowY: "auto" }}
        >
          {promotionItems.map((item, index) => (
            <div
              key={index}
              className="promotion-item-form mb-4 p-3 border rounded"
            >
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h5 className="text-blue">Item {index + 1}</h5>
                {promotionItems.length > 1 && (
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleRemoveItem(index)}
                  >
                    Remove
                  </button>
                )}
              </div>
              <div className="form-group mb-2">
                <label>Item ID</label>
                <select
                  className="form-select"
                  value={item.item_id}
                  onChange={(e) =>
                    handleInputChange(index, "item_id", e.target.value)
                  }
                >
                  <option value="">Select Item</option>
                  {items.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.item_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mb-2">
                <label>Free Item IDs</label>
                <Select
                  isMulti
                  options={itemOptions}
                  value={item.free_item_ids}
                  onChange={(selectedOptions) =>
                    handleInputChange(index, "free_item_ids", selectedOptions)
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
              <div className="form-group mb-2">
                <label>Promotion Type</label>
                <select
                  className="form-select"
                  value={item.promotion_type}
                  onChange={(e) =>
                    handleInputChange(index, "promotion_type", e.target.value)
                  }
                >
                  <option value="">Select Type</option>
                  <option value="percentage">Percentage</option>
                  <option value="flat">Flat</option>
                  <option value="bogo">Buy One Get One</option>
                </select>
              </div>
              <div className="form-group mb-2">
                <label>Promotion Value</label>
                <input
                  className="form-control"
                  type="number"
                  value={item.promotion_value}
                  onChange={(e) =>
                    handleInputChange(index, "promotion_value", e.target.value)
                  }
                />
              </div>
            </div>
          ))}
          <button className="btn btn-secondary" onClick={handleAddItem}>
            Add Another Item
          </button>
        </div>
        <div className="modal-footer d-flex justify-content-end">
          <button className="btn btn-secondary me-2" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleSave}>
            Save Items
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default PromotionItemModal;

export function Promotion() {
  const [activeTab, setActiveTab] = useState("promotion");
  const [promotionList, setPromotionList] = useState([]);
  const [promotionItemsList, setPromotionItemsList] = useState([]);
  const [showPromotionModal, setShowPromotionModal] = useState(false);
  const [showPromotionItemModal, setShowPromotionItemModal] = useState(false);
  const [selectedPromotionId, setSelectedPromotionId] = useState(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSelectedPromotionId(null);
  };

  const fetchPromotions = async () => {
    try {
      const res = await sendRequest("get", `${BASE_URL}/v1/manage/promotions`);
      setPromotionList(res.data.data);
    } catch (error) {
      error.data.errors.forEach((err) => ErrorNotification(err));
    }
  };

  const fetchPromotionItems = async (promotionId) => {
    try {
      const res = await sendRequest(
        "get",
        `${BASE_URL}/v1/manage/promotion_items?promotion_id=${promotionId}`
      );
      setPromotionItemsList(res.data.data);
    } catch (error) {
      error.data.errors.forEach((err) => ErrorNotification(err));
    }
  };

  const createPromotion = async (promotion) => {
    try {
      await sendRequest("post", `${BASE_URL}/v1/manage/promotions`, promotion);
      fetchPromotions();
      successNotification("Promotion created successfully", "success");
    } catch (error) {
      error.data.errors.forEach((err) => ErrorNotification(err));
    }
  };

  const createPromotionItem = async (promotionId, items) => {
    try {
      const params = { promotion_item: items }
      await sendRequest("post",`${BASE_URL}/v1/manage/promotion_items?promotion_id=${promotionId}`, params);
      fetchPromotionItems(promotionId);
      successNotification("Promotion items added successfully", "success");
    } catch (error) {
      error.data.errors.forEach((err) => ErrorNotification(err));
    }
  };

  useEffect(() => {
    fetchPromotions();
    if (selectedPromotionId) {
      fetchPromotionItems(selectedPromotionId);
    }
  }, [selectedPromotionId]);

  return (
    <div className="container-fluid mt-1">
      <h2 className="mb-4">Promotions Management</h2>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div>
          <button
            className={`btn p-4 ${
              activeTab === "promotion" ? "btn-primary " : "btn-outline-primary"
            } me-2`}
            onClick={() => handleTabClick("promotion")}
          >
            Promotion
          </button>
          <button
            className={`btn p-4 ${
              activeTab === "promotionList"
                ? "btn-primary"
                : "btn-outline-primary"
            }`}
            onClick={() => handleTabClick("promotionList")}
          >
            Promotion List
          </button>
        </div>
        {activeTab === "promotion" && (
          <button
            className="btn btn-primary"
            onClick={() => setShowPromotionModal(true)}
          >
           + Add Promotion
          </button>
        )}
        {activeTab === "promotionList" && selectedPromotionId && (
          <button
            className="btn btn-primary"
            onClick={() => setShowPromotionItemModal(true)}
          >
            + Add Promotion Item
          </button>
        )}
      </div>

      {activeTab === "promotion" && (
        <div>
          <PromotionModal
            isOpen={showPromotionModal}
            onClose={() => setShowPromotionModal(false)}
            onSave={createPromotion}
          />
          <div className="alert alert-info">
            Click on "Promotion List" to view and manage existing promotions.
          </div>
        </div>
      )}

      {activeTab === "promotionList" && (
        <div>
          <PromotionItemModal
            isOpen={showPromotionItemModal}
            promotionId={selectedPromotionId}
            onClose={() => setShowPromotionItemModal(false)}
            onSave={createPromotionItem}
          />
          <div className="row">
            <div className="col-md-4">
              <h4 className="text-primary">Promotions</h4>
              <ul className="list-group mb-4">
                {promotionList.map((promotion) => (
                  <li
                    key={promotion.id}
                    className={`list-group-item ${
                      selectedPromotionId === promotion.id ? "active" : ""
                    }`}
                    onClick={() => setSelectedPromotionId(promotion.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span>{promotion.name}</span>
                      <small>
                        Valid Until:{" "}
                        {new Date(promotion.valid_until).toLocaleDateString()}
                      </small>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {selectedPromotionId && (
              <div className="col-md-8">
                <h4>Promotion Items</h4>
                {promotionItemsList.length > 0 ? (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Item ID</th>
                        <th>Free Item IDs</th>
                        <th>Promotion Type</th>
                        <th>Promotion Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {promotionItemsList.map((item, index) => (
                        <tr key={index}>
                          <td>{item.item_id}</td>
                          <td>{item.free_item_ids.join(", ")}</td>
                          <td>{item.promotion_type}</td>
                          <td>{item.promotion_value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="alert alert-warning">
                    No promotion items found for this promotion.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
