import React from "react";
import Header from "../../components/Header";
import TemplateSettingView from "../../components/TemplateSettings/TemplateSettingView";

function TemplateSetting() {
    
    return (
        <>
            <Header />
            <TemplateSettingView/>
        </>
    );
}

export default TemplateSetting;
