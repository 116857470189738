import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import TaxEdit from "./EditTax/TaxEdit";
// import "./ManagingTax.css"
import { sendRequest } from "../../ApiRequest";
import "bootstrap/dist/css/bootstrap.min.css";
import { getToken } from "../SecureStorage/Token";
import { BASE_URL } from "../../Url/constants";
import ErrorNotification from "../../Notification/ErrorNotification";
import successNotification from "../../Notification/SuccessNotification";
import ConfirmPopup from "../ConfirmPop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ManageTaxRate.css";
import { useTranslation } from "react-i18next";

const TaxRates = ({ taxes, setTaxes }) => {
  const token = getToken();
  const [show, setShow] = useState(false);
  const [selectedTax, setSelectedTax] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [taxName, setTaxName] = useState("");
  const [taxValue, setTaxValue] = useState("");
  const [taxType, setTaxType] = useState("");
  const [errors, setErrors] = useState({});
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [taxToDelete, setTaxToDelete] = useState(null);
  const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
    setTaxName("");
    setErrors({});
    setTaxValue("");
    setTaxType("");
  };
  const handleShow = () => setShow(true);

  const validateForm = () => {
    const newErrors = {};

    if (!taxName) {
      newErrors.taxName = "Tax name is required.";
    } else if (!/^[a-zA-Z0-9]{1,16}$/.test(taxName)) {
      newErrors.taxName = "Tax name must be alphanumeric and up to 16 characters.";
    }

    if (!taxValue) {
      newErrors.taxValue = "Tax value is required.";
    } else if (!/^\d{0,8}(\.\d{0,4})?$/.test(taxValue)) {
      newErrors.taxValue = "Tax value must be a number with up to 12 digits including decimals.";
    }

    if (taxType === "") {
      newErrors.taxType = "Tax type is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const params = {
        tax: {
          title: taxName,
          tax_value: taxValue,
          tax_type: parseInt(taxType),
        },
      };
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/taxes/taxes`,
        params,
        token
      );
      if (response.status === 201) {
        const createdTax = response.data;
        setTaxes([createdTax, ...taxes]);
        handleClose();
        setTaxName("");
        setTaxValue("");
        setTaxType("");
        successNotification("Tax added successfully");
      } else {
        ErrorNotification("Error adding tax!");
      }
    } catch (error) {
      if (error.data && error.data.title) {
        const errorMessage = `The title "${taxName}" ${error.data.title[0]}.`;
        ErrorNotification(errorMessage);
      } else {
        ErrorNotification("Error adding tax!");
      }
    }
  };

  const handleChange = (e, field) => {
    if (field === "taxName") {
        const value = e.target.value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 16);
        setTaxName(value);
    } else if (field === "taxValue") {
        const value = e.target.value;
        const isValid = /^\d{0,8}(\.\d{0,4})?$/.test(value);
        if (isValid || value === "") {
            setTaxValue(value);
        }
    } else if (field === "taxType") {
        const value = e.target.value === "" ? "" : e.target.value;
        setTaxType(value);
    }

    setErrors({});
  };

  const handleCloseModal = () => {
    setIsModal(false);
  };

  const handleUpdateFormSubmit = (updatedTaxData) => {
    const updatedTaxes = taxes.map((tax) => {
      if (tax.id === updatedTaxData.id) {
        return updatedTaxData;
      }
      return tax;
    });
    setTaxes(updatedTaxes);
    setIsModal(false);
  };

  const handleDelete = async () => {
    const taxId = taxToDelete.id;
    const url = `${BASE_URL}/v1/taxes/taxes/${taxId}`;

    try {
      const response = await sendRequest("delete", url, null, token);
      if (response.status === 204) {
        const newTaxes = taxes.filter((singleTax) => singleTax.id !== taxId);
        setTaxes(newTaxes);
        successNotification("Tax deleted successfully");
      } else {
        ErrorNotification(
          "Tax is getting used in taxgroup so it should not deleted !"
        );
      }
    } catch (error) {
      ErrorNotification(
        "Tax is getting used in taxgroup so it should not deleted !"
      );
    }
    setShowConfirmPopup(false);
  };

  const handleEditTax = (tax) => {
    setIsModal(true);
    setSelectedTax(tax);
  };

  const handleDeleteClick = (tax) => {
    setTaxToDelete(tax);
    setShowConfirmPopup(true);
  };

  return (
    <div>
      <div className="tax-rate-scrollbar">
        <div>
          <div className="tax-rate-s">
            <div className="tax-head">{t("Tax Rates")} </div>
            <div className="button-rate">
              <button className="btn btn-primary" onClick={handleShow}>
                <FontAwesomeIcon
                  icon={faPlus}
                  className="tax-rate-plus-icon"
                  onClick={handleShow}
                />
                <span>{t("Add")}</span>
              </button>
            </div>
          </div>
          <hr />
          <div className="tax-rate-scroll mt-3">
            <div className="tax-rates-row">
              {taxes.map((taxRate) => (
                <div className="row">
                  <div className="tax-rate-div">
                    <div>{taxRate.title}</div>
                    <div style={{ display: "flex" }}>
                      <div className="tax-rate-value">{taxRate.tax_value}</div>
                      <div className="tax-value-symbol">
                        {taxRate.tax_type === "percentage" ? "(%)" : "(Fix)"}
                      </div>
                      <FaEdit
                        className="edit-trash-managing-tax"
                        onClick={() => handleEditTax(taxRate)}
                      />
                      <FaTrash
                        className="edit-trash-delete-managing-tax"
                        onClick={() => handleDeleteClick(taxRate)}
                      />
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col-6">
          {show && (
            <div className="modal">
              <Modal show={show} onHide={handleClose} centered>
                <div className="tax-modal">
                <Modal.Header>
                  <Modal.Title>{t("Tax Details")}</Modal.Title>
                  <Button
                    variant="link"
                    onClick={handleClose}
                    className="tax-rate-close-button text-light"
                    >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </Modal.Header>
                </div>
                <Modal.Body>
                  <div className="tax-field">
                    <label className="col-3 tax-rate-group-font">
                      {t("Tax Name")}
                    </label>
                    <input
                      type="text"
                      value={taxName}
                      onChange={(e) => handleChange(e, "taxName")}
                      className="form-control"
                    />
                    {errors.taxName && (
                      <div className="tax-error">{errors.taxName}</div>
                    )}
                  </div>
                  <div className="tax-field">
                    <label className="col-3 tax-rate-group-font">
                      {t("Tax Rate")}
                    </label>
                    <input
                      type="text"
                      value={taxValue}
                      onChange={(e) => handleChange(e, "taxValue")}
                      className="form-control"
                    />
                    {errors.taxValue && (
                      <div className="tax-error">{errors.taxValue}</div>
                    )}
                  </div>
                  <div className="tax-field">
                    <label className="col-3 tax-rate-group-font">
                      {t("Tax Type")}
                    </label>
                    <select
                      className="form-control form-select"
                      value={taxType}
                      onChange={(e) => handleChange(e, "taxType")}
                    >
                      <option value="" hidden disabled>
                        {t("Select tax type")}
                      </option>
                      <option value={0}>{t("Percentage")}</option>
                      <option value={1}>{t("Fixed")}</option>
                    </select>
                    <div className="tax-rate-drop-icon">
                      {/* <FontAwesomeIcon icon={faChevronDown} /> */}
                    </div>
                    {errors.taxType && (
                      <div className="tax-error">{errors.taxType}</div>
                    )}
                  </div>
                </Modal.Body>
                <div className="tax-footer">
                <Modal.Footer>
                  <Button variant="primary" onClick={handleSave}>
                    {t("Save")}
                  </Button>
                </Modal.Footer>
                </div>
              </Modal>
            </div>
          )}
        </div>
      </div>
      <div>
        {isModal && (
          <div>
            <div className="modal-background" onClick={handleCloseModal}></div>
            <TaxEdit
              tax={selectedTax}
              handleUpdateFormSubmit={handleUpdateFormSubmit}
              handleCloseModal={handleCloseModal}
            />
          </div>
        )}
      </div>

      {showConfirmPopup && (
        <ConfirmPopup
          title="Confirm Delete"
          message={`Are you sure you want to delete the tax "${taxToDelete.title}"?`}
          onConfirm={handleDelete}
          onCancel={() => setShowConfirmPopup(false)}
        />
      )}
    </div>
  );
};

export default TaxRates;
