import React from "react"
import "./CommonCloseButton.css"

function CommonCloseButton() {
  return (
    <>
      <span className='res-common-close-button'>X</span>
    </>
  )
}

export default CommonCloseButton;
