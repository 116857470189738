import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./ManagingTax.css";
import EditTaxGroup from "./EditTaxGroup/EditTaxGroup";
import Select from 'react-select';
import { getToken } from "../SecureStorage/Token";
import { BASE_URL } from "../../Url/constants";
import { sendRequest } from "../../ApiRequest";
import { NotificationContainer } from "react-notifications";
import ErrorNotification from "../../Notification/ErrorNotification";
import successNotification from "../../Notification/SuccessNotification";
import ConfirmPopup from "../ConfirmPop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ManageTaxGroup.css";
import { useTranslation } from "react-i18next";

const TaxGroup = ({ taxes }) => {
  const token = getToken();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setTaxName('');
    setSelectedOption([]);
    setErrors({});
  };
  const handleShow = () => setShow(true);

  const [taxName, setTaxName] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedOption, setSelectedOption] = useState([]);
  const [taxGroups, setTaxGroups] = useState([]);
  const [selectedTaxgroup, setSelectedTaxgroup] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [taxGroupToDelete, setTaxGroupToDelete] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchTaxGroups();
  }, []);

  const fetchTaxGroups = async () => {
    const url = `${BASE_URL}/v1/taxes/tax_groups`;

    try {
      const response = await sendRequest('get', url, null, token);
      if (response.status === 200 || response.data.length === 0) {
        const data = response.data;
        setTaxGroups(data.data);
      } else {
        ErrorNotification("Error fetching tax groups");
      }
    } catch (error) {
      ErrorNotification("Error fetching tax groups");
    }
  };

  const handleCloseModal = () => {
    setIsModal(false);
  };

  const handleUpdateFormSubmit = (updatedTaxData) => {
    const updatedTaxGroups = taxGroups.map((taxGroup) => {
      if (taxGroup.id === updatedTaxData.id) {
        return updatedTaxData;
      }
      return taxGroup;
    });
    setTaxGroups(updatedTaxGroups);
    setIsModal(false);
  };

  const handleDelete = async () => {
    const taxGroupId = taxGroupToDelete.id;
    const url = `${BASE_URL}/v1/taxes/tax_groups/${taxGroupId}`;

    try {
      const response = await sendRequest('delete', url, null, token);
      if (response.status === 204) {
        const newTaxGroups = taxGroups.filter((singleTaxGroup) => singleTaxGroup.id !== taxGroupId);
        setTaxGroups(newTaxGroups);
        successNotification('Tax group deleted successfully');
      } else {
        ErrorNotification("Error deleting tax group");
      }
    } catch (error) {
      ErrorNotification("Error deleting tax group");
    }
    setShowConfirmPopup(false);
  };

  const handleEdit = (taxGroup) => {
    setIsModal(true);
    setSelectedTaxgroup(taxGroup);
  };

  const handleDeleteClick = (tax) => {
    setTaxGroupToDelete(tax);
    setShowConfirmPopup(true);
  };

  const handleSave = async () => {
    const errors = {};
    if (!taxName.trim()) {
      errors.taxName = "Tax Name is required";
    } else if (!/^[a-zA-Z0-9]+$/.test(taxName)) {
      errors.taxName = "Tax Name must be alphanumeric";
    } else if (taxName.length > 16) {
      errors.taxName = "Tax Name must be up to 16 characters";
    }
    if (selectedOption.length === 0) {
      errors.selectedOption = "Please select at least one tax";
    }
    if (Object.keys(errors).length === 0) {
      const url = `${BASE_URL}/v1/taxes/tax_groups`;
      const data = {
        tax_group: {
          name: taxName,
          tax_ids: selectedOption
        }
      };
      try {
        const response = await sendRequest('post', url, data, token);
        if (response.status === 200) {
          const newTaxGroup = { ...response.data.data, tax_ids: response.data.data.tax_ids };
          setTaxGroups([newTaxGroup, ...taxGroups]);
          setTaxName('');
          setSelectedOption([]);
          handleClose();
          successNotification('Tax group created successfully');
        } else {
          ErrorNotification("Error creating tax group!");
        }
      } catch (error) {
        if (error.data && error.data.name) {
          const errorMessage = `The name "${taxName}" ${error.data.name[0]}.`;
          ErrorNotification(errorMessage);
        } else {
          ErrorNotification("Error creating tax group!");
        }
      }
    } else {
      setErrors(errors);
    }
  };

  const handleChange = (e, field) => {
    if (field === "taxName") {
      const value = e.target.value;
      if (/^[a-zA-Z0-9]*$/.test(value) && value.length <= 16) {
        setTaxName(value);
      }
    } else if (field === "dropdown") {
      const selectedOptions = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      setSelectedOption(selectedOptions);
    }
    setErrors({});
  };

  return (
    <div className="tax-rate-scrollbar">
      <div>
        <div className="tax-group-p">
          <div className="tax-group-head">{t("Tax Group")}{" "}</div>
          <div className="button-group">
            <button className="btn btn-primary" onClick={handleShow}>
              <FontAwesomeIcon icon={faPlus} className="tax-rate-plus-icon" onClick={handleShow} />
              <span>{t("Add")}</span>
            </button>
          </div>
        </div>
        <hr />
        <div className="tax-group-scroll mt-3">
          <div className="tax-rates-row">
            {taxGroups.map((taxGroup, index) => (
              <div key={index}>
                <div className="tax-group">
                  <div>{taxGroup.name}</div>
                  <div>
                    <div className="edit-delete-icons">
                      <FaEdit className="edit-trash-managing-tax justify-content-end" onClick={() => handleEdit(taxGroup)} />
                      <FaTrash className="edit-trash-delete-managing-tax justify-content-end" onClick={() => handleDeleteClick(taxGroup)} />
                    </div>
                  </div>
                </div>

                <div className="related-tax-rates">
                  <ul className="horizontal-list">
                    {taxGroup.taxes.map((tax, taxIndex) => (
                      <li key={taxIndex}>{tax.title}</li>
                    ))}
                  </ul>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
        <Modal show={show} onHide={handleClose} centered>
          <div className="group-modal">
            <Modal.Header>
              <Modal.Title>{t("Tax Group")} </Modal.Title>
              <Button
                variant="link"
                onClick={handleClose}
                className="tax-rate-close-button text-light"
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </Modal.Header>
          </div>  
          <Modal.Body>
            <div>
              <div className="group-contain-tax">
                <label className="group tax-rate-group-font">{t("Group Name")}</label>
                <input
                  className="form-control"
                  type="text"
                  value={taxName}
                  onChange={(e) => handleChange(e, "taxName")}
                />
                {errors.taxName && (
                  <div className="tax-group-error">{errors.taxName}</div>
                )}
              </div>
              <div className="form-select-wrapper">
                <label>{t("Taxes")}</label>
                <Select
                  className="custom-select"
                  value={selectedOption.map(optionId => ({
                    value: optionId,
                    label: taxes.find(tax => tax.id === optionId).title
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedIds = selectedOptions.map(option => option.value);
                    setSelectedOption(selectedIds);
                  }}
                  options={taxes.map((taxRate) => ({
                    value: taxRate.id,
                    label: taxRate.title,
                  }))}
                  isMulti
                  closeMenuOnSelect={false}
                  placeholder={t("Select Tax Rates")}
                />

                {errors.selectedOption && (
                  <div className="tax-group-error">{errors.selectedOption}</div>
                )}
              </div>
            </div>
          </Modal.Body>
          <div className="group-footer">
            <Modal.Footer>
              <Button variant="primary" onClick={handleSave}>
                {t("Save")}
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
      <div>
        {isModal && (
          <div>
            <div className="modal-background" onClick={handleCloseModal}></div>
            <EditTaxGroup
              taxrates={taxes}
              taxgroup={selectedTaxgroup}
              handleUpdateFormSubmit={handleUpdateFormSubmit}
              handleCloseModal={handleCloseModal}
            />
          </div>
        )}
      </div>
      <NotificationContainer />
      {showConfirmPopup && (
        <ConfirmPopup
          title="Confirm Delete"
          message={`Are you sure you want to delete the tax "${taxGroupToDelete.name}"?`}
          onConfirm={handleDelete}
          onCancel={() => setShowConfirmPopup(false)}
        />
      )}
    </div>
  );
};

export default TaxGroup;
