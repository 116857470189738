import React, { useState } from "react";
import './BarcodePrintOption.css';
import ErrorNotification from "../../../Notification/ErrorNotification";
const BarcodePrintOption = ({onClose, selectedQuantity}) => {
  const [quantity, setQuantity] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const quantityNumber = parseInt(quantity, 10);
    if (!isNaN(quantityNumber)) {
      selectedQuantity(quantityNumber);
    } else {
      ErrorNotification("Invalid quantity");
    }
    onClose();
  };

  return (
    <>
      <div className="barocde-qty-edit-container">
      <div className="barcode-edit-qty-modal-content">
        <button className="barcode-close-btn" onClick={onClose}>&#10005;</button> 
        <form className="barcode-qty-edit-form" onSubmit={handleSubmit}>
          <div className="barcode-edit-item-qty-form-group" >
          <label htmlFor="quantity">Quantity:</label>
            <input
              id="quantity"
              className="form-control"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              placeholder="Enter Barcode Quantity"
            />
          </div>
          <div className="barcode-flexbox">
            <button className="barcode-update-btn" type="submit">Print</button>
          </div> 
        </form>
      </div>
    </div>
    </>
  )
}
export default BarcodePrintOption;
