import React, { useEffect, useState } from 'react';
import { ReactTransliterate } from 'react-transliterate';
import 'react-transliterate/dist/index.css';
import { useTransliteration } from '../TransliterationContext';

const TransliterateInput = ({ placeholder, className, onChange, originalValue }) => {
  const { setText } = useTransliteration(); 
  const [displayValue, setDisplayValue] = useState(originalValue);

  useEffect(() => {
    setText(originalValue);
  }, [originalValue, setText]);

  return (
    <ReactTransliterate
      value={displayValue}
      onChangeText={(value) => {
        setText(value);
        onChange(value);
        setDisplayValue(value);
      }}
      lang="hi"
      placeholder={placeholder}
      className={className}
    />
  );
};

export default TransliterateInput;
