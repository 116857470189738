import React, { useState, useEffect } from "react";
import { BsCart, BsWallet } from "react-icons/bs";
import { BsFileBarGraph, BsArrowUp } from "react-icons/bs";
import { BASE_URL } from "../../Url/constants";
import { sendRequest } from "../../ApiRequest";
import { getToken } from "../SecureStorage/Token";
import Charts from "../../chart/Charts";
import ErrorNotification from "../../Notification/ErrorNotification";
import "./DashComponent.css";
import { useTranslation } from "react-i18next";

const setStartEndDate = (filterBy) => {
  const today = new Date();
  let start, end;

  if (filterBy === "weekly") {
    start = new Date(today);
    start.setDate(today.getDate() - today.getDay() + 1);
    end = new Date(start);
    end.setDate(start.getDate() + 6);
  } else if (filterBy === "monthly") {
    start = new Date(today.getFullYear(), today.getMonth(), 1);
    end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  } else if (filterBy === "yearly") {
    start = new Date(today.getFullYear(), 0, 1);
    end = new Date(today.getFullYear(), 11, 31);
  }

  return {
    startDate: formatStartDate(start, filterBy),
    endDate: end.toLocaleDateString(),
  };
};

const formatStartDate = (startDate, filterBy) => {
  const monthName = startDate.toLocaleString("default", { month: "short" });
  const year = startDate.getFullYear();

  if (filterBy === "yearly") {
    return `1 Jan ${year} to 31 Dec ${year}`;
  } else if (filterBy === "monthly") {
    const lastDayOfMonth = new Date(
      year,
      startDate.getMonth() + 1,
      0
    ).getDate();
    return `1 ${monthName} ${year} to ${lastDayOfMonth} ${monthName} ${year}`;
  } else if (filterBy === "weekly") {
    const endOfWeek = new Date(startDate);
    endOfWeek.setDate(startDate.getDate() + 6);
    const startMonth = startDate.toLocaleString("default", { month: "short" });
    const endMonth = endOfWeek.toLocaleString("default", { month: "short" });
    return `${startDate.getDate()} ${startMonth} ${year} to ${endOfWeek.getDate()} ${endMonth} ${year}`;
  } else {
    return startDate.toISOString().split("T")[0];
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Expenses = () => {
  const { t } = useTranslation();
  const [fetchBillExpanse, setFetchExpenses] = useState();
  const [filterBy, setFilterBy] = useState("weekly");

  useEffect(() => {
    const fetch_bills = async () => {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/setting/expanses/total_expenses?filter_by=${filterBy.toLowerCase()}`,
          null
        );
        if (response.status === 200) {
          setFetchExpenses(response.data);
        } else {
          ErrorNotification("No data found");
        }
      } catch (error) {
        ErrorNotification(error.errors);
      }
    };
    fetch_bills();
  }, [filterBy]);

  const capitalizedFilterBy = capitalizeFirstLetter(t(filterBy));

  return (
    <div className="col-md-4">
      <div className="dash-box large-box">
        <div className="row-content">
          <div className="col-md-6">
            <h3>
              <BsWallet />
              {t("Expenses")}
            </h3>
            <div className="expanse-fetch-total">
              {fetchBillExpanse
                ? fetchBillExpanse?.total_expenses.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })
                : ""}
            </div>
          </div>
          <div style={{ flex: 3 }} className="col-md-6">
            <select
              className="form-select text-dark"
              aria-label="Default select example"
              value={filterBy}
              onChange={(e) => setFilterBy(e.target.value)}
            >
              <option value="weekly">{t("Weekly")}</option>
              <option value="monthly">{t("Monthly")}</option>
              <option value="yearly">{t("Yearly")}</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="dash-report-txt">
            <h5>
            {`${t(capitalizedFilterBy)} ${t('Expenses')}`}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

const Sale = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [saleData, setSaleData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [filterBy, setFilterBy] = useState("weekly");
  const token = getToken();

  const capitalizedFilterBy = capitalizeFirstLetter(t(filterBy));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [saleResponse, chartResponse] = await Promise.all([
          sendRequest(
            "GET",
            `${BASE_URL}/v1/reports/growth?report_type=sales&filter_by=${filterBy.toLowerCase()}`,
            null,
            token
          ),
          sendRequest(
            "GET",
            `${BASE_URL}/v1/reports/chart_data?filter_by=${filterBy.toLowerCase()}`,
            null,
            token
          ),
        ]);

        const { startDate } = setStartEndDate(filterBy);
        setStartDate(startDate);
        setSaleData(saleResponse.data);
        setChartData(chartResponse.data.chart_data);
      } catch (error) {
        ErrorNotification(error.errors);
      }
    };

    fetchData();
  }, [filterBy, token]);

  return (
    <>
      <div className="col-md-8">
        <div className="dash-box large-box">
          <div className="row">
            <div className="col-md-4">
              <h3>
                <BsFileBarGraph />
                {t("Sale")}
              </h3>
              <div className="sale-details">
                <div className="dashvalue">
                  <h4 className="purchase-value-with-rs">
                    {saleData
                      ? Number(saleData.total.toFixed(2)).toLocaleString(
                          "en-IN",
                          { style: "currency", currency: "INR" }
                        )
                      : "₹0.00"}
                  </h4>
                </div>
                <h5>
                  {t("Total Sale")} {capitalizedFilterBy}
                </h5>
                <h4>
                  <BsArrowUp />
                  {saleData ? saleData.growth : "00"}%
                </h4>
                <p>
                  {t("Growth")} {capitalizedFilterBy}
                </p>
              </div>
            </div>
            <div className="col-md-8">
              <select
                className="form-select form-dashborad-main-box-filter text-dark"
                aria-label="Default select example"
                value={filterBy}
                onChange={(e) => setFilterBy(e.target.value)}
              >
                <option value={t("weekly")}>{t("Weekly")}</option>
                <option value={t("monthly")}>{t("Monthly")}</option>
                <option value={t("yearly")}>{t("Yearly")}</option>
              </select>
              <div className="dash-report-txt">
                <Charts chartData={chartData} />
                <h5>
                  {t("Report: from")} {startDate}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const YouRecieve = ({ fetchBills }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="col-md-4 yourecieve">
        <div className="card-smal dash-box">
          <h3>
            <BsArrowUp /> {t("You’ll Recieve")}
          </h3>
          <div className="col-md-12">
            {fetchBills === 0 ? (
              <>
                <div className="dash-receive-value">
                  <h3 className="receive-value-with-rs">
                    {fetchBills?.toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                    })}
                  </h3>
                </div>
                <p>{t("Amount to recieve")}</p>
              </>
            ) : (
              <div className="dash-receive-value">
                <h3 className="receive-value-with-rs">
                  {fetchBills?.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Youpay = ({ fetchBills }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="col-md-4 youpay">
        <div className="card-smal dash-box">
          <h3>
            <BsArrowUp /> {t("You’ll Pay")}
          </h3>
          <div className="col-md-12">
            {fetchBills === 0 ? (
              <>
                <div className="dash-pay-value">
                  <h3 className="pay-value-with-rs">
                    {fetchBills?.toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                    })}
                  </h3>
                </div>
                <p>
                  {t("No pending dues")}
                </p>
              </>
            ) : (
              <div className="dash-pay-value">
                <h3 className="pay-value-with-rs">
                  {fetchBills?.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const DashPurchase = () => {
  const { t } = useTranslation();
  const token = getToken();
  const [filterBy, setFilterBy] = useState("weekly");
  const [purchaseData, setPurchaseData] = useState([]);
  const [total, setTotal] = useState("");

  useEffect(() => {
    const handlePurchase = async () => {
      try {
        const purchaseResponse = await sendRequest(
          "GET",
          `${BASE_URL}/v1/reports/purchase?filter_by=${filterBy.toLowerCase()}`,
          null,
          token
        );

        setPurchaseData(purchaseResponse.data.data);
      } catch (error) {
        ErrorNotification(error.errors);
      }
    };

    handlePurchase();
  }, [filterBy, token]);

  useEffect(() => {
    const totals = purchaseData.map((data) => data.total_amount);
    setTotal(totals.reduce((total, amount) => total + amount, 0));
  }, [purchaseData]);

  return (
    <>
      <div className="col-md-4 dashpurchase">
        <div className="card-smal dash-box">
          <div className="row-content">
            <div className="col-md-6">
              <h3>
                <BsCart />
                {t("Purchase")}
              </h3>
            </div>
            <div style={{ flex: 3 }} className="col-md-6">
              <select
                className="form-select text-dark"
                aria-label="Default select example"
                value={filterBy}
                onChange={(e) => setFilterBy(e.target.value)}
              >
                <option value={t("weekly")}>{t("Weekly")}</option>
                <option value={t("monthly")}>{t("Monthly")}</option>
                <option value={t("yearly")}>{t("Yearly")}</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {total.length === 0 ? (
                <>
                  <div className="dash-purchase-value">
                    <h3 className="purchase-value-with-rs">
                      {total?.toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </h3>
                  </div>
                  <p>
                    {t("You have no purchased")}
                    <br />
                    {t("items entered for selected time")}
                  </p>
                </>
              ) : (
                <div className="dash-purchase-value">
                  <h3 className="purchase-value-with-rs">
                    {Number(total.toFixed(2))?.toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                    })}
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Expenses, Sale, YouRecieve, Youpay, DashPurchase };
