import React, { useState, useEffect } from "react";
import "./SelectGuest.css";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import successNotification from "../../../Notification/SuccessNotification";
import { useTranslation } from "react-i18next";
import ErrorNotification from "../../../Notification/ErrorNotification";

function SelectGuest({ onClose }) {
  const [isOpen, setIsOpen] = useState(true);
  const [showGuestList, setShowGuestList] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [guests, setGuests] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newGuest, setNewGuest] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
  });
  const [validationError, setValidationError] = useState("");

  const closeModal = () => {
    onClose(selectedGuest);
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchListGuests = async () => {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/customers`
        );
        setGuests(response.data.data);
      } catch (error) {
        console.error("Error fetching guests:", error);
      }
    };

    fetchListGuests();
  }, []);

  const fetchGuests = async (query) => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/onboarding/customers/customer_search?query=${query}`
      );
      if (response.data.length === 0) {
        setErrorMessage("No guest found");
        setGuests([]);
      } else {
        setGuests(response.data);
        setErrorMessage("");
      }
      setShowGuestList(true);
    } catch (error) {
      console.error("Error fetching guests:", error);
    }
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
    setErrorMessage("");
    if (value.length >= 2) {
      fetchGuests(value);
    } else {
      setShowGuestList(false);
      setGuests([]);
    }
  };

  const handleGuestSelection = (guest) => {
    setSelectedGuest(guest);
    onClose(guest);
    setIsOpen(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCreateGuestChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      const phoneValue = value.replace(/\D/g, "");

      if (phoneValue.length > 10) {
        setValidationError("Phone number cannot exceed 10 digits");
      } else {
        setValidationError("");
        setNewGuest((prev) => ({ ...prev, [name]: phoneValue }));
      }
    } else if (name === "email") {
      if (value && !validateEmail(value)) {
        setValidationError("Invalid email format");
      } else {
        setValidationError("");
      }
      setNewGuest((prev) => ({ ...prev, [name]: value }));
    } else {
      setNewGuest((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSaveNewGuest = async () => {
    if (newGuest.phone.trim() === "") {
      setValidationError("Phone number cannot be blank");
      return;
    }

    if (!validateEmail(newGuest.email) && newGuest.email.trim() !== "") {
      setValidationError("Invalid email format");
      return;
    }

    try {
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/onboarding/customers`,
        { customer: newGuest }
      );

      if (response.status === 200) {
        successNotification("Customer Created Successfully!");
        const createdGuest = response.data.data;
        setGuests((prevResults) => [createdGuest, ...prevResults]);
        setSelectedGuest(createdGuest);
        setIsOpen(false);
        onClose(createdGuest);
      }
    } catch (error) {
      const errorMessages = error?.data?.errors || ["An unknown error occurred"];
      setValidationError(errorMessages.join(", "));
      ErrorNotification("Error creating new guest:", errorMessages.join(", "));
    }
  };

  return (
    <>
      {isOpen && (
        <div className="modal-background">
          <div
            className={`select-guest-main ${showGuestList || showCreateForm
                ? "modal-container-large"
                : "modal-container-small"
              }`}
          >
            <div className="select-guest-container-header">
              <h1 className="invoice-select-guest-title">
                {t("Select Guest")}
              </h1>
              <span
                className="invoice-select-guest-close-icon"
                onClick={closeModal}
              >
                X
              </span>
            </div>
            <div className="select-guest-sub-container">
              <div className="invoice-guest-search-container">
                <div className="invoice-guest-sub-content">
                  <div className="invoice-guest-sub">
                    <label className="input-label" htmlFor="searchQuery">
                      {t("Search Guest by Email or Mobile")}
                    </label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        id="searchQuery"
                        placeholder={t("Enter email or mobile number")}
                        value={searchQuery}
                        onFocus={() => {
                          setShowGuestList(false);
                          setErrorMessage("");
                        }}
                        onChange={(e) => handleSearchChange(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="button-guest-group">
                    <button
                      className="resturant-guest-search-button"
                      onClick={() => {
                        setSearchQuery("");
                        setShowGuestList(false);
                        setErrorMessage("");
                      }}
                    >
                      {t("Search Again")}
                    </button>
                    <button
                      className="resturant-guest-search-button"
                      onClick={() => setShowCreateForm((prev) => !prev)}
                    >
                      {showCreateForm
                        ? t("Hide Create Form")
                        : t("Create New Guest")}
                    </button>
                  </div>

                  {errorMessage && (
                    <div className="select-guest-error-msg-grp">
                      <span className="error-message">{errorMessage}</span>
                    </div>
                  )}
                </div>
                {showGuestList && guests.length > 0 && (
                  <>
                    <div>
                      <span className="select-guest-list-heading">
                        {t("List of Guests")}
                      </span>
                      <div className="guest-table-list-info">
                        <table className="guest-list">
                          <thead>
                            <tr>
                              <th>{t("Select")}</th>
                              <th>{t("Name")}</th>
                              <th>{t("Phone")}</th>
                              <th>{t("Email")}</th>
                              <th>{t("Address")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {guests.map((guest) => (
                              <tr key={guest.id}>
                                <td>
                                  <input
                                    type="radio"
                                    name="guest-selection"
                                    checked={selectedGuest === guest}
                                    onChange={() => handleGuestSelection(guest)}
                                  />
                                </td>
                                <td>{guest.name}</td>
                                <td>{guest.phone}</td>
                                <td>{guest.email}</td>
                                <td>{guest.address}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {showCreateForm && (
                <div className="create-guest-form">
                  <div className="guest-title-header-box">
                    <span className="guest-header-title">
                      {t("Create New Guest")}
                    </span>
                  </div>
                  <div className="select-guest-create-form-group">
                    <label>{t("Name")}</label>
                    <input
                      type="text"
                      name="name"
                      value={newGuest.name}
                      onChange={handleCreateGuestChange}
                      className="form-control"
                      maxLength={12}
                    />
                  </div>
                  <div className="select-guest-create-form-group">
                    <label>{t("Phone")}</label>
                    <input
                      type="text"
                      name="phone"
                      value={newGuest.phone}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^[6-9]\d{0,9}$/;

                        if (regex.test(value) || value === "") {
                          handleCreateGuestChange(e);
                        }
                      }}
                      className="form-control"
                      maxLength={10}
                    />

                  </div>
                  {validationError && (
                    <div className="customer-error-msg">
                      <span className="customer-phone-error">
                        {validationError}
                      </span>
                    </div>
                  )}
                  <div className="select-customer-email">
                    <div className="select-guest-create-form-group">
                      <label>{t("Email")}</label>
                      <input
                        type="text"
                        name="email"
                        value={newGuest.email}
                        onChange={handleCreateGuestChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="select-guest-create-form-group">
                    <label>{t("Address")}</label>
                    <input
                      type="text"
                      name="address"
                      value={newGuest.address}
                      onChange={handleCreateGuestChange}
                      className="form-control"
                      maxLength={60}
                    />
                  </div>
                  <div className="select-guest-create-form-group guest-form-save-btn">
                    <button
                      onClick={handleSaveNewGuest}
                      className="select-guest-create-form"
                    >
                      {t("Save")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SelectGuest;
