import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import "./ShowCustomer.css";
import { useTranslation } from "react-i18next";
import Copyrights from "./Copyrights";

const ShowCustomer = () => {
  const location = useLocation();
  const customer = location.state;
  const { t } = useTranslation();

  console.log(customer);

  const [ShowCustomerData, setShowCustomerData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    if (customer) {
      setShowCustomerData({
        first_name: customer?.first_name,
        last_name: customer?.last_name,
        email: customer?.email,
        phone: customer?.phone,
      });
    }
  }, [customer]);


  return (
    <>
      <Header />
      <div className="show-customer-main-container">
        <div className="show-customer-header-container">
          <span className="customer-show-details-title">{t("Customer Details")}</span>
        </div>
        <div className="show-customer-second-container">
          <div className="customer-details-container">
            <div className="customer-detail-row">
              <span className="show-customer-detail-label">
                <strong>{t("FIRST NAME")}:</strong>
              </span>
              <span className="customer-detail-value">
                {ShowCustomerData.first_name}
              </span>
            </div>
            <div className="customer-detail-row">
              <span className="show-customer-detail-label">
                <strong>{t("LAST NAME")}:</strong>
              </span>
              <span className="customer-detail-value">
                {ShowCustomerData.last_name}
              </span>
            </div>
            <div className="customer-detail-row">
              <span className="show-customer-detail-label">
                <strong>{t("EMAIL")}:</strong>
              </span>
              <span className="customer-detail-value">
                {ShowCustomerData.email}
              </span>
            </div>
            <div className="customer-detail-row">
              <span className="show-customer-detail-label">
                <strong>{t("PHONE")}:</strong>
              </span>
              <span className="customer-detail-value">
                {ShowCustomerData.phone}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Copyrights />
    </>
  );
};

export default ShowCustomer;
