import React from "react";
import { useTranslation } from "react-i18next";

function Search({ placeholder }) {
  const { t } = useTranslation();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
      <div>
        <form className="position-relative">
          <input
            type="text"
            className="form-control"
            placeholder={t(placeholder)}
            onKeyDown={handleKeyDown}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-search position-absolute top-50"
            viewBox="0 0 16 16"
            style={{ right: "10px", transform: "translateY(-50%)" }}
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.415l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </form>
      </div>
  );
}

export default Search;
