import React from "react";
import './chart.css'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const Charts = ({ chartData }) => {
  return (
    <>
      <div className="chart-main-class">
        <ResponsiveContainer className="chart-container">
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis dataKey="label" tickFormatter={(value) => `${value.slice(0, 3)}`} />
            <YAxis tick={false} />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke="green" dot={{ stroke: 'green', r: 3 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default Charts;
