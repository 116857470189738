import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import Copyrights from "./Copyrights";
import ErrorNotification from "../Notification/ErrorNotification";
import { sendRequest } from "../ApiRequest";
import { BASE_URL } from "../Url/constants";
import { useTranslation } from "react-i18next";
import "./ShowStaff.css";

const ShowStaff = () => {
  const location = useParams();
  const staff_id = location.id;
  const [showImage, setShowImage] = useState(false);
  const [staffData, setStaffData] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await sendRequest(
          "get",
          `${BASE_URL}/v1/staff_management/staffs/${staff_id}`,
          null
        );
        if (response.status === 200) {
          setStaffData(response.data.data);
        }
      } catch (error) {
        error.data.errors.forEach((error) => ErrorNotification(error));
      }
    };

    fetchData();
  }, [staff_id]);

  const openImagePopup = () => {
    setShowImage(true);
  };

  const closeImagePopup = () => {
    setShowImage(false);
  };

  const getFileType = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    if (extension === "pdf") {
      return "pdf";
    }
    return "image";
  };

  return (
    <>
      <Header />
      <div className="show-staff-main-container">
        <div className="show-staff-header-container">
          <span className="staff-show-details-title">Staff Details</span>
        </div>
        <div className="show-staff-second-container">
          <div className="staff-details-container">
            <div className="staff-detail-row">
              <span className="show-staff-detail-label">
                <strong>{t("First Name")}:</strong>
              </span>
              <span className="staff-detail-value">{staffData?.first_name}</span>
            </div>
            <div className="staff-detail-row">
              <span className="show-staff-detail-label">
                <strong>{t("Last Name")}:</strong>
              </span>
              <span className="staff-detail-value">{staffData?.last_name}</span>
            </div>
            <div className="staff-detail-row">
              <span className="show-staff-detail-label">
                <strong>{t("Email")}:</strong>
              </span>
              <span className="staff-detail-value">{staffData?.email}</span>
            </div>
            <div className="staff-detail-row">
              <span className="show-staff-detail-label">
                <strong>{t("Phone Number")}:</strong>
              </span>
              <span className="staff-detail-value">{staffData?.phone}</span>
            </div>
            <div className="staff-detail-row">
              <span className="show-staff-detail-label">
                <strong>{t("Role")}:</strong>
              </span>
              <span className="staff-detail-value">{staffData?.role_details?.role_name}</span>
            </div>
            <div className="staff-detail-row">
              <span className="show-staff-detail-label">
                <strong>{t("Permanent Address")}:</strong>
              </span>
              <span className="staff-detail-value">
                {staffData?.permanent_address}
              </span>
            </div>
            <div className="staff-detail-row">
              <span className="show-staff-detail-label">
                <strong>{t("Tempory Address")}:</strong>
              </span>
              <span className="staff-detail-value">
                {staffData?.temporary_address}
              </span>
            </div>
            <div className="staff-detail-row">
              <span className="show-staff-detail-label">
                <strong>{t("Monthly Salary")}:</strong>
              </span>
              <span className="staff-detail-value">
                {staffData?.monthly_salary}
              </span>
            </div>
            <div className="staff-detail-row">
              <span className="show-staff-detail-label">
                <strong>{t("Id Proof")}:</strong>
              </span>
              <span className="staff-detail-value">
                {staffData?.id_proof_url && (
                  <div>
                    {getFileType(staffData?.id_proof_url) === "pdf" ? (
                      <div>
                        <embed
                          src={staffData?.id_proof_url}
                          width="100%"
                          height="500"
                          type="application/pdf"
                        />
                        <div>
                          <a
                            href={staffData?.id_proof_url}
                            download="ID_Proof_Document.pdf"
                          >
                            {t("Download")}
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <img
                          src={staffData?.id_proof_url}
                          alt="ID Proof"
                          className="staff-id-proof"
                          onClick={openImagePopup}
                        />
                        <div>
                          <a
                            href={staffData?.id_proof_url}
                            download="ID_Proof_Image"
                          >
                            {t("Download")}
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </span>
              {showImage && (
                <div className="staff-modal-overlay" onClick={closeImagePopup}>
                  <div className="staff-show-modal-content">
                    <span className="staff-modal-close" onClick={closeImagePopup}>
                      &times;
                    </span>
                    <img
                      src={staffData?.id_proof_url}
                      alt="ID Proof"
                      className="staff-modal-image"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Copyrights />
    </>
  );
};

export default ShowStaff;
