import React, { useState } from "react";
import { BASE_URL } from "../Url/constants";
import ErrorNotification from "../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import './CreateCustomer.css';
import { validateEmail, validatePhone } from "../Url/validationUtils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import { sendRequest } from "../ApiRequest";
import Copyrights from "./Copyrights";
import { useTranslation } from "react-i18next";

const UpdateSupplierForm = () => {
  const navigate = useNavigate();
  const { id: supplierId } = useParams();
  const location = useLocation();
  const supplier = location.state;
  const { t } = useTranslation();

  const [updateSupplierData, setUpdateSupplierData] = useState({
    first_name: supplier?.first_name || '',
    last_name: supplier?.last_name || '',
    email: supplier?.email || '',
    phone_number: supplier?.phone_number || '',
    supp_company: supplier?.supp_company || '',
    com_address: supplier?.com_address || ''
  });

  const [submitted, setSubmitted] = useState(false);
  const MAX_LENGTH = 60;

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone_number") {
      const indianPhoneRegex = /^$|^[6-9]\d{0,9}$/;

      if (indianPhoneRegex.test(value)) {
        setUpdateSupplierData({ ...updateSupplierData, [name]: value });
      }
    } else {
      setUpdateSupplierData({ ...updateSupplierData, [name]: value });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    const errors = [];

    if (updateSupplierData.email) {
      const emailValidationResult = validateEmail(updateSupplierData.email);
      if (emailValidationResult) {
        errors.push(emailValidationResult);
      }
    }

    if (!updateSupplierData.phone_number) {
      errors.push("Phone number is required.");
    } else {
      const phoneValidationResult = validatePhone(updateSupplierData.phone_number);
      if (phoneValidationResult) {
        errors.push(phoneValidationResult);
      }
    }

    if (errors.length > 0) {
      errors.forEach(errorMessage => {
        ErrorNotification(errorMessage);
      });
      return;
    }

    await sendRequest('PUT', `${BASE_URL}/v1/onboarding/suppliers/${supplierId}`, updateSupplierData)
      .then(response => {
        if (response.status === 200) {
          navigate('/suppliers');
        }
      })
      .catch(error => {
        ErrorNotification(error.message);
      });
  }

  const handleCancelForm = () => {
    navigate('/suppliers');
  };

  return (
    <>
      <Header />
      <div className="content">
        <div className="col-md-12 margin-btm">
          <h3 className="title">{t("Edit Supplier")}</h3>
        </div>
        <div className="create-company">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="first_name"
                  value={updateSupplierData.first_name}
                  onChange={handleInputChange}
                  placeholder={t("First Name")}
                  maxLength={12}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="last_name"
                  value={updateSupplierData.last_name}
                  onChange={handleInputChange}
                  placeholder={t("Last Name")}
                  maxLength={12}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={updateSupplierData.email}
                  onChange={handleInputChange}
                  placeholder={t("Email")}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control ${submitted && !updateSupplierData.phone_number && "required-field"}`}
                  name="phone_number"
                  value={updateSupplierData.phone_number}
                  onChange={handleInputChange}
                  placeholder={t("Phone No*")}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="supp_company"
                  value={updateSupplierData.supp_company}
                  onChange={handleInputChange}
                  placeholder={t("Company Name")}
                  maxLength={MAX_LENGTH}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="com_address"
                  value={updateSupplierData.com_address}
                  onChange={handleInputChange}
                  placeholder={t("Company Address")}
                  maxLength={MAX_LENGTH}
                />
              </div>
              <div className="col-md-12">
                <button className="btn btn-primary save-btn-1" type="submit">{t("Update")}</button>
                <button className="btn btn-primary" type="button" onClick={handleCancelForm}>{t("Cancel")}</button>
              </div>
            </div>
          </form>
          <NotificationContainer />
        </div>
      </div>
      <Copyrights />
    </>
  );
}

export default UpdateSupplierForm;
