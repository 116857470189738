import React, { useState } from "react";
import "./UnitPopup.css";
import CommonCloseButton from "../../Restaurant/CommonCloseButton/CommonCloseButton";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import { useTranslation } from "react-i18next";


function UnitPopup({onClose, createdUnit}) {
const [unitName, setUnitName] = useState("");
const [error, setError] = useState(null);
const { t} = useTranslation();


const handleSave = async () => {
  try {
  const response = await sendRequest(
    "POST",
    `${BASE_URL}/v1/inventory_managment/units`,
    { unit_name: unitName },
  );
  if (response.status === 200){
    onClose();
    createdUnit();
  }
  } catch (error) {
    console.log("Error fetching item modifier:", error);
    setError(error.data.errors || 'An error occurred');
  }
};

return (
  <>
    <div className='unit-popup-main-container'>
      <div className='unit-popup-title-container'>
        <span className='unit-popup-header-title'>{t("Unit")}</span>
        <span onClick={onClose}>
          <CommonCloseButton />
        </span>
      </div>
      <div className="unit-popup-sub-container">
        <div className="unit-popup-input-box">
          <label className="unit-label-title">{t("Unit Name")}:</label>
          <input
            type="text"
            className="form-control"
            value={unitName}
            onChange={(e) => setUnitName(e.target.value)}
            maxLength={12}
          />
        </div>
        <div>
          {error && <span className='unit-error-message'>{error}</span>}
          </div>
      </div>
      <div className="unit-popup-button-box">
        <span className="unit-popup-buttons">
          <button className="unit-popup-create-btn" onClick={handleSave}>{t("Create")}</button>
          <button className="unit-popup-close-btn" onClick={onClose}>{t("Cancel")}</button>
        </span>
      </div>
    </div>
  </>
);
}

export default UnitPopup;
