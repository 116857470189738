import React, { useState } from "react";
import "./quantityedit.css";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import { getToken } from "../../SecureStorage/Token";
import ErrorNotification from "../../../Notification/ErrorNotification";
import successNotification from "../../../Notification/SuccessNotification";

function QuantityEdit({ item, onClose, setItems }) {
  const [quantity, setQuantity] = useState(item ? item.quantity : 0);
  const token1 = getToken();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (quantity < item?.quantity) {
      ErrorNotification(`Quantity must be greater than ${item.quantity}`);
      return;
    }
    try {
      const updatequantity = { quantity };
      const response = await sendRequest('PATCH', `${BASE_URL}/v1/inventory_managment/items/${item.id}`, updatequantity, token1);
      if (response.status === 200) {

        setItems((prevItems) =>
          prevItems.map((i) =>
            i.id === item.id ? { ...i, quantity: quantity } : i
          )
        );
        successNotification("Quantity updated Successfully");
        onClose();
      }
    } catch (error) {
      console.log("Error updating quantity:", error);
    }
  };

  return (
    <div className="qty-edit-container">
      <div className="edit-qty-modal-content">
      <button className="close-btn" onClick={onClose}>&#10005;</button> 
        <form className="qty-edit-form" onSubmit={handleSubmit}>
          <div className="edit-item-qty-form-group" >
          <label htmlFor="quantity">Quantity:</label>
            <input
              id="quantity"
              className="form-control"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              placeholder="Enter Quantity"
            />
          </div>
          <div className="flexbox">
            <button className="update-btn" type="submit">Update</button>
          </div>
          
        </form>
      </div>
    </div>
  );
}

export default QuantityEdit;
