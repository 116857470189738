import React, { useState, useEffect } from "react";
import { getToken } from "../../SecureStorage/Token";
import "./AddSale.css";
import { sendRequest } from "../../../ApiRequest";
import Header from "../../Header";
import { BASE_URL } from "../../../Url/constants";
import { FaTrash } from "react-icons/fa";
import ErrorNotification from "../../../Notification/ErrorNotification";
import UnitOptions from "../../Purchases/UnitOption";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import GetCustomer from "../../GetCustomer";
import Copyrights from "../../Copyrights";
import { useTranslation } from "react-i18next";

const SuccessNotification = (message) => {
  NotificationManager.success(message);
};

const AddSale = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([
    {
      item_name: "",
      hsn: "",
      quantity: "",
      avail_quantity: "",
      item_code: "",
      tax_rate_id: "None",
      amount: "",
      company_id: 1,
      unit: "",
      purchase_price: "",
      tax_amount: "",
      sale_amount: "",
    },
  ]);
  const [taxTitles, setTaxTitles] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [itemSearchStates, setItemSearchStates] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  // const [customerPhone, setCustomerPhone] = useState("");
  const [totalTax, setTotalTax] = useState(0);
  const [billSettings, setBillSettings] = useState([]);
  const [enableGST, setEnableGST] = useState(false);
  const [subAmount, setSubAmount] = useState(0);
  const [itemSelected, setItemSelected] = useState(items.map(() => false));
  const { t } = useTranslation();

  const token = getToken();

  const units = UnitOptions({ token });

  useEffect(() => {
    const fetchTaxData = async () => {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/taxes/taxes`
        );
        setTaxTitles(response.data.data);
      } catch (error) {
        ErrorNotification("Failed to fetch tax data");
      }
    };

    const fetchGSTSetting = async () => {
      const url = `${BASE_URL}/v1/setting/settings/get_setting_status?name=GST`;
      try {
        const response = await sendRequest("get", url, null, token);
        if (response.status === 200) {
          const gstSetting = response.data;
          if (gstSetting) {
            setEnableGST(gstSetting.status);
          }
        }
      } catch (error) {
        console.error("Error fetching GST setting!", error);
      }
    };

    const fetchSettings = async () => {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/setting/settings/billing_settings`,
          null
        );
        setBillSettings(response.data.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };
    fetchTaxData();
    fetchSettings();
    fetchGSTSetting();
  }, [token]);

  const handleAddItem = () => {
    setSelectedItem(null);
    setItems([
      ...items,
      {
        item_name: null,
        hsn: null,
        quantity: null,
        avail_quantity: null,
        item_code: null,
        tax_rate_id: null,
        amount: null,
        sales_price: null,
        unit_id: null,
        tax_amount: null,
        sale_amount: null,
      },
    ]);
    setItemSelected([...itemSelected, false]);
  };

  const [searchResults, setSearchResults] = useState([]);
  const handleItemSearchDropdown = async (query) => {
    try {
      const response = await sendRequest(
        "get",
        `${BASE_URL}/v1/inventory_managment/items?query=${encodeURIComponent(
          query
        )}&is_normal_billing=true`
      );
      setSearchResults(
        Array.isArray(response.data.data) ? response.data.data : []
      );
      console.log(response.data.data);
    } catch (error) {
      ErrorNotification("Error searching item");
    }
  };

  const calculateAmount = (quantity, purchasePrice) => {
    return quantity * purchasePrice;
  };

  const calculateTaxAmount = (amount, taxAmount) => {
    return amount + taxAmount;
  };

  const handleRemoveItem = (indexToRemove) => {
    setItems((prevItems) =>
      prevItems.filter((_, index) => index !== indexToRemove)
    );
    setItemSelected((prevSelected) =>
      prevSelected.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleQtyChange = (e, index) => {
    if (!itemSelected[index]) {
      ErrorNotification("Please select an item first.");
      return;
    }

    handleItemChange(index, "quantity", parseInt(e.target.value));

    const { name, value } = e.target;
    const newItems = [...items];
    newItems[index][name] = parseInt(value);

    newItems[index]["hasError"] =
      newItems[index].quantity > newItems[index].avail_quantity;

    setItems(newItems);
  };

  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    if (field === "item_name" && value) {
      newItems[index][field] = value;
      setItems(newItems);
      const newItemSelected = [...itemSelected];
      newItemSelected[index] = true;
      setItemSelected(newItemSelected);
      if (newItems[index].quantity) {
        newItems[index].quantity = "";
      }
      if (value.trim() === "") {
        setItemSearchStates((prevStates) => ({
          ...prevStates,
          [index]: {
            ...prevStates[index],
            isSearchingItem: false,
          },
        }));
        return;
      }

      setItemSearchStates((prevStates) => ({
        ...prevStates,
        [index]: {
          ...prevStates[index],
          isSearchingItem: true,
        },
      }));

      const handleDebounce = setTimeout(() => {
        handleItemSearchDropdown(value, index);
      }, 500);

      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      setDebounceTimer(handleDebounce);
    } else if (field === "quantity" || field === "sales_price") {
      newItems[index][field] = value;
      const quantity = newItems[index].quantity || 0;
      const purchasePrice = newItems[index].sale_amount || 0;
      console.log(purchasePrice);
      const taxAmount = newItems[index].tax_amount || 0;
      const amount = calculateAmount(quantity, purchasePrice);
      newItems[index].amount = amount;
      console.log(amount);
      const subTotal = calculateTaxAmount(amount, taxAmount);
      newItems[index].sub_amount = subTotal;
      console.log(subTotal);
      if (selectedItem) {
        newItems[index].sale_amount = selectedItem.sale_amount;
        newItems[index].unit_id = selectedItem.unit_id
          ? units.find((unit) => unit.id === selectedItem.unit_id)?.unit_name
          : "";
        newItems[index].tax_rate_id = selectedItem.tax_rate_id
          ? taxTitles.find((tax) => tax.id === selectedItem.tax_rate_id)?.title
          : "None";
        newItems[index].hsn = selectedItem.hsn;
      }

      setItems(newItems);

      // Calculate totals here
      const totalAmount = newItems.reduce(
        (acc, item) =>
          acc +
          (item.sale_amount * item.quantity + item.tax_amount * item.quantity ||
            0),
        0
      );

      const subAmount = newItems.reduce(
        (acc, item) => acc + (item.sale_amount * item.quantity || 0),
        0
      );
      setSubAmount(subAmount);
      setTotalAmount(totalAmount);

      const totalTax = newItems.reduce((acc, item) => {
        return acc + (item.tax_amount * item.quantity || 0);
      }, 0);
      setTotalTax(totalTax);
    } else {
      newItems[index][field] = value;
      setItems(newItems);
    }
  };

  const handleCustomerChange = (selectedCustomer) => {
    if (selectedCustomer) {
      setSelectedCustomer(selectedCustomer);
      // setCustomerPhone(selectedCustomer.phone || "")
    } else {
      setSelectedCustomer(null);
      // setCustomerPhone("");
    }
  };

  const handleSaveBill = async () => {
    const itemsWithEmptyOrInvalidQuantity = items.filter(
      (item) => !item.quantity || item.quantity <= 0
    );

    if (itemsWithEmptyOrInvalidQuantity.length > 0) {
      const itemNamesList = itemsWithEmptyOrInvalidQuantity
        .map((item) => item.item_name)
        .join(", ");

      if (itemsWithEmptyOrInvalidQuantity.some((item) => !item.quantity)) {
        ErrorNotification(`Please enter a quantity for ${itemNamesList}`);
      } else {
        ErrorNotification(
          `Quantity value must be positive for ${itemNamesList}`
        );
      }
      return;
    }

    try {
      const billItemsData = items.map((item) => ({
        item_id: item.item_id || null,
        quantity: item.quantity,
        company_id: 1,
        // item_total_discount: item?.discount_amount?.toFixed(2),
        item_total_tax: item?.tax_amount?.toFixed(2),
        item_total_amount: item?.sale_amount?.toFixed(2),
      }));

      const total = totalAmount;
      console.log(total);

      const billData = {
        bill: {
          customer_id: selectedCustomer ? selectedCustomer.id : null,
          total_amount: (enableGST
            ? subAmount + totalTax + subAmount * 0.18
            : subAmount + totalTax
          ).toFixed(2),
          total_tax: totalTax,
          total_gst: (enableGST ? subAmount * 0.18 : 0).toFixed(2),
          sub_total: subAmount.toFixed(2),
          serial_number: "",
          status: 3,
          bill_type: 0,
          payment_mode: 1,
          is_igst_enable: isCheckboxEnabled("inter_state_GST"),
          is_gst_enable: enableGST,
          bill_items_attributes: billItemsData,
        },
        is_sale: true,
      };
      const response = await sendRequest(
        "post",
        `${BASE_URL}/v1/billings/bills`,
        billData,
        token
      );

      if (response.status === 200) {
        SuccessNotification("Bill created successfully!");
        navigate("/reports");
      }
    } catch (error) {
      error.data.errors.map((err) => ErrorNotification(err));
    }
  };

  const isCheckboxEnabled = (optionKey) => {
    const option = billSettings.find((setting) => setting.name === optionKey);
    return option && option.billing_setting_config
      ? option.billing_setting_config.status
      : false;
  };

  return (
    <>
      <Header />
      <div className="content">
        <div className="billing-page">
          <h3 className="sale_notice">{t("Add Sale")}</h3>
          <div className="billed-items-list">
            <div className="input-wrap">
              <GetCustomer onCustomerSelect={handleCustomerChange} />
            </div>
            <table>
              <thead className="table-thead">
                <tr className="table-tr">
                  <th className="table-th sno">{t("S No.")}</th>
                  <th className="table-th">{t("Item Name")}</th>
                  <th className="table-th">{t("HSN No.")}</th>
                  <th className="table-th">{t("Quantity")}</th>
                  <th className="table-th">{t("Unit")}</th>
                  <th className="table-th">{t("Price/Unit")} (₹)</th>
                  <th className="table-th">{t("Tax")}</th>
                  <th className="table-th">{t("Amount")} (₹)</th>
                </tr>
              </thead>
              <tbody className="t-body">
                {items.map((item, index) => (
                  <tr key={index} className="table-row">
                    <td>{index + 1}</td>
                    <td className="table_td_border">
                      <input
                        type="text"
                        className="input-select form-control"
                        value={item.item_name}
                        placeholder={t("Add or Search Item")}
                        onChange={(e) =>
                          handleItemChange(index, "item_name", e.target.value)
                        }
                      />
                      {itemSearchStates[index]?.isSearchingItem && (
                        <ul className="item-suggestions">
                          {searchResults.length > 0
                            ? searchResults.map((result) => (
                                <li
                                  key={result.id}
                                  onClick={() => {
                                    const newItems = [...items];
                                    newItems[index].item_name =
                                      result.item_name;
                                    newItems[index].item_id = result.id;
                                    newItems[index].sales_price =
                                      result.sales_price;
                                    newItems[index].hsn = result.hsn;
                                    newItems[index].avail_quantity =
                                      result.quantity;
                                    newItems[index].tax_amount =
                                      result.tax_amount;
                                    const selectedTaxRateId =
                                      result.tax_rate_id;
                                    newItems[index].sale_amount =
                                      result.sale_amount;
                                    const selectedTaxTitle = taxTitles.find(
                                      (tax) => tax.id === selectedTaxRateId
                                    )?.title;
                                    const selectedUnitId = result.unit_id;
                                    const selectedUnitName = units.find(
                                      (unit) => unit.id === selectedUnitId
                                    )?.unit_name;
                                    newItems[index].unit_id =
                                      selectedUnitName || "None";
                                    newItems[index].tax_rate_id =
                                      selectedTaxTitle || "None";
                                    setItems(newItems);
                                    setSelectedItem(null);
                                    setItemSearchStates((prevStates) => ({
                                      ...prevStates,
                                      [index]: {
                                        ...prevStates[index],
                                        isSearchingItem: false,
                                      },
                                    }));
                                    setSearchResults([]);
                                  }}
                                >
                                  {result.item_name}
                                </li>
                              ))
                            : ""}
                        </ul>
                      )}
                    </td>
                    <td className="number-input-wrapper table-td">
                      <input
                        readOnly
                        key={`hsn-${index}`}
                        type="text"
                        value={item.hsn || ""}
                        name="hsn"
                        placeholder={t("Hsn No")}
                        className="qty_input input-select number-input form-control"
                        onChange={(e) =>
                          handleItemChange(index, "hsn", e.target.value)
                        }
                      />
                    </td>
                    <td className="number-input-wrapper table-td">
                      <div
                        style={{ fontWeight: 800 }}
                        className={`inputwrapper ${
                          item.hasError ? "error" : ""
                        }`}
                        data-required={`Avl Qty: ${
                          (item.avail_quantity || 0) ?? 0
                        }`}
                      >
                        <input
                          type="number"
                          value={items.quantity}
                          name="quantity"
                          placeholder={t("Quantity")}
                          className="qty_input input-select form-control"
                          onChange={(e) => handleQtyChange(e, index)}
                          disabled={!itemSelected[index]}
                        />
                      </div>
                    </td>
                    <td className="table-td">
                      <select
                        disabled
                        className="form-control select-disabled"
                        value={item.unit_id || ""}
                        onChange={(e) =>
                          handleItemChange(index, "unit_id", e.target.value)
                        }
                      >
                        <option value="" disabled>
                          Unit
                        </option>
                        {units.map((unit) => (
                          <option key={unit.unit_name} value={unit.unit_name}>
                            {unit.unit_name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="table-td">
                      <input
                        readOnly
                        key={`sales-price-${index}`}
                        type="number"
                        className="form-control"
                        value={item.sales_price || ""}
                        name="sales_price"
                        placeholder={t("Sales Price")}
                        onChange={(e) =>
                          handleItemChange(
                            index,
                            "sales_price",
                            parseFloat(e.target.value)
                          )
                        }
                      />
                    </td>
                    <td className="table-td">
                      <select
                        disabled
                        className="form-control"
                        value={item.tax_rate_id || "None"}
                        onChange={(e) =>
                          handleItemChange(index, "tax_rate_id", e.target.value)
                        }
                      >
                        <option value="None">{t("None")}</option>
                        {taxTitles.map((tax) => (
                          <option key={tax.id} value={tax.title}>
                            {tax.title}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="table-td">
                      <div className="amount_trash">
                        <div className="amount">
                          <input
                            className="input-select form-control"
                            type="number"
                            value={
                              typeof item.amount === "number"
                                ? item.amount.toFixed(2)
                                : ""
                            }
                            disabled
                          />
                        </div>
                        <FaTrash
                          className="trash-icon"
                          onClick={() => handleRemoveItem(index)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="total-section">
              {" "}
              <span onClick={handleAddItem} className="add-btn">
                + {t("Add Item")}
              </span>{" "}
              <span className="cal-total-sale">
                {t("Total")}: ₹ {totalAmount.toFixed(2)} ({t("Tax")}: ₹{" "}
                {totalTax.toFixed(2)})
              </span>
            </div>
            <button className="save-btn" onClick={handleSaveBill}>
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
      <Copyrights />
    </>
  );
};
export default AddSale;
