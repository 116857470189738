import React from 'react';
import TransactionList from './transaction/transactions';
import Header from '../Header';
import Copyrights from '../Copyrights';

const Purchase = ({ startDate, endDate }) => {
  console.log(startDate);
  return (
    <>
      <Header />
      <TransactionList />
      <Copyrights/>
    </>
  );
};

export default Purchase;
