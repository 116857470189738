import React, { useEffect, useState } from "react";
import "./BillingTemplateFour.css";
import { getToken } from "../../SecureStorage/Token";
import { BASE_URL, NO_RECORD_ERROR } from "../../../Url/constants";
import ErrorNotification from "../../../Notification/ErrorNotification";
import { sendRequest } from "../../../ApiRequest";

function ReceiptFour({ template, formData, discount = "0.00", is_print = false }) {
  const token1 = getToken();
  const token = { headers: { Authorization: `${token1}` } };
  const [TemplateData, setTemplateData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [items, setItems] = useState([]);
  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  useEffect(() => {
    getTemplateConfig(template);
    companyDetails();
    fetchItemDetails(formData);
  }, [template, formData]);

  const getTemplateConfig = async (template) => {
    try {
      const url = `${BASE_URL}/v1/template/templates/${template}`;
      const response = await sendRequest('get', url);
      setTemplateData(response.data.data.template_options);
    } catch (error) {
      ErrorNotification(NO_RECORD_ERROR('template'));
    }
  };

  const companyDetails = async () => {
    try {
      const url = `${BASE_URL}/v1/onboarding/companies/current_user_company`;
      const response = await sendRequest('get', url);
      setCompanyData(response.data.data);
    } catch (error) {
      ErrorNotification(NO_RECORD_ERROR('company'));
    }
  };

  const fetchItemDetails = async (formData) => {
    try {
      const fetchedItems = await Promise.all(
        formData?.bill?.bill_items_attributes?.map(async (item) => {
          const url = `${BASE_URL}/v1/inventory_managment/items/${item.item_id}`;
          const response = await sendRequest('get', url);
          return response.data.data;
        })
      );
      setItems(fetchedItems);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  useEffect(() => {
    if (is_print) {
      window.print();
    }
  }, [is_print]);

  const enabledTemplateData = TemplateData
    .filter(option => option.template_config?.is_enable)
    .map(option => ({
      option_name: option.option_name,
      free_text: option.template_config.free_text,
      is_enable: option.template_config.is_enable
    }));

  const formatBillNumber = (number) => {
    return number?.toString().padStart(4, "0");
  };

    const companyAddress = companyData?.branch_info
    ? `${companyData.branch_info.address} ${companyData.branch_info.city}, ${companyData.branch_info.state} (${companyData.branch_info.pin_code})`
    : null;

    return (
      <>
        <div className="fouth-receipt-main-4">
          <div className="fourth-receipt-sub-4">
            <div className="fourth-logo-header-4">
              {(enabledTemplateData.find(option => option.option_name === 'company_logo')?.is_enable || formData?.bill?.print_logo) && (
                <img className="receipt-four-logo-4" src={companyData.logo_url || "/companylogo.jpeg"} alt="logo"/>
              )}
            </div>
            <div className="fourth-receipt-header-4">
              <div className="row">
                {enabledTemplateData.find(option => option.option_name === 'company_name')?.is_enable && (
                  <span className="company-title-4"> {enabledTemplateData.find(option => option.option_name === 'company_name').free_text ||  `${companyData.name}` || "JUSTMINDWORK Private Limited"}</span>
                )}
              </div>
              <hr className="fourth-receipt-line-4"/>
              <div className="invoice_details-4">
                <div className="company-info-4">
                  <div className="company-address-4">
                    <div>
                      {enabledTemplateData.find(option => option.option_name === 'address')?.is_enable && (
                        <div className="receipt-company-address-4">
                          {enabledTemplateData.find(option => option.option_name === 'address').free_text || companyAddress || "111J, Satguru Parinay, Indore"}
                        </div>
                      )}
                      {enabledTemplateData.find(option => option.option_name === 'email')?.is_enable && (
                        <div className="receipt-com-mail-4">
                          Email: {enabledTemplateData.find(option => option.option_name === 'email').free_text || companyData?.user_info?.email}
                        </div>
                      )}
                      {enabledTemplateData.find(option => option.option_name === 'phone_number')?.is_enable && (
                        <div className="receipt-com-mail-4">
                          Phone: {enabledTemplateData.find(option => option.option_name === 'phone_number').free_text || companyData?.user_info?.phone}
                        </div>
                      )}
                      {(enabledTemplateData.find(option => option.option_name === 'gst_number')?.is_enable || formData?.bill?.print_gst_no)&& (
                        <div className="receipt-com-mail-4">Gst Number: {companyData?.gst_number}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="order-details-4">
                  <div>
                    {enabledTemplateData.find(option => option.option_name === "date")?.is_enable && (
                      <div className="receipt-date-4">
                        Date: {currDate}
                      </div>
                    )}
                    {enabledTemplateData.find(option => option.option_name === "time")?.is_enable && (
                      <div className="receipt-time-4">
                        Time: {currTime}
                      </div>
                    )}
                    {enabledTemplateData.find(option => option.option_name === 'insta_id')?.is_enable && (
                      <div className="receipt-com-mail-4">
                        Insta Id: {enabledTemplateData.find(option => option.option_name === 'insta_id').free_text || "@nike"}
                      </div>
                    )}
                    {enabledTemplateData.find(option => option.option_name === 'phone_number')?.is_enable && (
                      <div className="receipt-com-mail-4">
                        Phone: {enabledTemplateData.find(option => option.option_name === 'phone_number').free_text || companyData?.user_info?.phone}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <hr className="fourth-receipt-line-4"/>
              <p className="customer-details-4">Customer Details:-</p>
              <hr className="fourth-receipt-line-4"/>
            </div>
            <table className="receipt-fourth-table-4">
              <thead className="fourth-receipt-table-heading-4">
                <tr>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Qty</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {formData?.bill?.bill_items_attributes?.map((billItem, index) => {
                  const correspondingItem = items.find(item => item.id === billItem.item_id);   
                  if (correspondingItem) {
                    const taxDetails = [];
                    if (correspondingItem?.tax_type === "Individual" && correspondingItem?.tax_info) {
                      taxDetails.push(
                        `${correspondingItem.tax_info.title}: ${correspondingItem.tax_info.tax_value}${correspondingItem.tax_info.tax_type === "fixed" ? "(F)" : "(%)"}`
                      );
                    } else if (correspondingItem?.tax_type === "Group" && correspondingItem?.group_tax_info) {
                      correspondingItem.group_tax_info.forEach(tax => {
                        taxDetails.push(
                          `${tax.title}: ${tax.tax_value}${tax.tax_type === "fixed" ? "(F)" : "(%)"}`
                        );
                      });
                    }
                    return (
                      <tr key={index}>
                        <td>
                          {correspondingItem.item_name}
                          {taxDetails.length > 0 && (
                            <div className="tax-font-size">
                              {taxDetails.join(", ")}
                            </div>
                          )}
                        </td>
                        <td>{correspondingItem.sale_amount.toFixed(2)}</td>
                        <td>{billItem.quantity}</td>
                        <td>{(correspondingItem.sale_amount * billItem.quantity).toFixed(2)}</td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </table>
            <table className="receipt-fourth-summary-table-4">
              <tbody>
                <tr>
                  <td>Total Amount</td>
                  <td>{formData?.bill?.total_amount || "0.00"}</td>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td>{discount || "0.00"}</td>
                </tr>
                <tr>
                  <td>Base Amount</td>
                  <td>{formData?.bill?.sub_total || "0.00"}</td>
                </tr>
                <tr>
                  <td>Tax</td>
                  <td>{formData?.bill?.total_tax || "0.00"}</td>
                </tr>
                {formData?.bill?.is_gst_enable && (
                  formData?.bill?.is_igst_enable ? (
                    <tr>
                      <td>IGST (18%):</td>
                      <td>{formData?.bill?.total_gst || "0.00"}</td>
                    </tr>
                  ) : (
                    <>
                      <tr>
                        <td>CGST (9%):</td>
                        <td>{(formData?.bill?.total_gst / 2).toFixed(2) || "0.00"}</td>
                      </tr>
                      <tr>
                        <td>SGST (9%):</td>
                        <td>{(formData?.bill?.total_gst / 2).toFixed(2) || "0.00"}</td>
                      </tr>
                    </>
                  )
                )}
                <tr>
                  <td>Total Included TAX Amount:</td>
                  <td>{formData?.bill?.total_amount || "0.00"}</td>
                </tr>
              </tbody>
            </table>
            <div className="footer-4">
              <p>THANK YOU FOR BEING A VALUED CUSTOMER</p>
              <div>
                <span className="fourth-receipt-policy-4">
                  {enabledTemplateData.find(option => option.option_name === 'return_policy')?.is_enable && (
                    <span>Return Policy: {enabledTemplateData.find(option => option.option_name === "return_policy")?.free_text || "NO RETURN NO EXCHANGE"} </span>
                  )}
                </span>
                <div>
                  <span className="receipt-fourth-policy-content-4">This is an electronic generated receipt so doesn't require any signature</span>
                </div>
              </div>
            </div>
            <div className="receip-four-wel-msg-4">***Thank you for shopping with us*** </div>
          </div>
        </div>
      </>
    );
  }
  
  export default ReceiptFour;