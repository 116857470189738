import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";
import Header from "../../components/Header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./RestaurantCoupon.css";
import { useTranslation } from "react-i18next";


const RestaurantCoupon = () => {
  const [coupon, setCoupon] = useState({
    name: "",
    description: "",
    value: "",
    coupon_type: "",
    valid_from: "",
    valid_until: "",
    code: "",
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoupon({
      ...coupon,
      [name]: value,
    });
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setCoupon({
      ...coupon,
      valid_from: date,
    });
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setCoupon({
      ...coupon,
      valid_until: date,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/setting/coupons`,
        coupon,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        navigate("/restaurant-coupons");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCancel = () => {
    navigate("/restaurant-coupons");
  };

  return (
    <>
      <Header />
      <div className="content">
        <div className="col-md-12 margin-btm">
          <h3 className="title">{t("Add Coupon")}</h3>
        </div>
        <div className="create-company">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Name")}
                  name="name"
                  value={coupon.name}
                  onChange={handleChange}
                  maxLength={12}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Description")}
                  name="description"
                  value={coupon.description}
                  onChange={handleChange}
                  maxLength={100}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="number"
                  className="form-control"
                  placeholder={t("Value")}
                  name="value"
                  value={coupon.value}
                  onChange={handleChange}
                  maxLength={5}
                  required
                />
              </div>
              <div
                className="col-md-6 position-relative"
                style={{ color: "#bbbaba" }}
              >
                <select
                  style={{ borderWidth: '1px', borderColor: '#bbbaba', marginTop: '0%' }}
                  name="coupon_type"
                  className="form-control select-input"
                  value={coupon.coupon_type}
                  onChange={handleChange}
                  required
                >
                  <option value="">{t("Select Type")}*</option>
                  <option value="percent">{t("Percent")}</option>
                  <option value="fixed">{t("Fixed")}</option>
                </select>
                <span className="coupon-dropdown-icon">
                  <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </div>
              <div className="col-md-6 date-picker-container coupon-valid-from">
                <DatePicker
                  placeholderText={t("Valid From")}
                  selected={startDate}
                  dateFormat="d MMM yyyy"
                  onChange={handleStartDateChange}
                  className="form-control"
                  minDate={new Date()}
                />
                <span className="date-show-picker-1">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="date-picker-icon"
                  />
                </span>
              </div>
              <div className="col-md-6 date-picker-container coupon-valid-until">
                <DatePicker
                  placeholderText={t("Valid Until")}
                  selected={endDate}
                  dateFormat="d MMM yyyy"
                  onChange={handleEndDateChange}
                  className="form-control"
                  minDate={startDate ? startDate : new Date()}
                />
                <span className="date-show-picker-2">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="date-picker-icon"
                  />
                </span>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Code")}
                  name="code"
                  value={coupon.code}
                  onChange={handleChange}
                  maxLength={25}
                  required
                />
              </div>
              <div className="col-md-12">
                <button className="btn btn-primary save-btn-1" type="submit">
                  {t("Save")}
                </button>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleCancel}
                >
                  {t("Cancel")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RestaurantCoupon;
