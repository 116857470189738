import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { removeToken } from "./SecureStorage/Token";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import BackButton from "../BackButton";
import { useTranslation } from "react-i18next";

import "../Header/Header.css";
import { current_user } from "../Authentication/AuthenticationContext";
import { sendRequest } from "../ApiRequest";
import { BASE_URL } from "../Url/constants";


const dropdown = [
  { text: "Profile", link: '/profile' },
  { text: "Sign Out", link: '/' }
];

const Header = ({ handleLogout, setIsDashboardClicked }) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [branchData, setBranchData] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(localStorage.getItem("branchId") || "");

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setUser(currentUser);
      setLoading(false);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/Billing") {
      setActiveTab(t("Billings"));
    } else if (path === "/Reports" || path.includes("/reports")) {
      setActiveTab(t("Reports"));
    } else if (path === "/addPurchase") {
      setActiveTab(t("Purchases"));
    } else if (path === "/addSale") {
      setActiveTab(t("Sales"));
    } else if (path === "/itemtable" || path === "/item" || path.includes("/item-details") || path.includes("/item-update")) {
      setActiveTab(t("Inventory"));
    } else if (path === "/create-customer" || path === "/show-customer") {
      setActiveTab(t("Customers"));
    } else if (path === "/Suppliers" || path === "/add-supplier" || path.includes("/show-supplier") || path.includes("/update-supplier")) {
      setActiveTab(t("Suppliers"));
    } else if (path === "/Staff" || path === "/add-staff" || path.includes("/show-staff") || path.includes("/update-staff")) {
      setActiveTab(t("Staff"));
    } else {
      setActiveTab("");
    }

  }, [location, t]);

  const changeLanguage = (lng) => {
    setLoading(true);
    i18n.changeLanguage(lng, () => {
      setLoading(false);
    });
  };

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      changeLanguage(selectedLanguage);
    }
  }, []);


  useEffect(() => {
    if (user && (user.user_detail.role.name === "admin" || user.user_detail.role.name === "owner")) {
      getBranchList();
    }
  }, [user]);

  const getBranchList = async () => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/onboarding/companies/${user.company.id}/branches`);
    if (response.status === 200) {
      setBranchData(response.data.data);
    }
  }

  const handleBranchChange = (e) => {
    const branchId = e.target.value;
    setSelectedBranch(branchId);
    if (branchId) {
      localStorage.setItem('branchId', branchId);
    } else {
      localStorage.removeItem('branchId');
    }
    window.location.reload();
  };

  if (loading) {
    return <div>{t('Loading...')}</div>;
  }

  const handleSignOut = () => {
    removeToken();
    handleLogout();
    navigate("/");
    setActiveTab("");
  };

  const routesWithoutBackButton = [
    '/dashboard', '/Billing', '/Reports', '/addPurchase', '/addSale', '/itemtable',
    '/create-customer', '/Suppliers', '/Staff', '/reports', '/create-company',
    '/roles-permissions', '/staff', '/suppliers'
  ];

  const shouldShowBackButton = !routesWithoutBackButton.includes(location.pathname);
  const hasFeature = (featureName) => {
    return user && user.features?.some(feature => feature?.name === featureName && feature?.enable);
  };

  return (
    <div className="header-container">
            {setIsDashboardClicked && (
        <div className="search-input-container">
          <input type="text" placeholder={t('Search Transactions')} /> {/* Translate placeholder */}
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
        </div>
      )}
      <div className="header-back-button-margine">
        {shouldShowBackButton && <BackButton />}
      </div>

      {user &&
        (user.user_detail.role.name === "admin" || user.user_detail.role.name === "owner") && (
          <div>
            <select
              className="form-control selected-branch-name"
              value={selectedBranch}
              onChange={handleBranchChange}
            >
              <option value="">{t("All Branch")}</option>
              {branchData.map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.branch_name}
                </option>
              ))}
            </select>
          </div>
        )
      }
      {user &&
        (user.user_detail.role.name === "admin" ||
          user.user_detail.role.name === "owner" ||
          user.user_detail.role.name === "manager") && (
          <div className="button-contents">
            {hasFeature("Restaurant") && (
              <div className={`report-content ${activeTab === t("Restaurant") ? "active" : ""}`}>
                <Link className="header-link" to="/restaurant-order">
                  {t("Restaurant")}
                </Link>
              </div>
            )}
            {hasFeature("Billing") && (
              <div className={`report-content ${activeTab === t("Billings") ? "active" : ""}`}>
                <Link className="header-link" to="/Billing">
                  {t("Billings")}
                </Link>
              </div>
            )}
            <div className={`report-content ${activeTab === t("Reports") ? "active" : ""}`}>
              <Link
                className="header-link"
                to="/Reports"
              >
                {t("Reports")}
              </Link>
            </div>
            <div className={`report-content ${activeTab === t("Purchases") ? "active" : ""}`}>
              <Link
                className="header-link"
                to="/addPurchase"
              >
                {t("Purchases")}
              </Link>
            </div>
            <div className={`report-content ${activeTab === t("Sales") ? "active" : ""}`}>
              <Link
                className="header-link"
                to="/addSale"
              >
                {t("Sales")}
              </Link>
            </div>
            <div className={`report-content ${activeTab === t("Inventory") ? "active" : ""}`}>
              <Link
                className="header-link"
                to="/itemtable"
              >
                {t("Inventory")}
              </Link>
            </div>
            <div className={`report-content ${activeTab === t("Customers") ? "active" : ""}`}>
              <Link
                className="header-link"
                to="/create-customer"
              >
                {t("Customers")}
              </Link>
            </div>
            <div className={`report-content ${activeTab === t("Suppliers") ? "active" : ""}`}>
              <Link
                className="header-link"
                to="/Suppliers"
              >
                {t("Suppliers")}
              </Link>
            </div>
            <div className={`report-content ${activeTab === t("Staff") ? "active" : ""}`}>
              <Link
                className="header-link"
                to="/Staff"
              >
                {t("Staff")}
              </Link>
            </div>
          </div>
        )}

      {user && user.user_detail.role.name === 'cashier' && (
        <div className="button-contents">
          {user.company && user.company.business_type === "restaurant" ? (
            <div className={`report-content ${activeTab === t("Restaurant") ? "active" : ""}`}>
              <Link
                className="header-link"
                to="/restaurant-order"
              >
                {t("Restaurant")}
              </Link>
            </div>
          ) : (
            <div className={`report-content ${activeTab === t("Billings") ? "active" : ""}`}>
              <Link
                className="header-link"
                to="/Billing"
              >
                {t("Billings")}
              </Link>
            </div>
          )}
          <div className={`customers-report-content ${activeTab === t("Customers") ? "active" : ""}`}>
            <Link
              className="header-link"
              to="/create-customer"
            >
              {t("Customers")}
            </Link>
          </div>
          <div className={`suppliers-report-content ${activeTab === t("Suppliers") ? "active" : ""}`}>
            <Link
              className="header-link"
              to="/Suppliers"
            >
              {t("Suppliers")}
            </Link>
          </div>
        </div>
      )}

      {user && user.user_detail.role.name === 'staff' && (
        <div className="button-contents">
          {user.company && user.company.business_type === "billing" ? (
            <div className={`report-content ${activeTab === t("Billing") ? "active" : ""}`}>
              <Link
                className="header-link"
                to="/Billing"
              >
                {t("Billing")}
              </Link>
            </div>
          ) : (
            <div className={`report-content ${activeTab === t("Billings") ? "active" : ""}`}>
              <Link
                className="header-link"
                to="/Billing"
              >
                {t("Billings")}
              </Link>
            </div>
          )}
          <div className={`customers-report-content ${activeTab === t("Customers") ? "active" : ""}`}>
            <Link
              className="header-link"
              to="/create-customer"
            >
              {t("Customers")}
            </Link>
          </div>
          <div className={`suppliers-report-content ${activeTab === t("Suppliers") ? "active" : ""}`}>
            <Link
              className="header-link"
              to="/Suppliers"
            >
              {t("Suppliers")}
            </Link>
          </div>
        </div>
      )}

      <div className="dropdown-box">
        <BsThreeDotsVertical onClick={() => setOpen(!open)} />
        {open && (
          <div className="dropdown-menus">
            <ul>
              {dropdown.map((menu, index) => (
                <Link to={menu.link} onClick={menu.text === 'Sign Out' ? handleSignOut : () => { }}>
                  <li key={index}>
                    {t(menu.text)} {/* Translate menu items */}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* <div className="language-switcher">
        <button onClick={() => changeLanguage('en')}>English</button>
        <button onClick={() => changeLanguage('hi')}>Hindi</button>
      </div> */}
    </div>
  );
};

export default Header;
