import React from "react";
import "./receiptthird.css";

function ReceiptThird({ enabledTemplateData }) {
  const getOption = (optionName) => {
    return enabledTemplateData.find((opt) => opt.option_name === optionName) || {};
  };

  const getAlignment = (optionName) => {
    const option = getOption(optionName);
    return option.alignment || 'center'; // Default to 'left' if alignment is not defined
  };

  return (
    <>
      <div className="third-receipt-main">
        <div className="third-receipt-sub">
          <div className="third-receipt-header">
            {getOption('company_logo').is_enable && (
              <img
                className="third-receipt-logo"
                src="/companylogo.jpeg"
                alt="logo"
              />
            )}
          </div>
          <div className="third-receipt-date-time mt-4">
            <div className="third-receipt-date">
              {getOption('date').is_enable && "Date: 02/05/2024"}
            </div>
            <div className="third-receipt">
              {getOption('invoice_number').is_enable && `Receipt: #L21387`}
            </div>
          </div>
          <hr />
          <div className="row">
            {getOption('company_name').is_enable && (
              <span 
                className="third-receipt-company"
                style={{ textAlign: getAlignment('company_name') }}
              >
                {getOption('company_name').free_text || "JUSTMINDWORK Private Limited"}
              </span>
            )}
          </div>
          <div className="row">
            {enabledTemplateData.find(
              (option) => option.option_name === "address"
            )?.is_enable && (
                <span>
                  {enabledTemplateData.find(
                    (option) => option.option_name === "address"
                  )?.free_text || "111J, Satguru Parinay, Indore"}
                </span>
              )}
            {enabledTemplateData.find(
              (option) => option.option_name === "email"
            )?.is_enable && (
                <span>
                  Email:{" "}
                  {enabledTemplateData.find(
                    (option) => option.option_name === "email"
                  )?.free_text || "justmindwork@gmail.com"}
                </span>
              )}

            {enabledTemplateData.find(option => option.option_name === 'insta_id')?.is_enable && (
              <span>
                Insta Id: {enabledTemplateData.find(option => option.option_name === 'insta_id').free_text || `@nike`}
              </span>
            )}

            {enabledTemplateData.find(
              (option) => option.option_name === "gst_number"
            )?.is_enable && <span>GstNumber: JDGT1234567876</span>}
          </div>
          <hr />

          <div className="third-receipt-table-grp">
            <table className="receipt-third-table">
              <thead className="third-receipt-table-heading">
                <tr>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Qty</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>colgate paste</td>
                  <td>1</td>
                  <td>40</td>
                  <td>40</td>
                </tr>
                <tr>
                  <td>colgate paste</td>
                  <td>1</td>
                  <td>40</td>
                  <td>40</td>
                </tr>
                <tr>
                  <td>colgate paste</td>
                  <td>1</td>
                  <td>40</td>
                  <td>40</td>
                </tr>
                <tr>
                  <td>colgate paste</td>
                  <td>1</td>
                  <td>40</td>
                  <td>40</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            {getOption('qr_code').is_enable && (
              <div className="qr-code-container">
                <img
                  src="/qr-code-placeholder.png"
                  alt="QR Code"
                  className="qr-code-image"
                />
              </div>
            )}
            {getOption('return_policy').is_enable && (
              <span 
                className="third-receipt-policy"
                style={{ textAlign: getAlignment('return_policy') }}
              >
                Return Policy: {getOption('return_policy').free_text || "Non returnable items"}
              </span>
            )}
            <div>
              <span className="receipt-third-policy-content">
                This is an electronic generated receipt so doesn't require any
                signature
              </span>
            </div>
          </div>
          <div>
            {getOption('recycle_offer').is_enable && (
              <div>
                <div>
                  <span className="third-receipt-offer">Recycle Offer:</span>
                </div>
                <div>
                  <span 
                    className="receipt-third-offer-content"
                    style={{ textAlign: getAlignment('recycle_offer') }}
                  >
                    {getOption('recycle_offer').free_text ||
                      "Recycle shopping bag & cash for each bag on discount on your purchase"}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="receipt-third-content">
            {getOption('phone_number').is_enable && (
              <span style={{ textAlign: getAlignment('phone_number') }}>
                CALL FOR HOME DELIVERY {getOption('phone_number').free_text || "+917849201710"}
              </span>
            )}
          </div>
          <span className="receip-third-message">
            ***Thank you for shopping with us***
          </span>
        </div>
      </div>
    </>
  );
}

export default ReceiptThird;