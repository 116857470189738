import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Copyrights from "../components/Copyrights";
import CustomTable from "../components/Common/CustomTable";
import ConfirmPopup from "../components/ConfirmPop";
import Search from "../components/Search/Search";
import { useNavigate } from "react-router-dom";
import { BsEye, BsFillTrashFill } from "react-icons/bs";
import { BASE_URL, NO_RECORD_ERROR, WENTWRONG } from "../Url/constants";
import { getToken } from "../components/SecureStorage/Token";
import ErrorNotification from "../Notification/ErrorNotification";
import EntriesPerPageDropdown from "../components/Common/EntriesPerPageDropdown";
import PaginationControls from "../components/Common/PaginationControls";
import { useTranslation } from "react-i18next";
import { sendRequest } from "../ApiRequest";
import successNotification from "../Notification/SuccessNotification";


function CreateCustomer() {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showDeletePop, setShowDeletePop] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setrecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const startIndex = (currentPage - 1) * recordPerPage + 1;
  const endIndex = Math.min(currentPage * recordPerPage, totalRecords);
  const { t } = useTranslation();

  const token1 = getToken();
  const token = { headers: { Authorization: `${token1}` } };

  useEffect(() => {
    fetchCustomers();
  }, [recordPerPage, searchQuery]);

  const fetchCustomers = async (pageNumber = 1) => {
    try {
      let request_url = `${BASE_URL}/v1/onboarding/customers?page=${pageNumber}&per_page=${recordPerPage}`;
      // const response = await sendRequest('GET', `${BASE_URL}/v1/onboarding/customers`, token1);
      if (searchQuery) {
        request_url += `&query=${searchQuery}`;
      }
      const response = await sendRequest('GET', request_url);
      if (response.status === 200) {
        setCustomers(response.data.data);
        setTotalPages(response.data.total_pages);
        setTotalRecords(response.data.total_count);
      }
    } catch (error) {
      const msg = NO_RECORD_ERROR('customer');
      ErrorNotification(msg);
    }
  };

  const handleShowButtonClick = (customer) => {
    navigate('/show-customer', { state: customer });
  };

  const handleDeleteButtonClick = (customer) => {
    setSelectedCustomer(customer);
    setShowDeletePop(true);
  };

  const handleConfirmDelete = async () => {
    try {
      
      if (selectedCustomer) {
        // await sendRequest('DELETE', `${BASE_URL}/v1/onboarding/customers/${selectedCustomer.id}`, token1);
        const url = `${BASE_URL}/v1/onboarding/customers/${selectedCustomer.id}`;
        const response = await sendRequest('DELETE', url)
        if (response.status === 200) {
          const updatedCustomers = customers?.filter(cust => cust.id !== selectedCustomer.id);
          setCustomers(updatedCustomers);
          setShowDeletePop(false);
          fetchCustomers(currentPage);
          successNotification("Customer Deleted Successfully!");
        } else {
          ErrorNotification(WENTWRONG);
        }
      }
    } catch (error) {
      ErrorNotification(WENTWRONG);
      
    }
  };

  const handleCancelDelete = () => {
    setSelectedCustomer(null);
    setShowDeletePop(false);
  };

  const handleSearch = (query) => {
    if (query.length >= 2) {
      setSearchQuery(query);
    } else {
      setSearchQuery('');
      setCurrentPage(1);
    }
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setrecordPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = async (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      await fetchCustomers(pageNumber);
    }
  };

  return (
    <>
      <Header />
      <div className="content customer-create-tab">
        <div className="button-container">
          {/* <Link to="/add-customer" className="btn btn-primary">
            + Add Customer
          </Link> */}
        </div>
        {/* {customers.length > 0 && ( */}
        <>
          <div className="row">
            <div className="col-md-12"><h3 className="title">{t("Customers List")} ({totalRecords})</h3></div>
            <div className="pagination-dropdown">
              <div className="pagination-controls">
                <EntriesPerPageDropdown recordPerPage={recordPerPage} handleRecordPerPageChange={handleRecordPerPageChange} />
              </div>
              <div className="text-right" value={searchQuery} onChange={e => handleSearch(e.target.value)}><Search placeholder={t("Search Customers ...")} /></div>
            </div>
          </div>
          <div className="table-view suppliers-table cust-common-table">
            <CustomTable
              col_names={['S. No.', 'First Name', 'Last Name', 'Email ID', 'Phone No', 'Actions']}
              data={customers.map((customer, index) => ({
                'S. No.': startIndex + index,
                'First Name': customer.first_name,
                'Last Name': customer.last_name,
                'Email ID': <a href={`mailto:${customer.email}`}>{customer.email}</a>,
                'Phone No': customer.phone,
                'Actions': (
                  <div className="col-md-12">
                    <span>
                      {/* <a href="S. No." className="show-icon" onClick={() => handleShowButtonClick(customer)}><BsEye /></a> */}
                      <a className="show-icon" onClick={() => handleShowButtonClick(customer)}><BsEye /></a>
                      <a className="deletebtn delete-icon" onClick={() => handleDeleteButtonClick(customer)}>
                        <BsFillTrashFill />
                      </a>
                    </span>
                  </div>
                ),
              }))}
            />
            {customers.length === 0 && searchQuery.length > 1 && (
              <div className="no-report-data-message">{t("No match found")}</div>
            )}
            {customers.length === 0 && searchQuery.length === 0 && (
              <div className="no-report-data-message">{t("No records found")}</div>
            )}
          </div>
          {totalRecords >= recordPerPage && (
            <div>
              <div className="report-pagination-info">
                <div className="report-pagination-container">
                  <PaginationControls
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                    totalRecords={totalRecords}
                    startIndex={startIndex}
                    endIndex={endIndex}
                  />
                </div>
              </div>
            </div>
          )}
        </>
        {/* )} */}
      </div>
      <Copyrights />
      {showDeletePop && (
        <ConfirmPopup
          title="Confirm Delete"
          message={`Are you sure you want to delete ?`}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </>
  );
}

export default CreateCustomer;

