import React, { useEffect, useState } from "react";
import "./DeliveryOrder.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import { faFileInvoice, faIndianRupeeSign, faTachometerAlt, faTicket } from "@fortawesome/free-solid-svg-icons";
import PaymentModal from "../PaymentModel";
import { useTranslation } from "react-i18next";

function DeliveryOrder() {
  const navigate = useNavigate();
  const [isPaymentPopup, setPaymentPopup] = useState(false)
  const [orderData, setOrderData] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const { t } = useTranslation();

  const handleNewOrder = () => {
    const orderType = 'home_delivery';
    navigate("/kot-create", { state: { orderType } });
  };

  const ReturnToRestaurant = () => {
    navigate("/restaurant-order");
  };

  // const handleDeliveryPayment = () => {
  //   const orderType = 'home_delivery';
  //   navigate("/bill-item",  { state: { orderType } });
  // }

  const handlePaymentSettelmentPopup = (order) => {
    setOrderDetail(order);
    setPaymentPopup(true);
  }

  const handleClosePaymentPopup = () => {
    setPaymentPopup(false);
    getOrderData();
  }

  useEffect(() => {
    getOrderData();
  }, []);

  const getOrderData = async () => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/restaurant/orders/orders/get_home_delivery_order`);
    if (response.status === 200) {
      const res = response.data.data;
      console.log(res);
      setOrderData(res);
    }
  };

  const handlecreateInvoice = (order) => {
    navigate('/bill-item', { state: { order } });
  };

  return (
    <>
      <div className="delivery-order-main-container">
        <div className="order-delivery-header-main">
          <div className="order-delivery-header-right">
            <button className="back-button" onClick={ReturnToRestaurant}></button>
            <div className="delivery-buttons">
              <button className="delivery-right-hand-button" onClick={handleNewOrder}>
                {t("New Order")}
              </button>
              <button className="delivery-right-hand-button" onClick={ReturnToRestaurant}>
                {t("Table KOT")}
              </button>
            </div>
          </div>
        </div>
        <div className="order-delivery-header-left">
          <span className="order-delivery-title">{t("Running Orders")}</span>
        </div>
        <hr className="delivery-order-hr" />
        <div className="order-delivery-cards-container">
          {orderData.length > 0 ? (
            orderData.map((item) => (
              <div key={item.id} className={`card order-delivery-card ${item.status === 'draft' ? 'order-status-draft' : item.status === 'pending' ? 'order-status-pending' : ''
                }`}>
                <div className='order-delivery-card-alignment-main'>
                  <span className='delivery-card-title-name'>{item.id}</span>
                  <span className='delivery-card-captain-name'>{item.customer?.first_name ?? ''} {item.customer?.last_name ?? ''}</span>
                </div>
                <span className="delivery-item-order-amount">{item.sub_total}</span>
                <div className="card-footer-icons">
                  <FontAwesomeIcon className="delivery-icon-pointer" title="Add Kot" icon={faTicket} />
                  <FontAwesomeIcon className="delivery-icon-pointer" title="Invoice" icon={faFileInvoice} onClick={() => handlecreateInvoice(item)} />
                  <FontAwesomeIcon className="delivery-icon-pointer" title="Settlement" icon={faIndianRupeeSign} onClick={() => handlePaymentSettelmentPopup(item)} />
                  <FontAwesomeIcon className="delivery-icon-pointer" title="Kot Details" icon={faTachometerAlt} />
                </div>
              </div>
            ))
          ) : (
            <span className="delivery-order-not-found">{t("No Orders Found")}</span>
          )}
        </div>
      </div>
      {isPaymentPopup &&
        <PaymentModal isOpen={isPaymentPopup} onRequestClose={handleClosePaymentPopup} UpdateInvoiceOrder={orderDetail} />
      }
    </>
  );
}

export default DeliveryOrder;
