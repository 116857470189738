import React, { useState, useEffect } from "react";
import "./KotTransaction.css";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import Search from "../../Search/Search";
import { BsEye } from "react-icons/bs";
import KotTransactionOrder from "./KotTransactionOrder";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import PaginationControls from "../../Common/PaginationControls";
import Copyrights from "../../Copyrights";
import { useTranslation } from "react-i18next";
import BackButton from "../../../BackButton";

function KotTransaction() {
  const [orderTickets, setOrderTickets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { t} = useTranslation();


  const fetchOrderTickets = async (pageNumber = 1, perPage = 10, query = "") => {
    try {
      let request_url = `${BASE_URL}/v1/restaurant/orders/orders/get_kot_tickets_details?page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setOrderTickets(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.log("Error fetching order tickets:", error);
    }
  };

  useEffect(() => {
    fetchOrderTickets(currentPage, recordsPerPage, searchQuery);
  }, [currentPage, recordsPerPage, searchQuery]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (query) => {
    if (query.length >= 2) {
      setSearchQuery(query);
    } else {
      setSearchQuery('');
      setCurrentPage(1);
    }
  };

  const handleOrderPopup = (order) => {
    setSelectedOrder(order);
  };

  const closeSelectOrderPopup = () => {
    setSelectedOrder(null);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  return (
    <>
      <div className="kot-transaction-main">
        <div className="kot-transaction-header-container">
          <BackButton />
          <span className="kot-transaction-title-name">{t("KOT TRANSACTION")}</span>
        </div>
        <div className="row mt-4">
          <div className="kot-transaction-container">
            <span className="kot-transaction-title">{t("Order Transaction")} ({totalRecords})</span>
          </div>
        </div>
        <div className="pagination-dropdown">
          <EntriesPerPageDropdown
            recordPerPage={recordsPerPage}
            handleRecordPerPageChange={handleRecordPerPageChange}
          />
          <span className="kot-transaction-search">
            <div className="text-right" value={searchQuery} onChange={e => handleSearch(e.target.value)}><Search placeholder={"Search Order ..."} /></div>
          </span>
        </div>
        <div className="transaction-kot-table-main">
          <table>
            <thead>
              <tr>
                <th>{t("Invoice Number")}</th>
                <th>{t("Order Type")}</th>
                <th>{t("Status")}</th>
                <th>{t("Total Amount")}</th>
                <th>{t("Order Date")}</th>
                <th>{t("Order Item List")}</th>
              </tr>
            </thead>
            <tbody>
              {orderTickets.map(ticket => (
                <tr key={ticket.id}>
                  <td>{ticket.bill_number}</td>
                  <td>{ticket.order_type ? ticket?.order_type?.replace(/_/g, ' ') : ''}</td>
                  <td>{ticket?.status}</td>
                  <td>{ticket?.total_amount}</td>
                  <td>{new Date(ticket.created_at).toLocaleString()}</td>
                  <td><BsEye className="order-transaction-show-icon" onClick={() => handleOrderPopup(ticket)} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          {orderTickets.length === 0 && (
            <div className="no-report-data-message">
              {t("No records found")}
            </div>
          )}
        </div>
        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}
      </div>
      {selectedOrder && (
        <div className="kot-transaction-popup-overlay">
          <KotTransactionOrder order={selectedOrder} onClose={closeSelectOrderPopup} />
        </div>
      )}
      <Copyrights />
    </>
  );
}

export default KotTransaction;
