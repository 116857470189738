import axios from 'axios';
import { getToken, removeToken } from './components/SecureStorage/Token';

export const sendRequest = async (method, url, data, options = {}) => {
  const token = getToken();
  const branchId = localStorage.getItem('branchId');
  
  const config = {
    ...options,
    headers: {
      ...(options.headers || {}),
      Authorization: token ? `${token}` : ''
    },
    params: {
      ...(options.params || {}),
      branch_id: branchId,
      devise_type: 'web'
    },
  };

  try {
    const response = await axios({
      method,
      url,
      data,
      ...config,
    });
    return response;
  } catch (error) {
    if (error.response?.data?.errors?.[0] === 'Signature has expired' || error.response?.data?.errors === 'Unauthorized user!') {
      removeToken();
      window.location.replace('/');
    }
    throw error.response || error.message;
  }
};
