import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { sendRequest } from '../../ApiRequest';
import { BASE_URL } from '../../Url/constants';
import { removeToken } from '../SecureStorage/Token';

export const LogOut = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const url = `${BASE_URL}/v1/auth/logout?devise_type=web`;
    const response = await sendRequest("delete", url, { id: location.state.user }, '');
    if (response.status === 204) {
      // Redirect to login or home after logout
      removeToken();
      navigate("/");
    }
  };

  return (
    <div className="login-body">
      <div className="login-box">
        <div className="logo-content">
          <img src={require("../../Assets/Images/logo.jpg")} alt="Billeasy Logo" className="company-unique-logo-image" style={{ width: '85%' }} />
        </div>
        <div className="card-body">
          <p className="card-text">It seems you have active session on another browsers, do you want to log out?</p>
          <button className="btn btn-primary mb-2" onClick={handleLogout}>Yes, Log me out</button>
        </div>
      </div>
    </div>
  );
}
