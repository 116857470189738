import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ShowSupplier.css";
import Header from "./Header";
import { BASE_URL } from "../Url/constants";
import { sendRequest } from "../ApiRequest";
import ErrorNotification from "../Notification/ErrorNotification";
import Copyrights from "./Copyrights";
import { useTranslation } from "react-i18next";

const ShowSupplier = () => {
  const [supplier, setSupplier] = useState("");
  const supplier1 = useParams();
  const { t } = useTranslation();
  const supplier_id = supplier1.id;

  useEffect(() => {
    const show_supplier = async () => {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/suppliers/${supplier_id}`
        );

        if (response.status === 200) {
          setSupplier(response.data.data);
        }
      } catch (error) {
        ErrorNotification("Supplier not found");
        console.log(error.message);
      }
    };
    show_supplier();
  }, []);

  return (
    <>
      <Header />
      <div className="show-supplier-main-container">
        <div className="show-supplier-header-container">
          <span className="supplier-show-details-title">Supplier Details</span>
        </div>
        <div className="show-supplier-second-container">
          <div className="supplier-details-container">
            <div className="supplier-details-row">
              <span className="show-supplier-details-label">
                <strong>{t("FIRST NAME")}:</strong>
              </span>
              <span className="supplier-details-value">
                {supplier.first_name}
              </span>
            </div>
            <div className="supplier-details-row">
              <span className="show-supplier-details-label">
                <strong>{t("LAST NAME")}:</strong>
              </span>
              <span className="supplier-details-value">
                {supplier.last_name}
              </span>
            </div>
            <div className="supplier-details-row">
              <span className="show-supplier-details-label">
                <strong>{t("EMAIL")}:</strong>
              </span>
              <span className="supplier-details-value">{supplier.email}</span>
            </div>
            <div className="supplier-details-row">
              <span className="show-supplier-details-label">
                <strong>{t("PHONE")}:</strong>
              </span>
              <span className="supplier-details-value">
                {supplier.phone_number}
              </span>
            </div>
            <div className="supplier-details-row">
              <span className="show-supplier-details-label">
                <strong>{t("SUPPLIER COMPANY")}:</strong>
              </span>
              <span className="supplier-details-value">
                {supplier.supp_company}
              </span>
            </div>
            <div className="supplier-details-row">
              <span className="show-supplier-details-label">
                <strong>{t("COMPANY ADDRESS")}:</strong>
              </span>
              <span className="supplier-details-value">
                {supplier.com_address}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Copyrights />
    </>
  );
};

export default ShowSupplier;
