import React from "react";
import Header from "../../components/Header";
import { BsFileEarmarkText } from "react-icons/bs";
import Copyrights from "../../components/Copyrights";
function AddItems() {
    return (
        <>
            <Header />
            <div className="add-items">
                <div className="content">
                    <div className="row">
                        <div className="col-md-11"><input type="file" className="form-control upload-img" placeholder="Upload Logo" /><label className="note">Upload Item Image</label></div>
                        <div className="col-md-1 text-right">
                            <button className="btn btn-primary">Upload</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <select className="form-select" aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <input type="text" className="form-control" placeholder="Item Name" />
                        </div>
                        <div className="col-md-3">
                            <input type="text" className="form-control" placeholder="Assign Item Name" />
                        </div>
                        <div className="col-md-3">
                            <input type="file" className="form-control upload-img" placeholder="Upload Item" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-items">
                <div className="content">
                    <h3 className="title"><BsFileEarmarkText /> Add Pricing</h3>
                    <div className="row">
                        <div className="col-md-4">
                            <input type="text" className="form-control" placeholder="Sale Price" />
                        </div>
                        <div className="col-md-4">
                            <input type="text" className="form-control" placeholder="Purchase Price" />
                        </div>
                        <div className="col-md-4">
                            <input type="text" className="form-control" placeholder="Wholesale Price" />
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12 discount-group">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="fixdiscount" checked />
                                    <label className="form-check-label" for="fixdiscount">
                                        Fix Discount
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="discount-percent" />
                                    <label className="form-check-label" for="discount-percent">
                                     Discount in percent (%)
                                    </label>
                                </div>
                            </div>
                            <input type="text" className="form-control" placeholder="Enter Fix Discount" />
                        </div>

                        <div className="col-md-6">
                        <div className="col-md-12 discount-group">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="individual-tax" checked/>
                                    <label className="form-check-label" for="individual-tax">
                                        Individual Tax
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="group-tax" />
                                    <label className="form-check-label" for="group-tax">
                                     Group Tax
                                    </label>
                                </div>
                            </div>
                            <input type="text" className="form-control" placeholder="Add Tax" />
                        </div>
                        <div className="col-md-4">
                            <input type="text" className="form-control" placeholder="Min Quantity" />
                        </div>
                        <div className="col-md-4">
                            <input type="text" className="form-control" placeholder="Max Quantity" />
                        </div>

                        <div className="col-md-12 text-right">
                            <button className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <Copyrights />
        </>
    );
}

export default AddItems;