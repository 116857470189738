import "./App.css";
import SideBar from "./components/Sidebar/SideBar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import AttendanceTracking from "./pages/AttendanceTracking";
import RolesPermissions from "./pages/Settings/RolesAndPermission/RolesPermissions";
import SalaryManagement from "./pages/SalaryManagement";
import Setting from "./pages/Setting";
import TaxBilling from "./pages/Settings/TaxBilling";
import Billing from "./pages/Billing";
import CreateCompany from "./pages/CreateCompany";
import AddItems from "./pages/Settings/AddItems";
import ItemSetting from "./pages/Settings/ItemSetting";
import Login from "../src/components/LoginSignup/Login";
import LoginOtp from "../src/components/LoginSignup/LoginOtp";
import { LogOut } from "./components/LoginSignup/LogOut";
import { getToken, removeToken } from "./components/SecureStorage/Token";
import { useState } from "react";
import SaleReport from "./pages/Reports";
import Reports from "./pages/Reports";
import Purchase from "./components/Purchases/Purchase";
import Item from "./components/Items/Item";
import ItemTable from "./components/Items/ItemTable";
import Suppliers from "./pages/Suppliers";
import Staff from "./pages/Staff";
import CreateCustomer from "./pages/CreateCustomer";
import Showcompany from "./components/ShowCompany";
import Invoice from "./pages/Invoice";
import CreateCollapse from "./components/CreateCollpase";
import AddPurchase from "./components/Purchases/addpurchase/AddPurchase";
import AddSale from "./components/Sales/addSale/AddSale";
import TransactionList from "./components/Purchases/transaction/transactions";
import CustomerCollapse from "./components/CustomerCollapse";
import UpdateCustomerForm from "./components/UpdateCustomerCollapse";
import StaffCollapse from "./components/StaffCollapse";
import UpdateSupplierForm from "./components/UpdateSupplierCollapse";
import UpdateStaffForm from "./components/UpdateStaffCollapse";
import Search from "./components/Search/Search";
import Template from "./components/Templates/Template";
import AddMoreTemplate from "./components/Templates/AddMoreTemplate";
import TemplateLayout1 from "./components/Templates/TemplateLayout1/TemplateLayout1";
import TemplateLayout2 from "./components/Templates/TemplateLayout2/TemplateLayout2";
import TemplateLayout3 from "./components/Templates/TemplateLayout3/TemplateLayout3";
import TemplateSetting from "./pages/Settings/TemplateSetting";
import TemplateSettingView from "./components/TemplateSettings/TemplateSettingView";
import ShowCustomer from "./components/ShowCustomer";
import ShowSupplier from "./components/ShowSupplier";
import ShowStaff from "./components/ShowStaff";
import TaxManagement from "./components/TaxManagement/ManagingTax";

import ReceiptFirst from "./components/Receipt/ReceiptFirst/ReceiptFirst";
import ReceiptSecond from "./components/Receipt/ReceiptSecond/ReceiptSecond";
import ReceiptThird from "./components/Receipt/ReceiptThird/ReceiptThird";
import ReceiptFour from "./components/Receipt/ReceiptFour/ReceiptFour";
import NotificationWrapper from "./NotificationWrapper";
// import ItemDetails from "./components/Items/ItemDetails";
import ItemUpdate from "./components/Items/ItemUpdate";
import TableReservation from "./components/Restaurant/PaxCaptain/TableReservation";
import Order from "./components/Restaurant/Order";
import VoidKot from "./components/Restaurant/VoidKot/VoidKot";
import VoidRemark from "./components/Restaurant/VoidKot/VoidRemark/VoidRemark";
import ItemModifier from "./components/Restaurant/ItemModifier/ItemModifier";
import SelectGuest from "./components/Restaurant/SelectGuest/SelectGuest";
import RestourentBillings from "./pages/Report/RestourentBillings";
import ItemInvoice from "./components/Restaurant/ItemInvoice/ItemInvoice";
import ParcelCharges from "./components/Restaurant/ParcelCharges/ParcelCharges";
import ItemDiscountInvoice from "./components/Restaurant/ItemDiscountInvoice/ItemDiscountInvoice";
import RestaurantSettings from "./pages/Settings/RestaurantSettings";
import TransferTable from "./components/Restaurant/TransferTable";
import MergeTable from "./components/Restaurant/mergeTable/MergeTable";
import Kitchenceff from "./components/Kitchendisplay/Kitchenceff";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import enTranslation from "./locales/en.json";
import hiTranslation from "./locales/hi.json";
import DeliveryOrder from "./components/Restaurant/HomeDelivery/DeliveryOrder";
import DeliveryPerson from "./components/Restaurant/HomeDelivery/DeliveryPerson";
import RestaurantCoupon from "./pages/Settings/RestaurantCoupon";
import RestaurantCoupons from "./pages/Settings/RestaurantCoupons";
import ExpanseBill from "./components/Expanse/ExpanseBill";
import ExpanseData from "./components/Expanse/Expansedata";
import KotTransaction from "./components/Restaurant/KotTransaction/KotTransaction";
import { TransliterationProvider } from "./TransliterationContext";
import CreateCategory from "./components/Items/CreateCategory";
import PrintableInvoice from "./components/PrintableInvoice";
import InvoiceSettings from "./pages/Settings/InvoiceSettings";
import OnlineOrder from "./components/Restaurant/OnlineOrder/OnlineOrder";
import TogglePlatformItem from "./components/Restaurant/OnlineOrder/TogglePlateformItem/TogglePlateformItem";
import PlatformItems from "./components/Restaurant/OnlineOrder/PlatformItem/PlatformItems";
import Store from "./components/Restaurant/OnlineOrder/Store";
import PlatformForStore from "./components/Restaurant/OnlineOrder/PlatformForStore/PlatformForStore";
import PlatformDashboard from "./components/Restaurant/OnlineOrder/PlatformDashboard/PlatformDashboard";
import ItemUnit from "./components/Items/ItemUnit/ItemUnit";
import { TransactionDetails } from "./components/Purchases/transaction/transaction-details/transaction-details";
import { PurchasePayment } from "./components/Purchases/purchase-payment/purchase-payment";
import { BASE_URL } from '././Url/constants';
import { Promotion } from "./components/Promotion/Promotion";
import ItemDetails from "./components/Items/item-details/ItemDetails";

function App() {
  const token = getToken();
  const [isLoggedIn, setIsLoggedIn] = useState(token !== null);

  const handleLogout = () => {
    localStorage.removeItem("branchId");
    fetch(`${BASE_URL}/v1/auth/logout?devise_type=web`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => {
        if (response.ok) {
          removeToken();
          setIsLoggedIn(false);
        } else {
          console.error("Logout failed");
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  i18n.use(initReactI18next).init({
    resources: {
      en: { translation: enTranslation },
      hi: { translation: hiTranslation },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

  return (
    <>
      {isLoggedIn ? (
        <Router>
            <TransliterationProvider>
              <NotificationWrapper>
                  <SideBar>
                    <Routes>
                      <Route
                        path="/"
                        element={<Dashboard handleLogout={handleLogout} />}
                      />
                      <Route
                        path="/dashboard"
                        element={<Dashboard handleLogout={handleLogout} />}
                      />
                      <Route path="/reports" element={<Reports />} />
                      <Route path="/billing" element={<Billing />} />
                      <Route
                        path="/create-company"
                        element={<CreateCompany />}
                      />
                      <Route path="/add-items" element={<AddItems />} />
                      <Route path="/sale-report" element={<SaleReport />} />
                      <Route
                        path="/roles-permissions"
                        element={
                          <RolesPermissions setIsLoggedIn={setIsLoggedIn} />
                        }
                      />
                      <Route
                        path="/attendance-tracking"
                        element={<AttendanceTracking />}
                      />
                      <Route
                        path="/salary-management"
                        element={<SalaryManagement />}
                      />
                      <Route path="/suppliers" element={<Suppliers />} />
                      <Route path="/Staff" element={<Staff />} />
                      <Route
                        path="/create-customer"
                        element={<CreateCustomer />}
                      />
                      <Route
                        path="/add-customer"
                        element={<CustomerCollapse />}
                      />
                      <Route
                        path="/update-customer"
                        element={<UpdateCustomerForm />}
                      />
                      <Route path="/show-customer" element={<ShowCustomer />} />
                      <Route
                        path="/add-supplier"
                        element={<CreateCollapse />}
                      />
                      <Route
                        path="/update-supplier/:id"
                        element={<UpdateSupplierForm />}
                      />
                      <Route
                        path="/show-supplier/:id"
                        element={<ShowSupplier />}
                      />
                      <Route path="/add-staff" element={<StaffCollapse />} />
                      <Route
                        path="/update-staff/:id"
                        element={<UpdateStaffForm />}
                      />
                      <Route path="/show-staff/:id" element={<ShowStaff />} />

                      <Route
                        path="pages/settings/tax-billing"
                        element={<TaxBilling />}
                      />
                      <Route
                        path="pages/settings/add-items"
                        element={<AddItems />}
                      />
                      <Route path="/purchase" element={<Purchase />} />
                      <Route path="/item" element={<Item />} />
                      <Route path="/itemtable" element={<ItemTable />} />
                      <Route
                        path="/item-details/:id"
                        element={<ItemDetails />}
                      />
                      <Route path="/item-update/:id" element={<ItemUpdate />} />
                      <Route path="/search" element={<Search />} />
                      <Route
                        path="pages/settings/item-setting"
                        element={<ItemSetting />}
                      />
                      <Route path="/settings" element={<Setting />} />
                      <Route path="/invoice/:id" element={<Invoice />} />
                      <Route path="/profile" element={<Showcompany />} />
                      <Route path="/addPurchase" element={<AddPurchase />} />
                      <Route path="/addSale" element={<AddSale />} />
                      <Route
                        path="/transaction-list"
                        element={<TransactionList />}
                      />
                      <Route
                        path="/transaction-details/:id"
                        element={<TransactionDetails />}
                      />
                      <Route
                        path="/purchase-payment/:id"
                        element={<PurchasePayment />}
                      />
                      <Route path="/template" element={<Template />} />
                      <Route
                        path="/addMoreTemplate"
                        element={<AddMoreTemplate />}
                      />
                      <Route
                        path="/template-layout1"
                        element={<TemplateLayout1 />}
                      />
                      <Route
                        path="/template-layout2"
                        element={<TemplateLayout2 />}
                      />
                      <Route
                        path="/template-layout3"
                        element={<TemplateLayout3 />}
                      />
                      <Route
                        path="template-setting-view"
                        element={<TemplateSettingView />}
                      />
                      <Route
                        path="/pages/settings/template-setting"
                        element={<TemplateSetting />}
                      />
                      <Route
                        path="/pages/settings/managing-tax"
                        element={<TaxManagement />}
                      />
                      <Route
                        path="/invoice-settings"
                        element={<InvoiceSettings />}
                      />
                      <Route
                        path="/restaurant-setting"
                        element={<RestaurantSettings />}
                      />
                      <Route
                        path="/restaurant-coupons"
                        element={<RestaurantCoupons />}
                      />
                      <Route
                        path="/restaurant-coupon"
                        element={<RestaurantCoupon />}
                      />
                      <Route path="/restaurant-order" element={<Order />} />
                      <Route path="/receipt-first" element={<ReceiptFirst />} />
                      <Route
                        path="/receipt-second"
                        element={<ReceiptSecond />}
                      />
                      <Route path="/receipt-third" element={<ReceiptThird />} />
                      <Route path="/receipt-four" element={<ReceiptFour />} />
                      <Route
                        path="/table-reservation"
                        element={<TableReservation />}
                      />
                      <Route
                        path="/kot-create"
                        element={<RestourentBillings />}
                      />
                      <Route path="/void-kot" element={<VoidKot />} />
                      <Route path="/void-remark" element={<VoidRemark />} />
                      <Route path="/select-guest" element={<SelectGuest />} />
                      <Route
                        path="/transfer-table"
                        element={<TransferTable />}
                      />
                      <Route path="/merge-table" element={<MergeTable />} />
                      <Route
                        path="/res-item-modifier"
                        element={<ItemModifier />}
                      />
                      <Route path="/bill-item" element={<ItemInvoice />} />
                      <Route
                        path="/parcel-charges"
                        element={<ParcelCharges />}
                      />
                      <Route
                        path="/item-discount"
                        element={<ItemDiscountInvoice />}
                      />
                      <Route
                        path="/kitchen-ceff"
                        element={<Kitchenceff handleLogout={handleLogout} />}
                      />
                      <Route
                        path="/delivery-order"
                        element={<DeliveryOrder />}
                      />
                      <Route
                        path="/delivery-person"
                        element={<DeliveryPerson />}
                      />
                      <Route path="/kitchen-ceff" element={<Kitchenceff />} />
                      <Route path="/expanse-bill" element={<ExpanseBill />} />
                      <Route path="/expanse-data" element={<ExpanseData />} />
                      <Route
                        path="/kot-transaction"
                        element={<KotTransaction />}
                      />
                      <Route
                        path="/create-category"
                        element={<CreateCategory />}
                      />
                      <Route path="/printable" element={<PrintableInvoice />} />
                      <Route path="/online-order" element={<OnlineOrder />} />
                      <Route
                        path="/toggle-platform-item"
                        element={<TogglePlatformItem />}
                      />
                      <Route
                        path="/plateform-item-details"
                        element={<PlatformItems />}
                      />
                      <Route path="/online-store" element={<Store />} />
                      <Route
                        path="/platform-for-store"
                        element={<PlatformForStore />}
                      />
                      <Route
                        path="/platform-dashboard"
                        element={<PlatformDashboard />}
                      />
                      <Route path="/create-unit" element={<ItemUnit />} />
                      <Route path="/promotion" element={<Promotion />} />
                    </Routes>
                  </SideBar>
              </NotificationWrapper>
            </TransliterationProvider>
        </Router>
      ) : (
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/loginotp"
              element={<LoginOtp setIsLoggedIn={setIsLoggedIn} />}
            />
            <Route
              path="/logout"
              element={<LogOut/>}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
